import moment from "moment";
import Utils from "../../../utility";
import { getAllGRNList, getGRNById } from "../GRN/action";

export const getAllStoreStockList = (id) => {
  return (dispatch, getState) => {
    const { order, sortField, offset, take, search } =
      getState().storeStockReducer;
    dispatch({
      type: Utils.ActionName.LOADING,
      payload: { loading: true },
    });
    Utils.api.getApiCall(
      Utils.endPoints.storeStock,
      // "",
      `?order=${order}&sortField=${sortField}&offset=${offset}&take=${take}&search=${search}`,
      (respData) => {
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });
        dispatch({
          type: Utils.ActionName.STORE_STOCK,
          payload: {
            storeStockList: respData.data.storeStocks,
            count: respData.data.count,
          },
        });
        // navigate("/dashboard");
      },
      (error) => {
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });
        let { data } = error;
        Utils.showAlert(
          2,
          `${Utils.endPoints.storeStock} GET Method error ${
            JSON.stringify(data?.message) || data?.error
          }`
        );
        // Utils.showAlert(2, data?.message?.join(",") || data?.error);
        // setSubmitting(true);
      }
    );
  };
};

export const getStoreStockDataForExport = (id) => {
  return (dispatch, getState) => {
    dispatch({
      type: Utils.ActionName.LOADING,
      payload: { loading: true },
    });
    Utils.api.getApiCall(
      Utils.endPoints.storeStock + "/" + id,
      // "",
      `?order=DESC&sortField=createdAt&offset=1&search=`,
      (respData) => {
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });
        dispatch({
          type: Utils.ActionName.STORE_STOCK,
          payload: {
            exportData: respData.data.storeStocks,
          },
        });
        // navigate("/dashboard");
      },
      (error) => {
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });
        let { data } = error;
        Utils.showAlert(
          2,
          `${Utils.endPoints.storeStock} GET Method error ${
            JSON.stringify(data?.message) || data?.error
          }`
        );
        // Utils.showAlert(2, data?.message?.join(",") || data?.error);
        // setSubmitting(true);
      }
    );
  };
};
export const getAllStoreStockDataForExport = () => {
  return (dispatch, getState) => {
    dispatch({
      type: Utils.ActionName.LOADING,
      payload: { loading: true },
    });
    Utils.api.getApiCall(
      Utils.endPoints.storeStock,
      // "",
      `?order=DESC&sortField=createdAt&offset=1&search=`,
      (respData) => {
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });
        dispatch({
          type: Utils.ActionName.STORE_STOCK,
          payload: {
            exportData: respData.data.storeStocks,
          },
        });
        // navigate("/dashboard");
      },
      (error) => {
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });
        let { data } = error;
        Utils.showAlert(
          2,
          `${Utils.endPoints.storeStock} GET Method error ${
            JSON.stringify(data?.message) || data?.error
          }`
        );
        // Utils.showAlert(2, data?.message?.join(",") || data?.error);
        // setSubmitting(true);
      }
    );
  };
};
export const getStoreStockList = (id, resData) => {
  return (dispatch, getState) => {
    const { order, sortField, offset, take, search } =
      getState().storeStockReducer;
    dispatch({
      type: Utils.ActionName.LOADING,
      payload: { loading: true },
    });
    Utils.api.getApiCall(
      Utils.endPoints.storeStock + "/" + id,
      // "",
      `?order=${order}&sortField=${sortField}&offset=${offset}&take=${take}&search=${search}`,
      (respData) => {
        dispatch({
          type: Utils.ActionName.STORE_STOCK,
          payload: {
            storeStockList: respData?.data?.storeStocks,
            count: respData?.data?.count,
          },
        });
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });

        dispatch({
          type: Utils.ActionName.STORE_STOCK,
          payload: {
            storeStockList: respData.data.storeStocks,
            count: respData.data.count,
          },
        });
        resData(respData.data.storeStocks[0]);
        // navigate("/dashboard");
      },
      (error) => {
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });
        let { data } = error;
        Utils.showAlert(
          2,
          `${Utils.endPoints.storeStock} GET Method error ${
            JSON.stringify(data?.message) || data?.error
          }`
        );
        // Utils.showAlert(2, data?.message?.join(",") || data?.error);
        // setSubmitting(true);
      }
    );
  };
};

export const getWarehouseStockListById = (id) => {
  return (dispatch, getState) => {
    const { order, sortField, offset, take, search } =
      getState().warehouseReducer;
    dispatch({
      type: Utils.ActionName.LOADING,
      payload: { loading: true },
    });
    Utils.api.getApiCall(
      Utils.endPoints.warehouseById + id,
      "",
      // `?order=${order}&sortField=${sortField}&offset=${offset}&take=${take}&search=${search}`,
      (respData) => {
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });
        dispatch({
          type: Utils.ActionName.WAREHOUSE_STOCK,
          payload: {
            warehouseStockList: respData.data,
            count: respData.data.count,
          },
        });
        // navigate("/dashboard");
      },
      (error) => {
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });
        let { data } = error;
        Utils.showAlert(
          2,
          `${Utils.endPoints.warehouseById} GET Method error ${
            JSON.stringify(data?.message) || data?.error
          }`
        );
        // Utils.showAlert(2, data?.message?.join(",") || data?.error);
        // setSubmitting(true);
      }
    );
  };
};
export const createStoreGRN = (values, navigate, printGrn, setSubmitting) => {
  return (dispatch) => {
    dispatch({
      type: Utils.ActionName.LOADING,
      payload: { loading: true },
    });

    const dataToSend = {
      products: values.product.map((item) => {
        return {
          product: item.product,
          // invoiceQuantity: 10,
          recievedQuantity: item.recievedQuantity,
          mrp: item.sellingPrice,
          // landingPrice: item.landingPrice,
          batch: moment(),
          costPrice: item.costPrice,
          sellingPrice: item?.sellingPrice,
        };
      }),
      vendorId: values.vendor,
      vehicleNo: values.vehicle,
      // invoicePath:values?.invoice,
      invoiceNumber: values.invoiceNumber,
      PO: values.PONumber,
      store: `${values?.storeId}`,
      // warehouse: "",
    };
    // dataToSend.product = [
    //   {
    //     product: values.product,
    //     quantity: values.quantity,
    //   },
    // ];
    // delete dataToSend.quantity;
    // dataToSend.warehousePincode = +values.warehousePincode;
    // dataToSend.isDeleted = false;

    Utils.api.postApiCall(
      Utils.endPoints.createGRN,
      dataToSend,
      (respData) => {
        setSubmitting(true);
        Utils.showAlert(1, "Store GRN added successfully");
        debugger;
        dispatch({
          type: Utils.ActionName.STORE_STOCK,
          payload: {
            printGrnData: respData.data,
            grandTotalAddedGRN: respData.data?.grnProducts?.reduce((x, y) => {
              x += +y.productCostPrice * +y.recievedQuantity;
              return x;
            }, 0),
            // count: respData.data.count,
          },
        });
        setTimeout(() => {
          printGrn();
          navigate("/grn");
          dispatch({
            type: Utils.ActionName.LOADING,
            payload: { loading: false },
          });
          dispatch(getAllGRNList());
        }, 500);

        // resetForm({});
        // setIsEdit(null)
        // dispatch({type:Utils.ActionName.USER_LIST
        //   ,payload:{userlist:respData.data}})
        // navigate("/dashboard");
      },
      (error) => {
        let { data } = error;
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });
        // Utils.showAlert(2, data?.message?.join(",") || data?.error);

        Utils.showAlert(
          2,
          `${Utils.endPoints.createGRN} POST Method error ${
            JSON.stringify(data?.message) || data?.error
          }`
        );
        setSubmitting(false);
      }
    );
  };
};

export const updateStoreStock = (
  values,
  stockId,
  setOpen,
  storeId,
  setSubmitting
) => {
  return (dispatch) => {
    Utils.api.putApiCall(
      Utils.endPoints.updateStoreStock + `?stockId=${stockId}`,
      values,
      (respData) => {
        Utils.showAlert(1, "Successfully");
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });
        dispatch({
          type: Utils.ActionName.STORE_STOCK,
          payload: {
            printGrnData: respData.data,
            // count: respData.data.count,
          },
        });
        //
        setOpen(null);
        dispatch(getStoreStockList(storeId));
        // resetForm({});
        // setIsEdit(null)
        // dispatch({type:Utils.ActionName.USER_LIST
        //   ,payload:{userlist:respData.data}})
        // navigate("/dashboard");
      },
      (error) => {
        let { data } = error;
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });
        Utils.showAlert(
          2,
          `update ${Utils.endPoints.updateStoreStock} PUT Method error ${
            JSON.stringify(data?.message) || data?.error
          }`
        );
        // Utils.showAlert(2, data?.message?.join(",") || data?.error);
        setSubmitting(false);
        // setSubmitting(true);
      }
    );
  };
};

export const deleteStoreStock = (id, storeId) => {
  return (dispatch) => {
    dispatch({
      type: Utils.ActionName.LOADING,
      payload: { loading: true },
    });
    Utils.api.deleteApiCall(
      Utils.endPoints.storeStock + `/${id}`,
      "",
      (respData) => {
        Utils.showAlert(1, "Warehouse stock delete sucesfuly");
        dispatch(getStoreStockList(storeId));
        // dispatch({type:Utils.ActionName.USER_LIST
        //   ,payload:{userlist:respData.data}})
        // navigate("/dashboard");
      },
      (error) => {
        let { data } = error;
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });
        Utils.showAlert(
          2,
          `delete ${Utils.endPoints.storeStock} DELETE Method error ${
            JSON.stringify(data?.message) || data?.error
          }`
        );
        // Utils.showAlert(2, data?.message?.join(",") || data?.error);
        // setSubmitting(true);
      }
    );
  };
};

// export const getBrandList = () => {
//   return (dispatch) => {
//     // const { email, password } = values;
//     // const dataToSend = {
//     //   username:email,
//     //   password,
//     // };
//     Utils.api.getApiCall(
//       Utils.endPoints.brands,
//       "",
//       (respData) => {
//         dispatch({
//           type: Utils.ActionName.USER_LIST,
//           payload: { brands: respData.data },
//         });
//         // navigate("/dashboard");
//       },
//       (error) => {
//         let { data } = error;
//         Utils.showAlert(2, data?.message?.join(",") || data?.error);
//         // setSubmitting(true);
//       }
//     );
//   };
// };

// export const getPropertyOwner = () => {
//   return (dispatch) => {
//     // const { email, password } = values;
//     // const dataToSend = {
//     //   username:email,
//     //   password,
//     // };
//     Utils.api.getApiCall(
//       Utils.endPoints.propertyOwner,
//       "",
//       (respData) => {
//         dispatch({
//           type: Utils.ActionName.PROPERTY_OWNER,
//           payload: { propertyOwnerList: respData.data },
//         });
//         // navigate("/dashboard");
//       },
//       (error) => {
//         let { data } = error;
//         Utils.showAlert(2, data?.message?.join(",") || data?.error);
//         // setSubmitting(true);
//       }
//     );
//   };
// };

export const getDailyCSV = (values, setOpen) => {
  return (dispatch, getState) => {
    // const { fromDate, toDate, storeId } = values;
    dispatch({
      type: Utils.ActionName.LOADING,
      payload: { loading: true },
    });
    Utils.api.postApiCall(
      Utils.endPoints.dailyStockReport,
      {
        // values
        store: values.storeId,
        searchDate: moment(values.searchDate).format("YYYY-MM-DD"),
      },
      (respData) => {
        const linkSource = `data:application/csv;base64,${respData.data}`;
        const downloadLink = document.createElement("a");
        const fileName = `dailyReport.csv`;
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });
        setOpen(false);
        // dispatch({
        //   type: Utils.ActionName.STORE_AND_WAREHOUSE_REPORTS,
        //   payload: {
        //     cashoutReport: respData.data,
        //     // count: respData.data[1],
        //   },
        // });
      },
      (error) => {
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });
        let { data } = error;
        Utils.showAlert(
          2,
          `${Utils.endPoints.dailyStockReport} POST Method error ${
            JSON.stringify(data?.message) || data?.error
          }`
        );
        // Utils.showAlert(2, data?.message?.join(",") || data?.error);
        // setSubmitting(true);
      }
    );
  };
};

export const grnBulkUpload = (
  values,
  file,
  fileData,
  printGrn,
  setSubmitting
) => {
  return (dispatch) => {
    dispatch({
      type: Utils.ActionName.LOADING,
      payload: { loading: true },
    });
    const { vendorId, invoice, storeId } = values;
    const dataToSend = new FormData();
    dataToSend.append("file", file);
    dataToSend.append("vendorId", vendorId);
    dataToSend.append("invoiceNo", invoice);

    Utils.api.postApiCall(
      Utils.endPoints.grnBulkUpload + storeId,
      dataToSend,
      (respData) => {
        //
        Utils.showAlert(1, "Upload successfully");
        // if (respData?.data?.unavailableBarcodes?.length) {
        // const element = document.createElement("a");
        // const file = new Blob(
        //   [respData?.data?.unavailableBarcodes.join(", ")],
        //   {
        //     type: "text/plain",
        //   }
        // );
        // element.href = URL.createObjectURL(file);
        // element.download = "unavailablebarcodes.txt";
        // document.body.appendChild(element); // Required for this to work in FireFox
        // element.click();
        // }
        // fileData(false);
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });
        dispatch(
          getGRNById(respData?.data?.grn, () => {
            setTimeout(() => {
              printGrn();
              fileData(false);
              setSubmitting(false);
            }, 500);
          })
        );

        // dispatch(getStoreStockList(storeId));
        // resetForm({});
        // setIsEdit(null)
        // dispatch({type:Utils.ActionName.USER_LIST
        //   ,payload:{userlist:respData.data}})
        // navigate("/dashboard");
      },
      (error) => {
        let { data } = error;
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });
        setSubmitting(false);
        // Utils.showAlert(2, data?.message?.join(",") || data?.error);
        Utils.showAlert(
          2,
          `${Utils.endPoints.grnBulkUpload} POST Method error ${
            JSON.stringify(data?.message) || data?.error
          }`
        );
        // setSubmitting(false);
      }
    );
  };
};

/**
 * get current stock
 * @param {*} values
 * @param {*} setOpen
 * @returns
 */

export const getCurrentStock = (storeId) => {
  return (dispatch, getState) => {
    // const { fromDate, toDate, storeId } = values;
    dispatch({
      type: Utils.ActionName.LOADING,
      payload: { loading: true },
    });
    Utils.api.getApiCall(
      Utils.endPoints.currentStock,
      `?store=${storeId}`,
      (respData) => {
        const linkSource = `data:application/csv;base64,${respData.data}`;
        const downloadLink = document.createElement("a");
        const fileName = `currentStock.csv`;
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });
        // setOpen(false);
        // dispatch({
        //   type: Utils.ActionName.STORE_AND_WAREHOUSE_REPORTS,
        //   payload: {
        //     cashoutReport: respData.data,
        //     // count: respData.data[1],
        //   },
        // });
      },
      (error) => {
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });
        let { data } = error;
        Utils.showAlert(
          2,
          `${Utils.endPoints.currentStock} GET Method error ${
            JSON.stringify(data?.message) || data?.error
          }`
        );
        // Utils.showAlert(2, data?.message?.join(",") || data?.error);
        // setSubmitting(true);
      }
    );
  };
};
