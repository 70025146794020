import Utils from "../../../utility";
export const getWarehouseGRNList = (id) => {
  return (dispatch, getState) => {
    // const { order, sortField, offset, take, search } = getState().storeReducer;

    dispatch({
      type: Utils.ActionName.LOADING,
      payload: { loading: true },
    });
    Utils.api.getApiCall(
      Utils.endPoints.warehouseGRN + id,
      "",
      // `?order=${order}&sortField=${sortField}&offset=${offset}&take=${take}&search=${search}`,
      (respData) => {
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });
        dispatch({
          type: Utils.ActionName.WAREHOUSE_STOCK,
          payload: {
            warehouseStockList: respData.data,
            // count: respData.data.count,
          },
        });
      },
      (error) => {
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });
        let { data } = error;
        Utils.showAlert(
          2,
          `${Utils.endPoints.warehouseGRN} GET Method error ${
            JSON.stringify(data?.message) || data?.error
          }`
        );
        // Utils.showAlert(2, data?.message?.join(",") || data?.error);
        // setSubmitting(true);
      }
    );
  };
};

export const createWarehouseeGRN = (
  values,
  setOpen,
  warehouseId,
  printGrn,
  setSubmitting
) => {
  return (dispatch) => {
    dispatch({
      type: Utils.ActionName.LOADING,
      payload: { loading: true },
    });

    const dataToSend = {
      products: values.product.map((item) => {
        return {
          product: item.product,
          invoiceQuantity: item.invoiceQuantity,
          recievedQuantity: item.recievedQuantity,
          mrp: item.MRP,
          // landingPrice: item.landingPrice,
          batch: "2022-09-22T17:37:41.842Z",
          costPrice: item.costPrice,
          sellingPrice: item?.sellingPrice,
        };
      }),
      vendorId: values.vendor,
      vehicleNo: values.vehicle,
      invoiceNumber: values.invoice,
      PO: values.PONumber,
      warehouse: +warehouseId,
    };

    Utils.api.postApiCall(
      Utils.endPoints.createGRN,
      dataToSend,
      (respData) => {
        // setOpen(false);
        Utils.showAlert(1, "Success");
        dispatch(getWarehouseStockListById(warehouseId));
        dispatch({
          type: Utils.ActionName.WAREHOUSE_STOCK,
          payload: {
            printGrnData: respData.data,
            // count: respData.data.count,
          },
        });
        setTimeout(() => {
          printGrn();
          setOpen(false);
        }, 500);
      },
      (error) => {
        let { data } = error;
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });
        setSubmitting(false);
        Utils.showAlert(
          2,
          `${Utils.endPoints.createGRN} POST Method error ${
            JSON.stringify(data?.message) || data?.error
          }`
        );
        // Utils.showAlert(2, data?.message?.join(",") || data?.error);
      }
    );
  };
};

export const getAllWarehouseStockDataForExport = (id) => {
  return (dispatch, getState) => {
    dispatch({
      type: Utils.ActionName.LOADING,
      payload: { loading: true },
    });
    Utils.api.getApiCall(
      Utils.endPoints.warehouseById + id,
      // "",
      `?order=DESC&sortField=name&offset=1&search=`,
      (respData) => {
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });
        dispatch({
          type: Utils.ActionName.WAREHOUSE_STOCK,
          payload: {
            exportData: respData.data.products,
          },
        });
      },
      (error) => {
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });
        let { data } = error;
        Utils.showAlert(
          2,
          `${Utils.endPoints.warehouseById} GET Method error ${
            JSON.stringify(data?.message) || data?.error
          }`
        );
        // Utils.showAlert(2, data?.message?.join(",") || data?.error);
        // setSubmitting(true);
      }
    );
  };
};
export const getWarehouseStockListById = (id) => {
  return (dispatch, getState) => {
    const { order, sortField, offset, take, search } =
      getState().warehouseStockReducer;
    dispatch({
      type: Utils.ActionName.LOADING,
      payload: { loading: true },
    });
    Utils.api.getApiCall(
      Utils.endPoints.warehouseById + id,
      `?order=${order}&offset=${offset}&take=${take}${
        sortField ? `&sortField=${sortField}` : ""
      }&search=${search}`,
      // `?order=${order}&sortField=${sortField}&offset=${offset}&take=${take}&search=${search}`,
      (respData) => {
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });
        dispatch({
          type: Utils.ActionName.WAREHOUSE_STOCK,
          payload: {
            warehouseStockList: respData.data?.products,
            count: respData.data.count,
          },
        });
      },
      (error) => {
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });
        let { data } = error;
        Utils.showAlert(
          2,
          `${Utils.endPoints.warehouseById} GET Method error ${
            JSON.stringify(data?.message) || data?.error
          }`
        );
        // Utils.showAlert(2, data?.message?.join(",") || data?.error);
      }
    );
  };
};

export const updateWarehouseStock = (
  values,
  setOpen,
  id,
  resetForm,
  setIsEdit
) => {
  return (dispatch) => {
    dispatch({
      type: Utils.ActionName.LOADING,
      payload: { loading: true },
    });
    dispatch({
      type: Utils.ActionName.LOADING,
      payload: { loading: true },
    });
    const dataToSend = values;
    dataToSend.warehousePincode = +values.warehousePincode;
    dataToSend.warehouseGSTIN = +values.warehouseGSTIN;

    // dataToSend.FSSAI=values.FSSAI

    Utils.api.putApiCall(
      Utils.endPoints.warehouse + id,
      dataToSend,
      (respData) => {
        setOpen(false);
        Utils.showAlert(1, "Warehouse stock updated successfully");
        // dispatch(getAllUser());
        resetForm({});
        setIsEdit(null);
        // dispatch(getWarehouseStockList());
        // dispatch({type:Utils.ActionName.USER_LIST
        //   ,payload:{userlist:respData.data}})
        // navigate("/dashboard");
      },
      (error) => {
        let { data } = error;
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });
        Utils.showAlert(
          2,
          `update ${Utils.endPoints.warehouse} PUT Method error ${
            JSON.stringify(data?.message) || data?.error
          }`
        );
        // Utils.showAlert(2, data?.message?.join(",") || data?.error);
        // setSubmitting(true);
      }
    );
  };
};
export const createWarehouseStock = (values, setOpen) => {
  return (dispatch) => {
    dispatch({
      type: Utils.ActionName.LOADING,
      payload: { loading: true },
    });
    const dataToSend = values;
    dataToSend.quantity = 12;
    // delete dataToSend.quantity;
    // dataToSend.warehousePincode = +values.warehousePincode;
    dataToSend.isDeleted = false;

    Utils.api.postApiCall(
      Utils.endPoints.createWarehouseStock,
      dataToSend,
      (respData) => {
        setOpen(false);
        Utils.showAlert(1, "Warehouse stock added successfully");
        // dispatch(getWarehouseStockList());
        // resetForm({});
        // setIsEdit(null)
        // dispatch({type:Utils.ActionName.USER_LIST
        //   ,payload:{userlist:respData.data}})
        // navigate("/dashboard");
      },
      (error) => {
        let { data } = error;
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });
        Utils.showAlert(
          2,
          `${Utils.endPoints.createWarehouseStock} POST Method error ${
            JSON.stringify(data?.message) || data?.error
          }`
        );
        // Utils.showAlert(2, data?.message?.join(",") || data?.error);
        // setSubmitting(true);
      }
    );
  };
};

export const deleteWarehouseStock = (id, warehouseId) => {
  return (dispatch) => {
    dispatch({
      type: Utils.ActionName.LOADING,
      payload: { loading: true },
    });
    Utils.api.deleteApiCall(
      Utils.endPoints.warehouseStock + `/${id}`,
      "",
      (respData) => {
        Utils.showAlert(1, "Warehouse stock delete sucessfully");
        dispatch(getWarehouseStockListById(warehouseId));
        // dispatch({type:Utils.ActionName.USER_LIST
        //   ,payload:{userlist:respData.data}})
        // navigate("/dashboard");
      },
      (error) => {
        let { data } = error;
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });
        Utils.showAlert(
          2,
          `delete ${Utils.endPoints.warehouseStock} DELETE Method error ${
            JSON.stringify(data?.message) || data?.error
          }`
        );
        // Utils.showAlert(2, data?.message?.join(",") || data?.error);
        // setSubmitting(true);
      }
    );
  };
};

export const getBrandList = () => {
  return (dispatch) => {
    // const { email, password } = values;
    // const dataToSend = {
    //   username:email,
    //   password,
    // };
    Utils.api.getApiCall(
      Utils.endPoints.brands,
      "",
      (respData) => {
        dispatch({
          type: Utils.ActionName.USER_LIST,
          payload: { brands: respData.data },
        });
        // navigate("/dashboard");
      },
      (error) => {
        let { data } = error;
        Utils.showAlert(
          2,
          `${Utils.endPoints.brands} GET Method error ${
            JSON.stringify(data?.message) || data?.error
          }`
        );
        // Utils.showAlert(2, data?.message?.join(",") || data?.error);
        // setSubmitting(true);
      }
    );
  };
};

export const getPropertyOwner = () => {
  return (dispatch) => {
    // const { email, password } = values;
    // const dataToSend = {
    //   username:email,
    //   password,
    // };
    Utils.api.getApiCall(
      Utils.endPoints.propertyOwner,
      "",
      (respData) => {
        dispatch({
          type: Utils.ActionName.PROPERTY_OWNER,
          payload: { propertyOwnerList: respData.data },
        });
        // navigate("/dashboard");
      },
      (error) => {
        let { data } = error;
        Utils.showAlert(
          2,
          `${Utils.endPoints.propertyOwner} GET Method error ${
            JSON.stringify(data?.message) || data?.error
          }`
        );
        // Utils.showAlert(2, data?.message?.join(",") || data?.error);
        // setSubmitting(true);
      }
    );
  };
};

export const editWarehouseStock = (values, stockId, setOpen, storeId) => {
  return (dispatch) => {
    Utils.api.putApiCall(
      Utils.endPoints.updateWarehouseStock + `?StockId=${stockId}`,
      values,
      (respData) => {
        Utils.showAlert(1, "Successfully");
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });
        dispatch({
          type: Utils.ActionName.STORE_STOCK,
          payload: {
            printGrnData: respData.data,
            // count: respData.data.count,
          },
        });
        setOpen(false);
        dispatch(getWarehouseStockListById(storeId));
        // resetForm({});
        // setIsEdit(null)
        // dispatch({type:Utils.ActionName.USER_LIST
        //   ,payload:{userlist:respData.data}})
        // navigate("/dashboard");
      },
      (error) => {
        let { data } = error;
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });
        // Utils.showAlert(2, data?.message?.join(",") || data?.error);
        Utils.showAlert(
          2,
          `update ${Utils.endPoints.updateWarehouseStock} PUT Method error ${
            JSON.stringify(data?.message) || data?.error
          }`
        );
        // setSubmitting(true);
      }
    );
  };
};
