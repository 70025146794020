import { Navigate } from "react-router-dom";
// import PrivateContainer from "../templates/PrivateContainer";

const RequireAuth = ({ children }) => {
  const token = localStorage.getItem("accessToken");
  if (token === undefined || token === null) {
    return <Navigate to="/" />;
  }
  return <>{children}</>;
};

export default RequireAuth;
