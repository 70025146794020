import Utils from "../../../utility";

const initialState = {
  warehouseList:[],
  order:"DESC",
  sortField:"addDate",
  offset:1,
  take:10,
  search:"",
  count:0,
};
export const warehouseReducer = (state = initialState, action) => {
  switch (action.type) {
    case `${Utils.ActionName.WAREHOUSE}`:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return { ...state };
  }
};