import moment from "moment";
import Utils from "../../../utility";
export const gets2wList = () => {
  return (dispatch, getState) => {
    const { order, storeId, warehouseId, offset, take, search } =
      getState().storeToWarehouseReducer;
    dispatch({
      type: Utils.ActionName.LOADING,
      payload: { loading: true },
    });
    Utils.api.getApiCall(
      Utils.endPoints.s2w,
      // "",
      `?order=${order}&offset=${offset}&take=${take}&search=${search}&storeId=${
        storeId ? storeId : ""
      }&warehouseId=${warehouseId ? warehouseId : ""}`,
      (respData) => {
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });
        dispatch({
          type: Utils.ActionName.STORE_TO_WAREHOUSE,
          payload: {
            storeToWarehouse: respData.data.warehouseList,
            count: respData.data.count,
          },
        });
        // navigate("/dashboard");
      },
      (error) => {
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });
        let { data } = error;
        Utils.showAlert(
          2,
          `${Utils.endPoints.s2w} GET Method error ${
            JSON.stringify(data?.message) || data?.error
          }`
        );
        // Utils.showAlert(2, data?.message?.join(",") || data?.error);
        // setSubmitting(true);
      }
    );
  };
};

export const gets2wListForExport = (csvDownload) => {
  return (dispatch, getState) => {
    const {
      order,
      storeId,
      warehouseId,
      fromDate,
      toDate,
      offset,
      take,
      search,
    } = getState().storeToWarehouseReducer;
    dispatch({
      type: Utils.ActionName.LOADING,
      payload: { loading: true },
    });
    Utils.api.getApiCall(
      Utils.endPoints.s2w,
      // "",
      `?order=${order}&offset=${offset}&take=50&storeId=${
        storeId ? storeId : ""
      }&warehouseId=${warehouseId ? warehouseId : ""}&fromDate=${moment(
        fromDate
      ).format("YYYY-MM-DD")}&toDate=${moment(toDate).format(
        "YYYY-MM-DD"
      )}&search=`,
      (respData) => {
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });
        dispatch({
          type: Utils.ActionName.STORE_TO_WAREHOUSE,
          payload: {
            storeToWarehouseForExport: respData.data.warehouseList,
            // count: respData.data.count,
          },
        });
        setTimeout(() => {
          csvDownload.current.link.click();
        }, 500);
        // navigate("/dashboard");
      },
      (error) => {
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });
        let { data } = error;
        Utils.showAlert(
          2,
          `${Utils.endPoints.s2w} GET Method error ${
            JSON.stringify(data?.message) || data?.error
          }`
        );
        // Utils.showAlert(2, data?.message?.join(",") || data?.error);
        // setSubmitting(true);
      }
    );
  };
};
export const makeATransfers2w = (values, setOpen, setSubmitting) => {
  return (dispatch) => {
    dispatch({
      type: Utils.ActionName.LOADING,
      payload: { loading: true },
    });
    const dataToSend = values;

    Utils.api.postApiCall(
      Utils.endPoints.createS2W,
      dataToSend,
      (respData) => {
        setOpen(false);
        Utils.showAlert(1, "success");
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });
        dispatch(gets2wList());
        // resetForm({});
        // setIsEdit(null)
        // dispatch({type:Utils.ActionName.USER_LIST
        //   ,payload:{userlist:respData.data}})
        // navigate("/dashboard");
      },
      (error) => {
        let { data } = error;
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });
        // Utils.showAlert(2, data?.message?.join(",") || data?.error);
        Utils.showAlert(
          2,
          `${Utils.endPoints.createS2W} POST Method error ${
            JSON.stringify(data?.message) || data?.error
          }`
        );
        setSubmitting(false);
      }
    );
  };
};

export const updateStateS2w = (id, status) => {
  return (dispatch) => {
    dispatch({
      type: Utils.ActionName.LOADING,
      payload: { loading: true },
    });

    Utils.api.putApiCall(
      Utils.endPoints.updateStatusS2W + id + "?status=" + status,
      // dataToSend,{}
      {},
      (respData) => {
        // setOpen(false);
        Utils.showAlert(1, "Success");
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });
        dispatch(gets2wList());
        // resetForm({});
        // setOpen(null);
        // dispatch({type:Utils.ActionName.USER_LIST
        //   ,payload:{userlist:respData.data}})
        // navigate("/dashboard");
      },
      (error) => {
        let { data } = error;
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });
        // Utils.showAlert(2, data?.message?.join(",") || data?.error);
        Utils.showAlert(
          2,
          `${Utils.endPoints.updateStatusS2W} PUT Method error ${
            JSON.stringify(data?.message) || data?.error
          }`
        );
        // setSubmitting(true);
      }
    );
  };
};

export const getPdfs2w = (id) => {
  return (dispatch, getState) => {
    dispatch({
      type: Utils.ActionName.LOADING,
      payload: { loading: true },
    });
    Utils.api.getApiCall(
      Utils.endPoints.s2wPdf + id,
      "",
      // `?order=${order}&sortField=${sortField}&offset=${offset}&take=${take}&search=${search}`,
      (respData) => {
        const linkSource = `data:application/pdf;base64,${respData.data}`;
        const downloadLink = document.createElement("a");
        const fileName = `tns${id}.pdf`;
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });
        // navigate("/dashboard");
      },
      (error) => {
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });
        let { data } = error;
        Utils.showAlert(
          2,
          `${Utils.endPoints.s2wPdf} GET Method error ${
            JSON.stringify(data?.message) || data?.error
          }`
        );
        // Utils.showAlert(2, data?.message?.join(",") || data?.error);
        // setSubmitting(true);
      }
    );
  };
};

export const updateQuntity = (values, setOpen, id) => {
  return (dispatch) => {
    dispatch({
      type: Utils.ActionName.LOADING,
      payload: { loading: true },
    });
    Utils.api.putApiCall(
      Utils.endPoints.updateQuantityS2w + id + `?quantity=${values?.quantity}`,
      {},
      (respData) => {
        setOpen(false);
        Utils.showAlert(1, "successfull");
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });
        dispatch(gets2wList());
        // resetForm({});
        // setIsEdit(null)
        // dispatch({type:Utils.ActionName.USER_LIST
        //   ,payload:{userlist:respData.data}})
        // navigate("/dashboard");
      },
      (error) => {
        let { data } = error;
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });
        // Utils.showAlert(2, data?.message?.join(",") || data?.error);
        Utils.showAlert(
          2,
          `${Utils.endPoints.updateQuantityS2w} PUT Method error ${
            JSON.stringify(data?.message) || data?.error
          }`
        );
        // setSubmitting(true);
      }
    );
  };
};
