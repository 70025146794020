import React, { useState } from "react";
import Button from "@mui/material/Button";
// import { getStoreList } from "../store/action";
import Stack from "@mui/material/Stack";
import Divider from "@mui/material/Divider";
import { CSVLink } from "react-csv";
import DatePicker from "../../../component/date";
import AutoComplete from "../../../component/input/autocomplete";
// import Table from "@mui/material/Table";
// import TableBody from "@mui/material/TableBody";
// import TableCell from "@mui/material/TableCell";
// import TableContainer from "@mui/material/TableContainer";
// import TableHead from "@mui/material/TableHead";
// import TablePagination from "@mui/material/TablePagination";
// import TableRow from "@mui/material/TableRow";
// import TableSortLabel from "@mui/material/TableSortLabel";
// import Paper from "@mui/material/Paper";
// import Schema from "../../../schema";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { Formik, Form } from "formik";
// import { getSKUTimeline, getSKUTimelineAll } from "./action";
import { Box } from "@mui/material";

import Utils from "../../../utility";
// import { getProducts } from "../../productRelated/products/action";
import { useLocation } from "react-router-dom";
import { getWarehouseGrnReport } from "./action";

// const headCells = [
//   {
//     id: "id",
//     numeric: true,
//     disablePadding: true,
//     label: "Id",
//     sortDisable: true,
//   },
//   {
//     id: "brandName",
//     numeric: false,
//     disablePadding: true,
//     label: "Date",
//     sortDisable: false,
//   },
//   {
//     id: "dateCreated",
//     numeric: false,
//     disablePadding: true,
//     label: "Logs",
//     sortDisable: true,
//   },

//   {
//     id: "action",
//     numeric: true,
//     disablePadding: false,
//     label: "Previous Quantity",
//     sortDisable: true,
//   },
//   {
//     id: "action",
//     numeric: true,
//     disablePadding: false,
//     label: "Currnet Quantity Quantity",
//     sortDisable: true,
//   },
// ];

export default function EnhancedTable() {
  const location = useLocation();
  // const [csvData, setCsvData] = useState([]);
  // const [initialValue, setInitialValue] = useState({});
  const dispatch = useDispatch();
  const initialValues = {
    warehouseId: null,
    toDate: moment(),
    fromDate: moment(),
  };

 
  // const { warehouseListForDropdown } = useSelector((state) => state.storeReducer);
  // const {  } = useSelector((state) => state.SkuTimelineReducer);
  // const { productsDropDownList } = useSelector((state) => state.productsReducer);

  React.useEffect(() => {
    // dispatch(getProducts());
    // dispatch(getStoreList());
    // dispatch(getSKUTimelineAll());
  }, []);
  const {
    // warehouseListForDropdown,
    warehouseListForDropdown,
    // brandOwnerListForDropdown,
    // productsDropDownList,
    // brandOwnerListForDropdown,
  } = useSelector((state) => state.commonReducer);
  const csvDownload = React.useRef(null);

  return (
    <div>
      <h2>{location?.pathname.slice(1).split("-").join(" ").toUpperCase()}</h2>
      <Box direction="row" spacing={2} sx={{ display: "flex", mb: 2 }}>
        <Formik
          initialValues={initialValues}
          // validationSchema={Schema.SKUTimelineSchema}
          onSubmit={(values, { setSubmitting }) => {
            // setInitialValue(values);
            // alert("Under development");
            dispatch(getWarehouseGrnReport(values, csvDownload));

            // dispatch(getSKUTimeline(values));
          }}
        >
          {({
            values,
            errors,
            touched,
            setFieldValue,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            /* and other goodies */
          }) => (
            <Form onSubmit={handleSubmit}>
              <Box sx={{ display: "flex" }}>
                <Stack direction="row" spacing={2} style={{ width: 200 }}>
                  <AutoComplete
                    freeSolo={false}
                    // onBlur={handleBlur}
                    values={values.warehouseId}
                    onChange={(event, value) => {
                      if (value !== null) {
                        const index = warehouseListForDropdown.findIndex(
                          (x) => x.id === value.id
                        );

                        setFieldValue("warehouseId", value?.id);
                        // setFieldValue("storeName", value?.storename);
                        dispatch({
                          type: Utils.ActionName.SKU_TIMELINE,
                          payload: { skuData: [] },
                        });
                      }
                    }}
                    helperText={touched.warehouseId && errors.warehouseId}
                    error={Boolean(touched.warehouseId && errors.warehouseId)}
                    name={"warehouseId"}
                    label={"warehouseId"}
                    touched={touched.warehouseId}
                    options={warehouseListForDropdown}
                    getOptionLabel={(option) => option?.warehouseNickname}
                    // isRequired={true}
                    getOptionSelected={(option, value) => {
                      return `${option.id}` === `${value.id}`;
                    }}
                    defaultValue={() => {
                      const index = warehouseListForDropdown?.findIndex(
                        (x) => x?.id == values.warehouseId
                      );
                      return warehouseListForDropdown[index];
                    }}
                  />
                </Stack>
                <Stack style={{ width: 200 }}>
                  <DatePicker
                    value={values.fromDate}
                    name="fromDate"
                    handleChange={(newVal) => setFieldValue("fromDate", newVal)}
                    label="From Date"
                    error={errors.fromDate}
                  />
                </Stack>
                <Stack direction="row" spacing={2} style={{ width: 200 }}>
                  <DatePicker
                    value={values.toDate}
                    name="toDate"
                    handleChange={(newVal) => setFieldValue("toDate", newVal)}
                    label="To Date"
                    error={errors.toDate}
                  />
                </Stack>

                <Button
                  variant="outlined"
                  spacing={2}
                  sx={{ mr: 2 }}
                  type="submit"
                >
                  Submit
                </Button>
                {/* {skuData.length ? (
                  <CSVLink
                    data={csvData}
                    headers={headers}
                    filename={"store-sku-timeline"}
                  >
                    <Button variant="outlined" spacing={2}>
                      Download report
                    </Button>
                  </CSVLink>
                ) : (
                  <Button
                    variant="outlined"
                    spacing={2}
                    disabled={!skuData.length}
                  >
                    Download report
                  </Button>
                )} */}
              </Box>
            </Form>
          )}
        </Formik>
      </Box>
      <Divider />
      {/* <CSVLink
        data={cvcData}
        headers={headers}
        filename={"wearehouse GRN report"}
        ref={csvDownload}
        target="_blank"
      /> */}
    </div>
  );
}
