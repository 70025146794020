import moment from "moment";
import Utils from "../../../utility";

export const deleteProduct = (id) => {
  return (dispatch) => {
    dispatch({
      type: Utils.ActionName.LOADING,
      payload: { loading: true },
    });
    Utils.api.deleteApiCall(
      Utils.endPoints.products + `${id}`,
      "",
      (respData) => {
        Utils.showAlert(1, "Brand delete sucesfully");
        // dispatch(getProducts());
      },
      (error) => {
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });
        let { data } = error;
        Utils.showAlert(
          2,
          `Delete ${Utils.endPoints.products} DELETE Method error ${
            JSON.stringify(data?.message) || data?.error
          }`
        );
        // Utils.showAlert(2, data?.message?.join(",") || data?.error);
        // setSubmitting(true);
      }
    );
  };
};

export const getReturnList = () => {
  return (dispatch, getState) => {
    dispatch({
      type: Utils.ActionName.LOADING,
      payload: { loading: true },
    });
    const { order, offset, take, search, storeId } = getState().returnReducer;
    Utils.api.getApiCall(
      Utils.endPoints.allReturns + "/" + storeId,
      `?order=${order}&offset=${offset}&take=${take}&search=${search}`,
      (respData) => {
        dispatch({
          type: Utils.ActionName.RETURNS,
          payload: {
            returnList: respData.data?.orderReturns.reverse(),
            count: respData?.data?.count,
          },
        });
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });
      },
      (error) => {
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });
        let { data } = error;
        Utils.showAlert(
          2,
          `${Utils.endPoints.allReturns} GET Method error ${
            JSON.stringify(data?.message) || data?.error
          }`
        );
        // Utils.showAlert(2, data?.message?.join(",") || data?.error);
        // setSubmitting(true);
      }
    );
  };
};

export const returnInvoice = (id) => {
  return (dispatch, getState) => {
    dispatch({
      type: Utils.ActionName.LOADING,
      payload: { loading: true },
    });
    // const { images } = getState().productsReducer;
    // // const { brandName } = values;
    // var dataToSend = new FormData();
    // dataToSend.append("file", file);

    Utils.api.getApiCall(
      Utils.endPoints.returnPdf + id,
      "",
      (respData) => {
        var winparams =
          "dependent=yes,locationbar=no,scrollbars=yes,menubar=yes," +
          "resizable,screenX=50,screenY=50,width=850,height=1050";

        var htmlPop =
          "<embed width=100% height=100%" +
          ' type="application/pdf"' +
          ' src="data:application/pdf;base64,' +
          escape(respData?.data) +
          '"></embed>';

        var printWindow = window.open("", "PDF", winparams);
        printWindow.document.write(htmlPop);
        // printWindow.print();

        // const linkSource = `data:application/pdf;base64,${respData.data}`;
        // const downloadLink = document.createElement("a");
        // const fileName = "abc.pdf";
        // downloadLink.href = linkSource;
        // downloadLink.download = fileName;
        // downloadLink.click();

        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });
        // dispatch({
        //   type: Utils.ActionName.PRODUCTS,
        //   payload: { images: [...images, respData.data.Location] },
        // });
        // setOpen(false);
        Utils.showAlert(1, "successfully");
        // dispatch(getProducts());
      },
      (error) => {
        let { data } = error;
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });
        // Utils.showAlert(2, data?.message?.join(",") || data?.error?.join(","));
        Utils.showAlert(
          2,
          `${Utils.endPoints.returnPdf} GET Method error ${
            JSON.stringify(data?.message) || data?.error
          }`
        );
        // setSubmitting(true);
      }
    );
  };
};



/**
 * get Return Report
 */

export const getReturnReport = (values) => {
  return (dispatch, getState) => {
    const { fromDate, toDate, storeId } = values;
    dispatch({
      type: Utils.ActionName.LOADING,
      payload: { loading: true },
    });
    Utils.api.getApiCall(
      Utils.endPoints.returnReport,
      `?fromDate=${moment(fromDate).format(
        "YYYY-MM-DD"
      )} 00:00:00&toDate=${moment(toDate).format("YYYY-MM-DD")} 23:59:59${
        storeId ? `&storeId=${storeId}` : ""
      }`,
      (respData) => {
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });

        if (!respData.data?.length) {
          Utils.showAlert(2, "No data found");
          return;
        }
        const linkSource = `data:application/csv;base64,${respData.data}`;
        const downloadLink = document.createElement("a");
        const fileName = `Return-report.csv`;
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();

        // setTimeout(() => {
        //   csvDownload.current.link.click();
        // }, 500);
      },
      (error) => {
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });
        let { data } = error;
        Utils.showAlert(
          2,
          `${Utils.endPoints.RTVStoreStockReport} GET Method error ${
            JSON.stringify(data?.message) || data?.error
          }`
        );
        // Utils.showAlert(2, data?.message?.join(",") || data?.error);
        // setSubmitting(true);
      }
    );
  };
};
