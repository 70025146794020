import Utils from "../../../utility";

const initialState = {
  recipes: [],
  count: 0,
  order: "DESC",
  sortField: "createdAt",
  offset: 1,
  take: 10,
  search: "",
  count: 0,
  recipesDetail: {},
  recipesProducts: [],
};
export const recipesReducer = (state = initialState, action) => {
  switch (action.type) {
    case `${Utils.ActionName.RECIPES}`:
      return {
        ...state,
        ...action.payload,
      };
    case `${Utils.ActionName.RESET_RECIPES}`:
      return initialState;
    default:
      return { ...state };
  }
};
