import moment from "moment";
import Utils from "../../../utility";

const initialState = {
  orderList: [],
  // count: 0,
  order: "DESC",
  storeId: null,
  sortField: "createdAt",
  offset: 1,
  take: 10,
  search: "",
  count: 0,
  prevCount: 0,
  exportData: [],
  fromDate: moment().subtract(7, "d"),
  toDate: moment(),
  // store: "",
  invoiceHtml: "",
};
export const orderReducer = (state = initialState, action) => {
  switch (action.type) {
    case `${Utils.ActionName.ORDER}`:
      return {
        ...state,
        ...action.payload,
      };
    case "CLEAR_INVOICE":
      return {
        ...state,
        invoiceHtml: "",
      };
    default:
      return { ...state };
  }
};

export const clearInvoiceDetails = () => ({
  type: "CLEAR_INVOICE",
});
