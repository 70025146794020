import Utils from "../../../utility";

export const CreditByStore = (storeId) => {
  return (dispatch, getState) => {
    dispatch({
      type: Utils.ActionName.LOADING,
      payload: { loading: true },
    });
    const { order, offset, take, search } = getState().creditsReducer;
    Utils.api.getApiCall(
      Utils.endPoints.credit,
      `/${storeId}?search=${
        search ? search : ""
      }&order=${order}&offset=${offset}&take=${take}`,
      (respData) => {
        dispatch({
          type: Utils.ActionName.CREDIT,
          payload: {
            count: respData?.data?.count,
            creditData: respData?.data?.result,
            loading: false,
          },
        });
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });
      },
      (error) => {
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });
        let { data } = error;
        if (data?.message === "" || !data) {
          Utils.showAlert(2, "POS Credit Get Error");
        } else {
          Utils.showAlert(2, "POS Credit Get Error: " + data?.message);
        }
      }
    );
  };
};

export const creditEmailAndSms = (storeId, customerId) => {
  return () => {
    let dataToSend = {};
    dataToSend = {
      customer: customerId,
    };
    Utils.api.postApiCall(
      Utils.endPoints.credit + `/${storeId}`,
      dataToSend,
      (data) => {
        Utils.showAlert(1, data.data.status);
      },
      (error) => {
        let { data } = error;
        Utils.showAlert(2, data?.message);
      }
    );
  };
};

export const CreditByStoreId = (customerId) => {
  return (dispatch, getState) => {
    const { storeId } = getState().creditsReducer;
    dispatch({
      type: Utils.ActionName.LOADING,
      payload: { loading: true },
    });
    Utils.api.getApiCall(
      Utils.endPoints.credit,
      `?customer=${customerId}&store=${storeId}&status=credit&sortField=createdAt&order=ASC&offset=1&take=10`,
      (respData) => {
        dispatch({
          type: Utils.ActionName.CREDIT,
          payload: {
            count: respData?.data?.count,
            customerCreditData: respData?.data?.results,
            loading: false,
          },
        });
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });
      },
      (error) => {
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });
        let { data } = error;
        if (data?.message === "" || !data) {
          Utils.showAlert(2, "POS Credit Get Error");
        } else {
          Utils.showAlert(2, "POS Credit Get Error: " + data?.message);
        }
      }
    );
  };
};

export const updateCredit = (
  storeId,
  customerId,
  amount,
  id,
  type,
  setOpen
) => {
  return (dispatch) => {
    let dataToSend = {
      customer: customerId,
      ids: id,
      payments: amount,
      paymentType: type,
    };
    Utils.api.putApiCall(
      // eslint-disable-next-line
      Utils.endPoints.credit + `/${storeId}`,
      dataToSend,
      () => {
        Utils.showAlert(1, "Credit Paid");
        dispatch(CreditByStoreId(customerId, storeId));
        setOpen(false);
      },
      (error) => {
        let { data } = error;
        if (data?.error === "" || !data) {
          Utils.showAlert(2, "Credit Update Error");
        } else {
          Utils.showAlert(2, "Credit Update Error: " + data?.error);
        }
      }
    );
  };
};

export const orderInvoice = (id) => {
  return (dispatch) => {
    dispatch({
      type: Utils.ActionName.LOADING,
      payload: { loading: true },
    });
    Utils.api.getApiCall(
      Utils.endPoints.orderInvoice + id,
      "",
      (respData) => {
        var winparams =
          "dependent=yes,locationbar=no,scrollbars=yes,menubar=yes," +
          "resizable,screenX=50,screenY=50,width=850,height=1050";
        var htmlPop =
          "<embed width=100% height=100%" +
          ' type="application/pdf"' +
          ' src="data:application/pdf;base64,' +
          escape(respData?.data) +
          '"></embed>';
        var printWindow = window.open("", "PDF", winparams);
        //@ts-ignore
        printWindow.document.write(htmlPop);
        //@ts-ignore
        // printWindow.print()

        // const linkSource = `data:application/pdf;base64,${respData.data}`;
        // const downloadLink = document.createElement("a");
        // const fileName = "abc.pdf";
        // downloadLink.href = linkSource;
        // downloadLink.download = fileName;
        // downloadLink.click();

        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });
        // Utils.showAlert(1, 'successfully')
      },
      (error) => {
        let { data } = error;
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });
        if (data?.message === "" || !data) {
          Utils.showAlert(2, "Invoice Get Error");
        } else {
          Utils.showAlert(
            2,
            "Invoice Get Error: " + data?.error || data?.message
          );
        }
      }
    );
  };
};
