import Utils from "../../../utility";

export const getAllGRNList = () => {
  return (dispatch, getState) => {
    const { order, sortField, offset, take, search, id, type } =
      getState().GRNReducer;
    dispatch({
      type: Utils.ActionName.LOADING,
      payload: { loading: true },
    });
    Utils.api.getApiCall(
      Utils.endPoints.GRN,
      `?order=${order}${
        sortField ? `&sortField=${sortField}` : ""
      }&offset=${offset}&take=${take}&id=${id}&type=store`,
      (respData) => {
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });
        dispatch({
          type: Utils.ActionName.GRN,
          payload: {
            GRNList: respData.data,
            count: respData.data[1],
          },
        });
      },
      (error) => {
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });
        let { data } = error;
        Utils.showAlert(
          2,
          `${Utils.endPoints.GRN} GET Method error ${
            JSON.stringify(data?.message) || data?.error
          }`
        );
        // Utils.showAlert(2, data?.message?.join(",") || data?.error);
        // setSubmitting(true);
      }
    );
  };
};

export const getGRNById = (id, callBack) => {
  return (dispatch, getState) => {
    // const { order, sortField, offset, take, search } = getState().storeReducer;
    dispatch({
      type: Utils.ActionName.LOADING,
      payload: { loading: true },
    });
    Utils.api.getApiCall(
      Utils.endPoints.GRN + id,
      "",
      // `?order=${order}&sortField=${sortField}&offset=${offset}&take=${take}&search=${search}`,
      (respData) => {
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });
        if (callBack) callBack();
        dispatch({
          type: Utils.ActionName.STORE_STOCK,
          payload: {
            printGrnData: respData.data,
            grandTotalAddedGRN: respData.data?.grnProducts?.reduce((x, y) => {
              x += +y.productCostPrice * +y.recievedQuantity;
              return x;
            }, 0),
            // count: respData.data.count,
          },
        });
        dispatch({
          type: Utils.ActionName.GRN,
          payload: {
            GRNDetail: respData.data,
          },
        });
      },
      (error) => {
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });
        let { data } = error;
        // Utils.showAlert(2, data?.message?.join(",") || data?.error);
        // setSubmitting(true);
        Utils.showAlert(
          2,
          `${Utils.endPoints.GRN} GET Method error ${
            JSON.stringify(data?.message) || data?.error
          }`
        );
      }
    );
  };
};
// export const updateStore = (values, setOpen, id, resetForm, setIsEdit) => {
//   return (dispatch) => {
//     dispatch({
//       type: Utils.ActionName.LOADING,
//       payload: { loading: true },
//     });
//     dispatch({
//       type: Utils.ActionName.LOADING,
//       payload: { loading: true },
//     });
//     const dataToSend = values;
//     dataToSend.isDeleted = false;
//     dataToSend.runner = +values.runner;
//     dataToSend.propertyOwner = +values.propertyOwner;
//     dataToSend.pincode = +values.pincode;

//     // dataToSend.FSSAI=values.FSSAI

//     Utils.api.putApiCall(
//       Utils.endPoints.store + id,
//       dataToSend,
//       (respData) => {
//         setOpen(false);
//         Utils.showAlert(1, "Store updated successfully");
//         // dispatch(getAllUser());
//         resetForm({});
//         setIsEdit(null);
//         // dispatch(getStoreList());
//         // dispatch({type:Utils.ActionName.USER_LIST
//         //   ,payload:{userlist:respData.data}})
//         // navigate("/dashboard");
//       },
//       (error) => {
//         let { data } = error;
//         dispatch({
//           type: Utils.ActionName.LOADING,
//           payload: { loading: false },
//         });
//         Utils.showAlert(2, data?.message?.join(",") || data?.error);

//         // setSubmitting(true);
//       }
//     );
//   };
// };
// export const createStores = (values, setOpen) => {
//   return (dispatch) => {
//     dispatch({
//       type: Utils.ActionName.LOADING,
//       payload: { loading: true },
//     });
//     const dataToSend = values;
//     dataToSend.isDeleted = false;
//     dataToSend.runner = +values.runner;
//     dataToSend.propertyOwner = +values.propertyOwner;
//     dataToSend.FSSAI = +values.FSSAI;
//     Utils.api.postApiCall(
//       Utils.endPoints.createStore,
//       dataToSend,
//       (respData) => {
//         setOpen(false);
//         Utils.showAlert(1, "Store updated successfully");
//         // dispatch(getStoreList());
//         // resetForm({});
//         // setIsEdit(null)
//         // dispatch({type:Utils.ActionName.USER_LIST
//         //   ,payload:{userlist:respData.data}})
//         // navigate("/dashboard");
//       },
//       (error) => {
//         let { data } = error;
//         dispatch({
//           type: Utils.ActionName.LOADING,
//           payload: { loading: false },
//         });
//         Utils.showAlert(2, data?.message?.join(",") || data?.error);

//         // setSubmitting(true);
//       }
//     );
//   };
// };

export const deleteGRN = (id) => {
  return (dispatch) => {
    dispatch({
      type: Utils.ActionName.LOADING,
      payload: { loading: true },
    });
    Utils.api.deleteApiCall(
      Utils.endPoints.deleteGrn + `${id}`,
      "",
      (respData) => {
        Utils.showAlert(1, "GRN delete sucesfuly");
        dispatch(getAllGRNList());
        // dispatch({type:Utils.ActionName.USER_LIST
        //   ,payload:{userlist:respData.data}})
        // navigate("/dashboard");
      },
      (error) => {
        let { data } = error;
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });
        Utils.showAlert(
          2,
          `delete ${Utils.endPoints.deleteGrn} DELETE Method error ${
            JSON.stringify(data?.message) || data?.error
          }`
        );
        // Utils.showAlert(2, data?.message?.join(",") || data?.error);
        // setSubmitting(true);
      }
    );
  };
};

// export const getBrandList = () => {
//   return (dispatch) => {
//     // const { email, password } = values;
//     // const dataToSend = {
//     //   username:email,
//     //   password,
//     // };
//     Utils.api.getApiCall(
//       Utils.endPoints.brands,
//       "",
//       (respData) => {
//         dispatch({
//           type: Utils.ActionName.USER_LIST,
//           payload: { brands: respData.data },
//         });
//         // navigate("/dashboard");
//       },
//       (error) => {
//         let { data } = error;
//         Utils.showAlert(2, data?.message?.join(",") || data?.error);
//         // setSubmitting(true);
//       }
//     );
//   };
// };

// export const getPropertyOwner = () => {
//   return (dispatch) => {
//     // const { email, password } = values;
//     // const dataToSend = {
//     //   username:email,
//     //   password,
//     // };
//     Utils.api.getApiCall(
//       Utils.endPoints.propertyOwner,
//       "",
//       (respData) => {
//         dispatch({
//           type: Utils.ActionName.PROPERTY_OWNER,
//           payload: { propertyOwnerList: respData.data },
//         });
//         // navigate("/dashboard");
//       },
//       (error) => {
//         let { data } = error;
//         Utils.showAlert(2, data?.message?.join(",") || data?.error);
//         // setSubmitting(true);
//       }
//     );
//   };
// };

// export const getBiller = (takes) => {
//   return (dispatch, getState) => {
//     const { order, sortField, offset, take, search } = getState().storeReducer;

//     dispatch({
//       type: Utils.ActionName.LOADING,
//       payload: { loading: true },
//     });
//     Utils.api.getApiCall(
//       Utils.endPoints.biller,
//       "",
//       // `?order=${order}&sortField=${sortField}&offset=${offset}&take=${takes?takes:take}&search=${search}`,
//       (respData) => {
//         dispatch({
//           type: Utils.ActionName.LOADING,
//           payload: { loading: false },
//         });
//         dispatch({
//           type: Utils.ActionName.STORE,
//           payload: {
//             billerList: respData.data,
//           },
//         });
//       },
//       (error) => {
//         dispatch({
//           type: Utils.ActionName.LOADING,
//           payload: { loading: false },
//         });
//         let { data } = error;
//         Utils.showAlert(2, data?.message?.join(",") || data?.error);
//         // setSubmitting(true);
//       }
//     );
//   };
// };

// export const getOprationManagerList = (takes) => {
//   return (dispatch, getState) => {
//     const { order, sortField, offset, take, search } = getState().storeReducer;

//     dispatch({
//       type: Utils.ActionName.LOADING,
//       payload: { loading: true },
//     });
//     Utils.api.getApiCall(
//       Utils.endPoints.oprationManager,
//       "",
//       // `?order=${order}&sortField=${sortField}&offset=${offset}&take=${takes?takes:take}&search=${search}`,
//       (respData) => {
//         dispatch({
//           type: Utils.ActionName.LOADING,
//           payload: { loading: false },
//         });
//         dispatch({
//           type: Utils.ActionName.STORE,
//           payload: {
//             oprationManagerList: respData.data,
//           },
//         });
//       },
//       (error) => {
//         dispatch({
//           type: Utils.ActionName.LOADING,
//           payload: { loading: false },
//         });
//         let { data } = error;
//         Utils.showAlert(2, data?.message?.join(",") || data?.error);
//         // setSubmitting(true);
//       }
//     );
//   };
// };
