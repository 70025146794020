import * as React from "react";
import { useRef } from "react";
import { useReactToPrint } from "react-to-print";
import { useSelector } from "react-redux";
import moment from "moment";
import { Button } from "@mui/material";
export default function EnhancedTable() {
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  const { PODetail } = useSelector((state) => state.warehousePOReducer);

  const PoPdf = () => {
    return (
      <div ref={componentRef} style={{ margin: 10 }}>
        <div>
          <p style={{ fontSize: 14, fontWeight: 500, marginBottom: 5 }}>
            NEW SHOP CSTORE NETWORK PRIVATE LIMITED
          </p>
          <p style={{ fontSize: 14, marginTop: 0 }}>
            C 39, East Of Kailash New Delhi, India, 110065
            <br />
            GSTIN: 07AAHCN8911F1ZE
          </p>
        </div>
        <p style={{ fontSize: 18, fontWeight: 700, textAlign: "center" }}>
          Purchase Order
        </p>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <p>
            PO Expiry Date
            <br />
            {moment(PODetail?.expiryDate).format("DD-MM-YYYY")}
          </p>
          <p>
            PO ID
            <br />
            {PODetail?.poNumber}
          </p>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            borderBottom: "2px dotted gray",
          }}
        >
          <p>
            Delivery Date
            <br />
            {moment(PODetail?.deliveryDate).format("DD-MM-YYYY")}
          </p>
          <p>
            Terms
            <br />
            {PODetail?.terms || "N/A"}
          </p>
        </div>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <p>
            Vendor
            <br />
            {PODetail?.vendor?.alias}
            <br />
            {PODetail?.vendor?.user?.address}
            <br />
            {PODetail?.vendor?.user?.contactEmail}
          </p>
          <p>
            Ship To
            <br />
            THE NEW SHOP
            <br />
            {PODetail?.warehouse?.warehouseNickname}
            <br />
            {PODetail?.warehouse?.GSTIN}
          </p>
        </div>
        <div>
          <table
            id="tab_customers"
            style={{
              border: "1px solid gray",
              borderCollapse: "collapse",
              width: "100%",
            }}
          >
            <thead>
              <tr style={{ borderBottom: "1px solid gray" }}>
                <th style={{ borderRight: "1px solid gray" }}>S.N</th>
                <th style={{ borderRight: "1px solid gray" }}>Product Name</th>
                <th style={{ borderRight: "1px solid gray" }}>Barcode</th>
                <th style={{ borderRight: "1px solid gray" }}>MRP</th>
                <th style={{ borderRight: "1px solid gray" }}>Quantity</th>
                <th style={{ borderRight: "1px solid gray" }}>
                  Unit Price (incl. GST)
                </th>
                <th style={{ borderRight: "1px solid gray" }}>Total Value</th>
              </tr>
            </thead>
            <tbody>
              {PODetail?.poProducts?.map((row, index) => (
                <tr key={index} style={{ borderBottom: "1px solid gray" }}>
                  <td style={{ borderRight: "1px solid gray" }}>{++index}</td>
                  <td style={{ borderRight: "1px solid gray" }}>
                    {row?.product?.name}
                  </td>
                  <td style={{ borderRight: "1px solid gray" }}>
                    {row?.product?.barcode ? row?.product?.barcode : "-"}
                  </td>
                  <td style={{ borderRight: "1px solid gray" }}>
                    {row?.productSellingPrice || "-"}
                  </td>
                  <td style={{ borderRight: "1px solid gray" }}>
                    {row?.requiredQuantity || "-"}
                  </td>
                  <td style={{ borderRight: "1px solid gray" }}>
                    {row?.productCostPrice}{" "}
                  </td>
                  <td style={{ borderRight: "1px solid gray" }}>
                    {(row?.productCostPrice * row?.requiredQuantity)?.toFixed(2) || 0}
                  </td>
                </tr>
              ))}
              <tr style={{ borderBottom: "1px solid gray" }}>
                <td
                  colSpan="6"
                  style={{
                    textAlign: "center",
                    borderLeft: "1px solid gray",
                  }}
                >
                  total value
                </td>
                <td style={{ borderLeft: "1px solid gray" }}>
                  {PODetail?.totalValue || 0}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div>
          <p>Terms and Conditions:</p>
          <p>1. Please acknowledge the receipt of the purchase order.</p>
          <p>
            2. State product code and purchase order number on all invoices and
            delivery notes to facilitate timely payments.
          </p>
          <p>
            3. Stock will be accepted only if the validity of the stock is more
            then 80%.
          </p>
        </div>
        <di>
          <p>For NEW SHOP CSTORE NETWORK PRIVATE LIMTED</p>
          <div></div>
        </di>
      </div>
    );
  };
  return (
    <>
      {PoPdf()}
      <Button variant="outlined" onClick={handlePrint}>
        Print this out
      </Button>
    </>
  );
}
