import Utils from "../../utility";

const initialState = {
  dashboard: {},
  dashboardChartData: [],
  dashboardDetailData: [],
};
export const dashboardReducer = (state = initialState, action) => {
  switch (action.type) {
    case `${Utils.ActionName.DASHBOARD}`:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return { ...state };
  }
};
