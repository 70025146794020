import Utils from "../../../utility";

const initialState = {
  customerList: [],
  storeId: "",
  order: "DESC",
  sortField: "addDate",
  offset: 1,
  take: 10,
  search: "",
  count: 0,
};
export const customerReducer = (state = initialState, action) => {
  switch (action.type) {
    case `${Utils.ActionName.CUSTOMER}`:
      return {
        ...state,
        ...action.payload,
      };
    case `${Utils.ActionName.RESET_CUSTOMER}`:
      return initialState;
    default:
      return { ...state };
  }
};
