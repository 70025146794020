import moment from "moment";
import Utils from "../../../utility";

const initialState = {
  cashPendingReport: [],
  formDate: moment(),
  toDate: moment(),
  storeId: "",
  warehouseId: "",
  cashoutReport: [],
};
export const reportReducer = (state = initialState, action) => {
  switch (action.type) {
    case `${Utils.ActionName.STORE_AND_WAREHOUSE_REPORTS}`:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return { ...state };
  }
};
