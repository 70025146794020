import React, { useState } from "react";
import Button from "@mui/material/Button";
import { getStoreList } from "../../storeRelated/store/action";
import Stack from "@mui/material/Stack";
import Divider from "@mui/material/Divider";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import { CSVLink } from "react-csv";
import DatePicker from "../../../component/date";
import AutoComplete from "../../../component/input/autocomplete";
import { getWarehouseList } from "../../warehouseRelated/warehouses/action";
import Schema from "../../../schema";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { Formik, Form } from "formik";
import {
  getSKUTimeline,
  getSKUTimelineAll,
} from "../../storeRelated/SKUTimeline/action";
import { Box } from "@mui/material";

import Utils from "../../../utility";
import { getProducts } from "../../productRelated/products/action";
const headCells = [
  {
    id: "id",
    numeric: true,
    disablePadding: true,
    label: "Id",
    sortDisable: true,
  },
  {
    id: "brandName",
    numeric: false,
    disablePadding: true,
    label: "Date",
    sortDisable: false,
  },
  {
    id: "dateCreated",
    numeric: false,
    disablePadding: true,
    label: "Logs",
    sortDisable: true,
  },

  {
    id: "action",
    numeric: true,
    disablePadding: false,
    label: "Previous Quantity",
    sortDisable: true,
  },
  {
    id: "action",
    numeric: true,
    disablePadding: false,
    label: "Currnet Quantity Quantity",
    sortDisable: true,
  },
];
function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            sortDirection={
              orderBy === headCell.id ? order.toLowerCase() : false
            }
          >
            {headCell?.sortDisable ? (
              headCell.label
            ) : (
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={
                  orderBy === headCell.id ? order.toLowerCase() : "asc"
                }
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box component="span">
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </Box>
                ) : null}
              </TableSortLabel>
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
export default function EnhancedTable() {
  const [csvData, setCsvData] = useState([]);
  const [initialValue, setInitialValue] = useState({});
  const dispatch = useDispatch();
  const initialValues = {
    warehouseId: null,
    warehouseName: "",
    productName: "",
    toDate: moment(),
    fromDate: moment(),
    productId: null,
  };

  const headers = [
    { label: "Product ID", key: "ProductID" },
    { label: "Warehouse Name", key: "warehouseName" },
    { label: "Product Name", key: "ProductName" },
    { label: "Date", key: "date" },
    { label: "status", key: "status" },
    { label: "Previouse Quantity", key: "prevQuantity" },
    { label: "Current Quantity", key: "currentQuanttity" },
  ];

  const { skuData, offset, take, count } = useSelector(
    (state) => state.SkuTimelineReducer
  );
  // const { products } = useSelector((state) => state.productsReducer);
  // const { productsDropDownList } = useSelector((state) => state.warehouseReducer);

  React.useEffect(() => {
    // dispatch(getProducts());
    // dispatch(getStoreList());
    // dispatch(getSKUTimelineAll());
    // dispatch(getWarehouseList());
    return () => {
      dispatch({
        type: Utils.ActionName.SKU_TIMELINE,
        payload: { skuData: [] },
      });
    };
  }, []);
  React.useEffect(() => {
    if (skuData.length) {
      const data = skuData.map((item) => {
        return {
          warehouseName: initialValue.warehouseName,
          ProductID: initialValue.productId,
          ProductName: initialValue.productName,
          date: moment(item.addDate).format("YYYY-MM-DD HH:mm:ss"),
          status: item.flag,
          currentQuanttity: item.NewQuantity,
          prevQuantity: item.PreviousQuantity,
        };
      });
      setCsvData(data);
    }
  }, [skuData]);
  const handleChangePage = (event, newPage) => {
    dispatch({
      type: Utils.ActionName.SKU_TIMELINE,
      payload: { offset: newPage + 1 },
    });
    dispatch(getSKUTimelineAll());
  };

  const handleChangeRowsPerPage = (event) => {
    dispatch({
      type: Utils.ActionName.SKU_TIMELINE,
      payload: { take: event.target.value, offset: 1 },
    });
    dispatch(getSKUTimelineAll());
  };
  const {
    warehouseListForDropdown,
    // brandOwnerListForDropdown,
    productsDropDownList,
    // brandOwnerListForDropdown,
  } = useSelector((state) => state.commonReducer);

  return (
    <div>
      <Box direction="row" spacing={2} sx={{ display: "flex", mb: 2 }}>
        <Formik
          initialValues={initialValues}
          validationSchema={Schema.SKUTimelineSchema}
          onSubmit={(values, { setSubmitting }) => {
            setInitialValue(values);
            dispatch(getSKUTimeline(values));
          }}
        >
          {({
            values,
            errors,
            touched,
            setFieldValue,
            handleSubmit,
            /* and other goodies */
          }) => (
            <Form onSubmit={handleSubmit}>
              <Box sx={{ display: "flex" }}>
                <Stack direction="row" spacing={2} style={{ width: 200 }}>
                  <AutoComplete
                    freeSolo={false}
                    // onBlur={handleBlur}
                    values={values.warehouseId}
                    onChange={(event, value) => {
                      // console.log(event.target.value, value);
                      if (value !== null) {
                        setFieldValue("warehouseId", value?.id);
                        setFieldValue(
                          "warehouseName",
                          value?.warehouseNickname
                        );
                        dispatch({
                          type: Utils.ActionName.SKU_TIMELINE,
                          payload: { skuData: [] },
                        });
                      }
                    }}
                    helperText={touched.warehouseId && errors.warehouseId}
                    error={Boolean(touched.warehouseId && errors.warehouseId)}
                    name={"warehouseId"}
                    label={"warehouseId"}
                    touched={touched.warehouseId}
                    options={warehouseListForDropdown}
                    getOptionLabel={(option) => option?.warehouseNickname}
                    // isRequired={true}
                    getOptionSelected={(option, value) => {
                      return `${option.id}` === `${value.id}`;
                    }}
                    defaultValue={() => {
                      const index = productsDropDownList?.findIndex(
                        (x) => x?.id == values.warehouseId
                      );
                      return productsDropDownList[index];
                    }}
                  />
                </Stack>
                <Stack style={{ width: 200 }}>
                  <DatePicker
                    value={values.fromDate}
                    name="fromDate"
                    handleChange={(newVal) => setFieldValue("fromDate", newVal)}
                    label="From Date"
                    error={errors.fromDate}
                  />
                </Stack>
                <Stack direction="row" spacing={2} style={{ width: 200 }}>
                  <DatePicker
                    value={values.toDate}
                    name="toDate"
                    handleChange={(newVal) => setFieldValue("toDate", newVal)}
                    label="To Date"
                    error={errors.toDate}
                  />
                </Stack>
                <Stack direction="row" spacing={2} style={{ width: 200 }}>
                  <AutoComplete
                    freeSolo={false}
                    // onBlur={handleBlur}
                    values={values.productId}
                    onChange={(event, value) => {
                      if (value !== null) {
                        const index = productsDropDownList.findIndex(
                          (x) => x.id === value.id
                        );
                        setFieldValue("productId", value?.id);
                        setFieldValue("productName", value?.name);
                        dispatch({
                          type: Utils.ActionName.SKU_TIMELINE,
                          payload: { skuData: [] },
                        });
                      }
                    }}
                    helperText={touched.productId && errors.productId}
                    error={Boolean(touched.productId && errors.productId)}
                    name={"productId"}
                    label={"productId"}
                    touched={touched.productId}
                    options={productsDropDownList}
                    getOptionLabel={(option) =>
                      `${option?.barcode} | ${option.name}`
                    }
                    // isRequired={true}
                    getOptionSelected={(option, value) => {
                      return `${option.id}` === `${value.id}`;
                    }}
                    defaultValue={() => {
                      const index = productsDropDownList?.findIndex(
                        (x) => x?.id == values.productId
                      );
                      return productsDropDownList[index];
                    }}
                  />
                </Stack>

                <Button
                  variant="outlined"
                  spacing={2}
                  sx={{ mr: 2 }}
                  type="submit"
                >
                  Submit
                </Button>
                {skuData.length ? (
                  <CSVLink
                    data={csvData}
                    headers={headers}
                    filename={"warehouse-sku"}
                  >
                    <Button variant="outlined" spacing={2}>
                      Export
                    </Button>
                  </CSVLink>
                ) : (
                  <Button
                    variant="outlined"
                    spacing={2}
                    disabled={!skuData.length}
                  >
                    Export
                  </Button>
                )}
              </Box>
            </Form>
          )}
        </Formik>
      </Box>
      <Divider />
      {/* {skuData.length  */}
      <TableContainer>
        <Table
          sx={{ minWidth: 750 }}
          aria-labelledby="tableTitle"
          size={"medium"}
        >
          <EnhancedTableHead />
          <TableBody>
            {skuData.length &&
              skuData.map((row, index) => {
                return (
                  <TableRow hover tabIndex={-1} key={row.id}>
                    <TableCell
                      component="th"
                      id={index}
                      scope="row"
                      // padding="none"
                    >
                      {row.id}
                    </TableCell>
                    <TableCell align="left">
                      {moment(row.addDate).format("YYYY-MM-DD HH:mm:ss")}
                    </TableCell>
                    <TableCell align="left">{row.flag}</TableCell>
                    <TableCell align="left">
                      {row.PreviousQuantityWarehouse || "N/A"}
                    </TableCell>
                    {/* <TableCell align="left">
                      {row?.NewQuantityWarehouse ||
                        row?.NewQuantityStore ||
                        "N/A"}
                    </TableCell> */}
                    <TableCell align="left">
                      {row?.NewQuantityWarehouse || "N/A"}
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25]}
        component="div"
        count={count}
        rowsPerPage={take}
        page={offset - 1}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </div>
  );
}
