import Utils from "../../utility";

const initialState = {
  OM: [],
  runner: [],
  WM: [],
  biller: [],
  permissions: {},
  productsDropDownList: [],
  categoryListForDropDown: [],
  storeListForDropdown: [],
  warehouseListForDropdown: [],
  userlistForDropdown: [],
  propertyOwnerListForDropdown: [],
  brandOwnerListForDropdown: [],
  brandsForDropdown: [],
  royaltyPending: false,
};
export const commonReducer = (state = initialState, action) => {
  switch (action.type) {
    case `${Utils.ActionName.COMMON}`:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return { ...state };
  }
};
