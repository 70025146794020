import { combineReducers } from "redux";

// import { signInReducer } from "../screens/login/reducer";
import { userListReducer } from "../pages/userAccount/users/reducer";
import { propertyOwnerReducer } from "../pages/userAccount/propertyOwner/reducer";
import { storeReducer } from "../pages/storeRelated/store/reducer";
import { loaderReducer } from "./globalReducer";
import { warehouseReducer } from "../pages/warehouseRelated/warehouses/reducer";
import { productsReducer } from "../pages/productRelated/products/reducer";
import { categoryReducer } from "../pages/productRelated/productCategory/reducer";
import { brandReducer } from "../pages/productRelated/brands/reducer";
import { brandOwnerReducer } from "../pages/userAccount/brandOwner.js/reducer";
import { recipesReducer } from "../pages/productRelated/recipes/reducer";
import { warehouseStockReducer } from "../pages/warehouseRelated/warehouseStocks/reducer";
import { storeStockReducer } from "../pages/storeRelated/storeStocks/reducer";
import { warehouseToStoreReducer } from "../pages/storeTransfers/warehouseToStore/reducer";
import { customerReducer } from "../pages/userAccount/customer/reducer";
import { GRNReducer } from "../pages/storeRelated/GRN/reducer";
import { warehouseGRNReducer } from "../pages/warehouseRelated/warehouseGRN/reducer";
import { storeGRNReducer } from "../pages/storeRelated/storeGRN/reducer";
import { SkuTimelineReducer } from "../pages/storeRelated/SKUTimeline/reducer";
import { warehouseToWarehouseReducer } from "../pages/storeTransfers/warehouseToWarehouse/reducer";
import { storeToStoreReducer } from "../pages/storeTransfers/storeToStore/reducer";
import { storeToWarehouseReducer } from "../pages/storeTransfers/storeToWarehouse/reducer";
import { rtvStoreStockReducer } from "../pages/storeRelated/rtvStocks/reducer";
import { orderReducer } from "../pages/invoiceAndBilling/order/reducer";
import { POReducer } from "../pages/storeRelated/po/reducer";
import { dashboardReducer } from "../pages/dashboard/reducer";
import { bannerReducer } from "../pages/superAdmin/banner/reducer";
import { onlineOrdersReducer } from "../pages/superAdmin/onlineOrder/reducer";
import { warehousePOReducer } from "../pages/warehouseRelated/warehousePo/reducer";
import { commonReducer } from "../component/layout/reducer";
import { returnReducer } from "../pages/invoiceAndBilling/return/reducer";
import { creditsReducer } from "../pages/reports/credit/reducer";
import { reportReducer } from "../pages/storeRelated/pendingAndCashoutReport/reducer";
/**
 * combine all reducer into single root reducer
 */
const rootReducer = (state, action) => {
  if (action.type === "USER_LOGOUT") {
    return appReducer(undefined, action);
  }

  return appReducer(state, action);
};
const appReducer = combineReducers({
  userListReducer: userListReducer,
  propertyOwnerReducer: propertyOwnerReducer,
  storeReducer: storeReducer,
  loaderReducer: loaderReducer,
  warehouseReducer: warehouseReducer,
  productsReducer: productsReducer,
  categoryReducer: categoryReducer,
  brandReducer,
  brandOwnerReducer,

  recipesReducer,
  warehouseStockReducer,
  storeStockReducer,
  warehouseToStoreReducer,
  customerReducer,
  GRNReducer,
  warehouseGRNReducer,
  storeGRNReducer,
  SkuTimelineReducer,
  warehouseToWarehouseReducer,
  storeToStoreReducer,
  storeToWarehouseReducer,
  rtvStoreStockReducer,
  orderReducer,
  POReducer,
  dashboardReducer,
  bannerReducer,
  onlineOrdersReducer,
  warehousePOReducer,
  commonReducer,
  returnReducer,
  reportReducer,
  creditsReducer,
});

export default rootReducer;
