import * as React from "react";
import { useState } from "react";
import Select from "../../../component/select";
import MenuItem from "@mui/material/MenuItem";
import { useLocation } from "react-router-dom";
import moment from "moment";
import useDebounce from "../../../hooks/debounce";
import VerifiedIcon from "@mui/icons-material/Verified";

import Utils from "../../../utility";
import { getStoreList } from "../../storeRelated/store/action";
import { getWarehouseList } from "../../warehouseRelated/warehouses/action";
import { getRtvWarehouseStockList, verifyRtvWarehouse } from "./action";
import PropTypes from "prop-types";
import { alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import { visuallyHidden } from "@mui/utils";
import { useDispatch, useSelector } from "react-redux";
import Stack from "@mui/material/Stack";
import Modal from "../../../component/modal";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import DetailGRN from "./detailGRN";
import { getProducts } from "../../productRelated/products/action";
import { getRtvStoreStockList } from "../../storeRelated/rtvStocks/action";

const headCells = [
  {
    id: "id",
    numeric: true,
    disablePadding: true,
    label: "ID",
    sorting: false,
  },
  {
    id: "fromStore",
    numeric: true,
    disablePadding: true,
    label: "From Store",
    sorting: false,
  },
  {
    id: "storename",
    numeric: false,
    disablePadding: true,
    label: "Product Name",
    sorting: false,
  },
  {
    id: "address",
    numeric: false,
    disablePadding: true,
    label: "Product Barcode",
    sorting: false,
  },
  {
    id: "Vendor ID",
    numeric: false,
    disablePadding: true,
    label: "Brand",
  },
  {
    id: "Vendor",
    numeric: false,
    disablePadding: true,
    label: "Quantity",
    sorting: false,
  },
  {
    id: "Done By",
    numeric: false,
    disablePadding: true,
    label: "RTV Done By",
    sorting: false,
  },

  {
    id: "Invoice Number",
    numeric: false,
    disablePadding: true,
    label: "Date",
    sorting: false,
  },
  {
    id: "status",
    numeric: true,
    disablePadding: false,
    label: "status",
    sorting: false,
  },
  {
    id: "Store",
    numeric: true,
    disablePadding: false,
    label: "Action",
    sorting: false,
  },
];

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort, isWarehouseRoutes } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            sortDirection={
              orderBy === headCell?.id ? order.toLowerCase() : false
            }
          >
            {headCell?.sorting ? (
              <TableSortLabel
                active={orderBy === headCell?.id}
                direction={
                  orderBy === headCell?.id ? order.toLowerCase() : "asc"
                }
                onClick={createSortHandler(headCell?.id)}
              >
                {headCell.label}
                {orderBy === headCell?.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </Box>
                ) : null}
              </TableSortLabel>
            ) : isWarehouseRoutes === "/warehouse-rtvstocks" &&
              headCell.id === "fromStore" ? (
              "From warehouse"
            ) : (
              headCell.label
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  //onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["ASC", "DESC"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = (props) => {
  const {
    numSelected,
    storeId,
    storeList,
    warehouseList,
    handleStoreSelect,
    warehouseId,
    handleWarehouseSelect,
    isWarehouseRoutes,
  } = props;
  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
      }}
    >
      <Typography
        sx={{ flex: "1 1 100%" }}
        variant="h6"
        id="tableTitle"
        component="div"
      >
        RTV Stocks
      </Typography>
      <Stack sx={{ width: "250px", marginLeft: 1 }}>
        {isWarehouseRoutes === "/warehouse-rtvstocks" ? (
          <Select
            handleChange={handleWarehouseSelect}
            value={warehouseId}
            label={"Select Warehouse"}
            option={warehouseList?.map((item) => (
              <MenuItem key={item.id} value={item?.id}>
                {item?.warehouseNickname}
              </MenuItem>
            ))}
            name="manager"
          />
        ) : (
          <Select
            handleChange={handleStoreSelect}
            value={storeId}
            label={"Select Stores"}
            option={storeList?.map((item) => (
              <MenuItem key={item?.id} value={item?.id}>
                {item?.storename}
              </MenuItem>
            ))}
            name="Stores"
          />
        )}
      </Stack>
      {/* 
      <Tooltip title="Filter list">
        <IconButton>
          <FilterListIcon />
        </IconButton>
      </Tooltip> */}
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

export default function EnhancedTable() {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [warehouseId, setWarehouseId] = React.useState(null);

  const [isEdit, setIsEdit] = useState(null);
  let isWarehouseRoutes = useLocation().pathname;
  const [storeId, setStoreId] = useState(null);
  const { rtvStoreStock, order, sortField, offset, take, search, count } =
    useSelector((state) => state.rtvStoreStockReducer);

  const debouncedSearch = useDebounce(search, 500);

  React.useEffect(() => {
    // dispatch(getWarehouseList());
    // dispatch(getProducts());
    // dispatch(getStoreList());
  }, [dispatch]);
  React.useEffect(() => {
    if (isWarehouseRoutes === "/warehouse-rtvstocks") {
      dispatch(getRtvWarehouseStockList(storeId, "warehouse"));
    } else {
      dispatch(getRtvWarehouseStockList(storeId, "store"));
    }
  }, [debouncedSearch]);
  const handleRequestSort = (event, property) => {
    const isAsc = sortField === property && order === "ASC";
    dispatch({
      type: Utils.ActionName.GRN,
      payload: { sortField: property, order: isAsc ? "DESC" : "ASC" },
    });
  };

  const handleChangePage = (event, newPage) => {
    dispatch({
      type: Utils.ActionName.RTV_STORE_STOCK,
      payload: { offset: newPage + 1 },
    });
    dispatch(getRtvWarehouseStockList());
  };

  const handleChangeRowsPerPage = (event) => {
    dispatch({
      type: Utils.ActionName.RTV_STORE_STOCK,
      payload: { take: event.target.value, offset: 1 },
    });
    dispatch(getRtvWarehouseStockList());
  };

  const handleSearch = (e) => {
    const searchedVal = e.target.value.trim();
    dispatch({
      type: Utils.ActionName.RTV_STORE_STOCK,
      payload: { search: searchedVal },
    });
  };

  const { storeList } = useSelector((state) => state.storeReducer);
  // const { warehouseList } = useSelector((state) => state.warehouseReducer);
  const {
    warehouseListForDropdown,
    // brandOwnerListForDropdown,
    // productsDropDownList,
    // brandOwnerListForDropdown,
  } = useSelector((state) => state.commonReducer);

  const handleWarehouseSelect = (e) => {
    setWarehouseId(e.target.value);
    dispatch(getRtvWarehouseStockList(e.target.value, "warehouse"));
  };
  const handleStoreSelect = (e) => {
    setStoreId(e.target.value);
    dispatch(getRtvWarehouseStockList(e.target.value, "store")); // dispatch(getStoreStockList());
  };
  const { permissions } = useSelector((state) => state.commonReducer);

  // const { GRNList } = useSelector((state) => state.GRNReducer);
  return (
    <>
      <Box sx={{ width: "100%" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: 10,
          }}
        >
          <Stack direction="row" spacing={1}>
            <Stack direction="row" spacing={2} style={{ width: 200 }}>
              <Stack direction="row" spacing={2}>
                {!permissions || permissions["warehouseRTV"]?.add ? (
                  <Button
                    variant="outlined"
                    startIcon={<AddCircleOutlineOutlinedIcon />}
                    onClick={() => setOpen(true)}
                  >
                    Add RTV
                  </Button>
                ) : (
                  <></>
                )}
              </Stack>
            </Stack>
            <Stack direction="row" spacing={2} style={{ width: 200 }}></Stack>
          </Stack>
          <TextField
            id="standard-search"
            label="Search field"
            type="search"
            value={search}
            variant="standard"
            onChange={handleSearch}
            InputProps={{
              endAdornment: (
                <InputAdornment>
                  <IconButton>
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </div>
        <Paper sx={{ width: "100%", mb: 2 }}>
          <EnhancedTableToolbar
            storeList={storeList}
            warehouseList={warehouseListForDropdown}
            warehouseId={warehouseId}
            storeId={storeId}
            isWarehouseRoutes={isWarehouseRoutes}
            handleWarehouseSelect={handleWarehouseSelect}
            handleStoreSelect={handleStoreSelect}
          />
          <TableContainer>
            <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
              <EnhancedTableHead
                order={order}
                isWarehouseRoutes={isWarehouseRoutes}
                orderBy={sortField}
                onRequestSort={handleRequestSort}
                // rowCount={storeList.length}
              />
              <TableBody>
                {rtvStoreStock.length ? (
                  rtvStoreStock.map((row, index) => {
                    if (
                      isWarehouseRoutes === "/warehouse-rtvstocks" &&
                      row?.rtvStocks?.store?.id
                    ) {
                      return false;
                    } else {
                      if (
                        isWarehouseRoutes === "/rtvstocks" &&
                        row?.rtvStocks?.warehouse?.id
                      ) {
                        return false;
                      }
                    }

                    return (
                      <TableRow
                        hover
                        // onClick={(event) => handleClick(event, row.name)}
                        // role="checkbox"
                        // aria-checked={isItemSelected}
                        // tabIndex={-1}
                        key={row?.id}
                        // selected={isItemSelected}
                      >
                        {/* <TableCell padding="checkbox">
                        <Checkbox
                          color="primary"
                          checked={isItemSelected}
                          inputProps={{
                            'aria-labelledby': labelId,
                          }}
                        />
                      </TableCell> */}
                        <TableCell
                          component="th"
                          id={index}
                          scope="row"
                          onClick={() => {
                            // dispatch(getGRNById(row?.id));
                            setOpen(true);
                          }}
                          // padding="none"
                        >
                          {row?.id}
                        </TableCell>
                        <TableCell align="left">
                          {row?.rtvStocks?.warehouse?.warehouseNickname}
                        </TableCell>
                        <TableCell align="left">{row?.product?.name}</TableCell>
                        <TableCell align="left">
                          {row?.product?.barcode}
                        </TableCell>
                        <TableCell align="left">-</TableCell>
                        <TableCell align="left">{row?.quantity}</TableCell>
                        <TableCell align="left">
                          {row?.rtvStocks?.doneBy?.name}
                        </TableCell>
                        <TableCell align="left">
                          {moment(row?.rtvStocks?.addDate).format("DD-MM-YYYY")}
                        </TableCell>
                        <TableCell align="left">
                          {row?.approvedStatus}
                        </TableCell>
                        <TableCell align="left">
                          {row?.approvedStatus !== "approved" && (
                            <VerifiedIcon
                              onClick={() =>
                                dispatch(
                                  verifyRtvWarehouse(row?.id, () => {
                                    if (
                                      isWarehouseRoutes ===
                                      "/warehouse-rtvstocks"
                                    ) {
                                      dispatch(
                                        getRtvWarehouseStockList(
                                          storeId,
                                          "warehouse"
                                        )
                                      );
                                    } else {
                                      dispatch(
                                        getRtvWarehouseStockList(
                                          storeId,
                                          "store"
                                        )
                                      );
                                    }
                                  })
                                )
                              }
                            />
                          )}
                        </TableCell>

                        {/* <TableCell align="left">
                        <>
                          {" "}
                          <EditIcon
                            onClick={() => {
                              setIsEdit(
                                storeList.filter((item) => item.id === row.id)
                              );
                              setOpen(true);
                            }}
                          />
                          <DeleteIcon
                            onClick={() => dispatch(deleteStore(row?.id))}
                          />
                        </>
                      </TableCell> */}
                      </TableRow>
                    );
                  })
                ) : (
                  <TableRow sx={{ textAlign: "center" }}>
                    No data Found
                    {/* <TableCell sx={{ width: "100%" }} align="left"></TableCell> */}
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25]}
            component="div"
            count={count}
            rowsPerPage={take}
            page={offset - 1}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </Box>
      <Modal
        header={"Add RTV"}
        children={
          <DetailGRN
            setOpen={setOpen}
            isEdit={isEdit}
            setIsEdit={setIsEdit}
            getUpdatedListing={() => {
              if (isWarehouseRoutes === "/warehouse-rtvstocks") {
                dispatch(getRtvWarehouseStockList(storeId, "warehouse"));
              } else {
                dispatch(getRtvWarehouseStockList(storeId, "store"));
              }
            }}
          />
        }
        handleClose={() => {
          setOpen(false);
          setIsEdit(null);
        }}
        open={open}
        minWidth={1000}
      />
    </>
  );
}
