import * as React from "react";
import Utils from "../../../utility";
// import { getCustomerList } from "./action";
import PropTypes from "prop-types";
import useDebounce from "../../../hooks/debounce";
import { alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import { visuallyHidden } from "@mui/utils";
import { useDispatch, useSelector } from "react-redux";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import { getSKUTimeline } from "./action";
import moment from "moment";
import { discardInvoice } from "../rtvStocks/action";
import { returnInvoice } from "../../invoiceAndBilling/return/action";
import { orderInvoice } from "../../invoiceAndBilling/order/action";
import { getGRNById } from "../GRN/action";

const headCells = [
  {
    id: "id",
    numeric: true,
    disablePadding: true,
    label: "Date",
    sorting: false,
  },
  {
    id: "userName",
    numeric: false,
    disablePadding: true,
    label: "Id",
    sorting: true,
  },
  {
    id: "address",
    numeric: true,
    disablePadding: false,
    label: "Done by",
    sorting: false,
  },
  {
    id: "phoneNumber",
    numeric: true,
    disablePadding: false,
    label: "Quantity",
    sorting: true,
  },
];

function EnhancedTableHead(props) {
  const { onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  const { order, sortField } = useSelector((state) => state.customerReducer);
  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            sortDirection={
              sortField === headCell.id ? order.toLowerCase() : false
            }
          >
            {headCell?.sorting ? (
              <TableSortLabel
                active={sortField === headCell.id}
                direction={
                  sortField === headCell.id ? order.toLowerCase() : "asc"
                }
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {sortField === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === "DESC"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </Box>
                ) : null}
              </TableSortLabel>
            ) : (
              headCell.label
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  //onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["ASC", "DESC"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

// const EnhancedTableToolbar = (props) => {
//   const { numSelected } = props;

//   return (
//     <Toolbar
//       sx={{
//         pl: { sm: 2 },
//         pr: { xs: 1, sm: 1 },
//         ...(numSelected > 0 && {
//           bgcolor: (theme) =>
//             alpha(
//               theme.palette.primary.main,
//               theme.palette.action.activatedOpacity
//             ),
//         }),
//       }}
//     >
//       {/* <Typography
//         sx={{ flex: "1 1 100%" }}
//         variant="h6"
//         id="tableTitle"
//         component="div"
//       >
//         Customer
//       </Typography> */}
//     </Toolbar>
//   );
// };

export default function EnhancedTable({ value, type, setOpen }) {
  const dispatch = useDispatch();
  const { skuData, order, sortField, offset, take, search, count } =
    useSelector((state) => state.SkuTimelineReducer);
  //   const debouncedSearch = useDebounce(search, 500);

  //   React.useEffect(() => {
  //     if (storeId) dispatch(getCustomerList());
  //     return () => {
  //       dispatch({
  //         type: Utils.ActionName.RESET_CUSTOMER,
  //       });
  //     };
  //   }, [debouncedSearch]);

  const handleRequestSort = (event, property) => {
    const isAsc = sortField === property && order === "ASC";
    // setOrder(isAsc ? "desc" : "asc");
    dispatch({
      type: Utils.ActionName.SKU_TIMELINE,
      payload: { sortField: property, order: isAsc ? "DESC" : "ASC" },
    });
    dispatch(getSKUTimeline(value, type));
    // setOrderBy(property);
  };

  // const handleSelectAllClick = (event) => {
  //   if (event.target.checked) {
  //     const newSelecteds = userlist.map((n) => n.name);
  //     setSelected(newSelecteds);
  //     return;
  //   }
  //   setSelected([]);
  // };

  const handleChangePage = (event, newPage) => {
    dispatch({
      type: Utils.ActionName.SKU_TIMELINE,
      payload: { offset: newPage + 1 },
    });
    dispatch(getSKUTimeline(value, type));
  };

  const handleChangeRowsPerPage = (event) => {
    // setRowsPerPage(parseInt(event.target.value, 10));
    // setPage(0);
    dispatch({
      type: Utils.ActionName.SKU_TIMELINE,
      payload: { take: event.target.value, offset: 1 },
    });
    dispatch(getSKUTimeline(value, type));
  };
  //   const { skuData } = useSelector(
  //     (state) => state.SkuTimelineReducer
  //   );
  // const { permissions } = useSelector((state) => state.commonReducer);
  //   const { storeListForDropdown } = useSelector((state) => state.commonReducer);
  const getDate = (row) => {
    if (type == "GRN") return moment(row?.grn?.createdAt).format("DD-MM-YYYY");
    if (type === "ORDERS") return moment(row?.createdAt).format("DD-MM-YYYY");
    if (type === "RETURNS")
      return moment(row?.return?.createdAt).format("DD-MM-YYYY");
    if (["RTV", "DISCARD"].includes(type))
      return moment(row?.rtvStocks?.addDate).format("DD-MM-YYYY");
    if (["TRANSFEROUT", "TRANSFERIN"].includes(type))
      return moment(row?.createdAt).format("DD-MM-YYYY");
  };
  const getId = (row) => {
    if (type == "GRN") return row?.grn?.id;
    if (type === "ORDERS") return row?.id;
    if (type === "RETURNS") return row?.return?.id;
    if (["RTV", "DISCARD"].includes(type)) return row?.rtvStocks?.id;
    if (["TRANSFEROUT", "TRANSFERIN"].includes(type)) return row?.transferId;
    // if (type === "DISCARD") return row?.rtvStocks?.id;
  };
  const getDoneBy = (row) => {
    if (type == "GRN") return row?.grn?.createdBy?.name;
    if (type === "ORDERS") return row?.biller?.name;
    if (["RTV", "DISCARD"].includes(type)) return row?.rtvStocks?.doneBy?.name;
    if (["TRANSFEROUT", "TRANSFERIN"].includes(type))
      return row?.operator?.name;
  };

  const getQuantity = (row) => {
    if (type == "GRN") return row?.recievedQuantity;
    if (type === "ORDERS")
      return row?.actualBillingSent && row?.actualBillingSent[0]?.quantity;
    if (type === "RETURNS") return row?.quantity;
    if (["RTV", "DISCARD"].includes(type)) return row?.quantity;
    if (["TRANSFEROUT", "TRANSFERIN"].includes(type))
      return (
        row?.s2sProducts &&
        row?.s2sProducts?.length &&
        row?.s2sProducts[0]?.quantity
      );
  };
  const getDetailIvoice = (row) => {
    if (type == "GRN") {
      dispatch(getGRNById(row?.grn?.id));
      setOpen(true);
    }
    if (["RTV", "DISCARD"].includes(type))
      return dispatch(discardInvoice(row?.rtvStocks?.id));
    if (type === "ORDERS") dispatch(orderInvoice(row?.stateInvoiceNo));
    if (type === "RETURNS") dispatch(returnInvoice(row.return?.id));
  };
  return (
    <>
      <Box sx={{ width: "100%" }}>
        <Paper sx={{ width: "100%" }}>
          {/* <EnhancedTableToolbar /> */}
          <TableContainer>
            <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
              <EnhancedTableHead
                order={order}
                orderBy={sortField}
                onRequestSort={handleRequestSort}
              />
              <TableBody>
                {skuData?.length &&
                  skuData.map((row, index) => {
                    return (
                      <TableRow hover tabIndex={-1} key={row.name}>
                        <TableCell align="left">{getDate(row)}</TableCell>
                        <TableCell
                          align="left"
                          onClick={() => getDetailIvoice(row)}
                          sx={{ cursor: "pointer" }}
                        >
                          {getId(row)}
                        </TableCell>
                        <TableCell align="left">{getDoneBy(row)}</TableCell>
                        <TableCell align="left">{getQuantity(row)}</TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25]}
            component="div"
            count={count}
            rowsPerPage={take}
            page={offset - 1}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </Box>
    </>
  );
}
