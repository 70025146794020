import Utils from "../../../utility";

const initialState = {
  storeList: [],
  order: "DESC",
  sortField: "addDate",
  offset: 1,
  take: 10,
  search: "",
  count: 0,
  oprationManagerList: [],
  billerList: [],
  runnerList: [],
  storeDetail: {},
  monthWiseRoyalty: [],
  storeId: "",
  storeId: "",
  status: "pending",
  royaltyHistory: [],
  royaltyHistoryDetail: {},
};
export const storeReducer = (state = initialState, action) => {
  switch (action.type) {
    case `${Utils.ActionName.STORE}`:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return { ...state };
  }
};
