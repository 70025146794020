import Utils from "../../../utility";

const initialState = {
  storeId: null,
  toDate: null,
  fromDate: null,
  productId: null,
  skuData: [],
  order: "DESC",
  sortField: "id",
  offset: 1,
  take: 10,
  search: "",
  count: 0,
  skuExportData: [],
  skuTypeWithQuantity: {},
};
export const SkuTimelineReducer = (state = initialState, action) => {
  switch (action.type) {
    case `${Utils.ActionName.SKU_TIMELINE}`:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return { ...state };
  }
};
