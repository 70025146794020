import Utils from "../../../utility";

const initialState = {
  brands: [],
  count: 0,
  order: "DESC",
  sortField: "addDate",
  offset: 1,
  take: 10,
  search: "",
  count: 0,
};
export const brandReducer = (state = initialState, action) => {
  switch (action.type) {
    case `${Utils.ActionName.BRAND}`:
      return {
        ...state,
        ...action.payload,
      };
    case `${Utils.ActionName.RESET_BRAND}`:
      return initialState;
    default:
      return { ...state };
  }
};
