import Utils from "../../../utility";

export const updatePropertyOwner = (
  values,
  setOpen,
  id,
  resetForm,
  setIsEdit
) => {
  return (dispatch) => {
    dispatch({
      type: Utils.ActionName.LOADING,
      payload: { loading: true },
    });
    const { brandName } = values;
    const dataToSend = {
      brandName,
    };

    Utils.api.putApiCall(
      Utils.endPoints.updateBrand + id,
      dataToSend,
      (respData) => {
        setOpen(false);
        Utils.showAlert(1, "Brand updated successfully");
        // dispatch(getAllUser());
        resetForm({});
        setIsEdit(null);
        dispatch(getBrandList());
        // dispatch({type:Utils.ActionName.USER_LIST
        //   ,payload:{userlist:respData.data}})
        // navigate("/dashboard");
      },
      (error) => {
        let { data } = error;
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });
        Utils.showAlert(
          2,
          `${Utils.endPoints.updateBrand} PUT Method error ${
            JSON.stringify(data?.message) || data?.error
          }`
        );
        // Utils.showAlert(2, data?.message?.join(",") || data?.error);
      }
    );
  };
};
export const addBrand = (values, setOpen) => {
  return (dispatch) => {
    dispatch({
      type: Utils.ActionName.LOADING,
      payload: { loading: true },
    });
    const { brandName } = values;
    const dataToSend = {
      brandName,
      isDeleted: false,
    };

    Utils.api.postApiCall(
      Utils.endPoints.createBrand,
      dataToSend,
      (respData) => {
        setOpen(false);
        Utils.showAlert(1, "Brand added successfully");
        dispatch(getBrandList());
      },
      (error) => {
        let { data } = error;
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });
        // Utils.showAlert(2, data?.message?.join(",") || data?.error);
        Utils.showAlert(
          2,
          `${Utils.endPoints.createBrand} POST Method error ${
            JSON.stringify(data?.message) || data?.error
          }`
        );
        // setSubmitting(true);
      }
    );
  };
};

export const deleteBrand = (id) => {
  return (dispatch) => {
    dispatch({
      type: Utils.ActionName.LOADING,
      payload: { loading: true },
    });
    Utils.api.deleteApiCall(
      Utils.endPoints.updateBrand + `${id}`,
      "",
      (respData) => {
        Utils.showAlert(1, "Brand delete sucesfully");
        dispatch(getBrandList());
      },
      (error) => {
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });
        let { data } = error;
        Utils.showAlert(
          2,
          `Delete ${Utils.endPoints.updateBrand} DELETE Method error ${
            JSON.stringify(data?.message) || data?.error
          }`
        );
        // Utils.showAlert(2, data?.message?.join(",") || data?.error);
        // setSubmitting(true);
      }
    );
  };
};

export const getBrandList = (allList = false) => {
  return (dispatch, getState) => {
    const { order, sortField, offset, take, search } = getState().brandReducer;

    dispatch({
      type: Utils.ActionName.LOADING,
      payload: { loading: true },
    });
    Utils.api.getApiCall(
      Utils.endPoints.brands,
      `?order=${order}&sortField=${sortField}&offset=${
        allList ? -1 : offset
      }&take=${take}&search=${search}`,
      (respData) => {
        dispatch({
          type: Utils.ActionName.BRAND,
          payload: { brands: respData.data.brand, count: respData.data.count },
        });
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });
        // navigate("/dashboard");
      },
      (error) => {
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });
        let { data } = error;
        Utils.showAlert(
          2,
          `${Utils.endPoints.brands} GET Method error ${
            JSON.stringify(data?.message) || data?.error
          }`
        );
        // Utils.showAlert(2, data?.message?.join(",") || data?.error);
        // setSubmitting(true);
      }
    );
  };
};

export const getBrandListForDropdown = (allList = false) => {
  return (dispatch, getState) => {
    const { order, sortField, offset, take, search } = getState().brandReducer;

    dispatch({
      type: Utils.ActionName.LOADING,
      payload: { loading: true },
    });
    Utils.api.getApiCall(
      Utils.endPoints.brands,
      `?order=${order}&sortField=${sortField}&offset=${
        allList ? -1 : offset
      }&search=${search}`,
      (respData) => {
        dispatch({
          type: Utils.ActionName.COMMON,
          payload: { brandsForDropdown: respData.data.brand },
        });
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });
        // navigate("/dashboard");
      },
      (error) => {
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });
        let { data } = error;
        Utils.showAlert(
          2,
          `${Utils.endPoints.brands} GET Method error ${
            JSON.stringify(data?.message) || data?.error
          }`
        );
        // Utils.showAlert(2, data?.message?.join(",") || data?.error);
        // setSubmitting(true);
      }
    );
  };
};
// export const getPropertyOwner = () => {
//   return (dispatch) => {
//     // const { email, password } = values;
//     // const dataToSend = {
//     //   username:email,
//     //   password,
//     // };
//     Utils.api.getApiCall(
//       Utils.endPoints.propertyOwner,
//       "",
//       (respData) => {
//         dispatch({
//           type: Utils.ActionName.PROPERTY_OWNER,
//           payload: { propertyOwnerList: respData.data },
//         });
//         // navigate("/dashboard");
//       },
//       (error) => {
//         let { data } = error;
//         Utils.showAlert(2, data?.message?.join(",") || data?.error);
//         // setSubmitting(true);
//       }
//     );
//   };
// };
