import Utils from "../../../utility";
import moment from "moment";
// export const getSKUTimeline = (values) => {
//   return (dispatch, getState) => {
//     const { storeId, productId, toDate, fromDate, warehouseId } = values;
//     const { order, sortField, offset, take } = getState().SkuTimelineReducer;
//     dispatch({
//       type: Utils.ActionName.LOADING,
//       payload: { loading: true },
//     });

//     Utils.api.getApiCall(
//       Utils.endPoints.SKUTimelineSpecific,
//       `?productID=${productId}${true ? "&storeID=" + storeId : ""}${
//         warehouseId ? "&warehouseID=" + warehouseId : ""
//       }&order=${order}&sortField=${sortField}&offset=${offset}&take=${take}&fromDate=${moment(
//         fromDate
//       ).format("YYYY-MM-DD")}T00:00&toDate=${moment(toDate).format(
//         "YYYY-MM-DD"
//       )}T23:59:59`,
//       (respData) => {
//         dispatch({
//           type: Utils.ActionName.LOADING,
//           payload: { loading: false },
//         });

//         dispatch({
//           type: Utils.ActionName.SKU_TIMELINE,
//           payload: {
//             skuData: respData.data?.result ? respData.data?.result : [],
//             count: respData?.data?.count || 0,
//           },
//         });
//         // navigate("/dashboard");
//       },
//       (error) => {
//         dispatch({
//           type: Utils.ActionName.LOADING,
//           payload: { loading: false },
//         });
//         let { data } = error;
//         Utils.showAlert(
//           2,
//           `${Utils.endPoints.SKUTimelineSpecific} GET Method error ${
//             JSON.stringify(data?.message) || data?.error
//           }`
//         );
//         // Utils.showAlert(2, data?.message?.join(",") || data?.error);
//         // setSubmitting(true);
//       }
//     );
//   };
// };

export const getSKUTimeline = (values, type) => {
  return (dispatch, getState) => {
    const { storeId, productId, toDate, fromDate, warehouseId } = values;
    const { order, sortField, offset, take } = getState().SkuTimelineReducer;
    dispatch({
      type: Utils.ActionName.LOADING,
      payload: { loading: true },
    });

    Utils.api.getApiCall(
      Utils.endPoints.skuDetail,
      `?productID=${productId}${true ? "&storeID=" + storeId : ""}${
        warehouseId ? "&warehouseID=" + warehouseId : ""
      }&order=${order}&sortField=${sortField}&offset=${offset}&take=${take}&fromDate=${moment(
        fromDate
      ).format("YYYY-MM-DD")}T00:00&toDate=${moment(toDate).format(
        "YYYY-MM-DD"
      )}T23:59:59&type=${type}`,
      (respData) => {
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });

        dispatch({
          type: Utils.ActionName.SKU_TIMELINE,
          payload: {
            skuData: respData.data?.data ? respData.data?.data : [],
            count: respData?.data?.count || 0,
          },
        });
        // navigate("/dashboard");
      },
      (error) => {
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });
        let { data } = error;
        Utils.showAlert(
          2,
          `${Utils.endPoints.SKUTimelineSpecific} GET Method error ${
            JSON.stringify(data?.message) || data?.error
          }`
        );
        // Utils.showAlert(2, data?.message?.join(",") || data?.error);
        // setSubmitting(true);
      }
    );
  };
};
/**
 *
 * @returns get sku time line quantity
 */

export const getSKUTTypeWithquantity = (values) => {
  return (dispatch, getState) => {
    const { storeId, productId, toDate, fromDate, warehouseId } = values;
    const { order, sortField, offset, take } = getState().SkuTimelineReducer;
    dispatch({
      type: Utils.ActionName.LOADING,
      payload: { loading: true },
    });

    Utils.api.getApiCall(
      Utils.endPoints.sku,
      `?productID=${productId}${true ? "&storeID=" + storeId : ""}${
        warehouseId ? "&warehouseID=" + warehouseId : ""
      }&order=${order}&sortField=${sortField}&offset=${offset}&take=${take}&fromDate=${moment(
        fromDate
      ).format("YYYY-MM-DD")}T00:00&toDate=${moment(toDate).format(
        "YYYY-MM-DD"
      )}T23:59:59`,
      (respData) => {
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });

        dispatch({
          type: Utils.ActionName.SKU_TIMELINE,
          payload: {
            skuTypeWithQuantity: respData.data ? respData.data : {},
            count: respData?.data?.count || 0,
          },
        });
        // navigate("/dashboard");
      },
      (error) => {
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });
        let { data } = error;
        Utils.showAlert(
          2,
          `${Utils.endPoints.SKUTimelineSpecific} GET Method error ${
            JSON.stringify(data?.message) || data?.error
          }`
        );
        // Utils.showAlert(2, data?.message?.join(",") || data?.error);
        // setSubmitting(true);
      }
    );
  };
};
export const getSKUTimelineAll = () => {
  return (dispatch, getState) => {
    const { order, sortField, offset, take, search } =
      getState().SkuTimelineReducer;
    // const { storeId, productId, toDate, fromDate } = values;
    dispatch({
      type: Utils.ActionName.LOADING,
      payload: { loading: true },
    });

    Utils.api.getApiCall(
      Utils.endPoints.SKUTimeline,
      `?order=${order}&sortField=${sortField}&offset=${offset}&take=${take}&search=${search}`,
      (respData) => {
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });
        dispatch({
          type: Utils.ActionName.SKU_TIMELINE,
          payload: {
            skuData: respData.data?.skuTimelines,
            count: respData.data?.count,
          },
        });
        // navigate("/dashboard");
      },
      (error) => {
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });
        let { data } = error;
        Utils.showAlert(
          2,
          `${Utils.endPoints.SKUTimeline} GET Method error ${
            JSON.stringify(data?.message) || data?.error
          }`
        );
        // Utils.showAlert(2, data?.message?.join(",") || data?.error);
        // setSubmitting(true);
      }
    );
  };
};

/**
 * generate SKU report
 */

export const generatReport = (values, csvDownload) => {
  return (dispatch, getState) => {
    dispatch({
      type: Utils.ActionName.LOADING,
      payload: { loading: true },
    });
    const { order, sortField, offset, take } = getState().SkuTimelineReducer;

    const { storeId, toDate, fromDate, productId } = values;
    Utils.api.getApiCall(
      Utils.endPoints.SKUTimelineSpecific,
      `?fromDate=${moment(fromDate).format("YYYY-MM-DD")}T00:00&toDate=${moment(
        toDate
      ).format("YYYY-MM-DD")}T23:59:59${
        storeId ? `&storeID=${storeId}` : ""
      }&productID=${productId}&sortField=${sortField}&offset=${offset}`,
      (respData) => {
        dispatch({
          type: Utils.ActionName.SKU_TIMELINE,
          payload: {
            skuExportData: respData.data?.result,
          },
        });

        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });
        setTimeout(() => {
          csvDownload.current.link.click();
        }, 500);
        // const linkSource = `data:application/csv;base64,${respData.data}`;
        // const downloadLink = document.createElement("a");
        // const fileName = `order_report.csv`;
        // downloadLink.href = linkSource;
        // downloadLink.download = fileName;
        // downloadLink.click();
        // setTimeout(() => {
        //   csvDownload.current.link.click();
        // }, 500);
        // setSubmitting(false);
        // if (respData.data?.order?.length)
      },
      (error) => {
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });
        let { data } = error;
        Utils.showAlert(
          2,
          `${Utils.endPoints.orderCSVReport} GET Method error ${
            JSON.stringify(data?.message) || data?.error
          }`
        );
        // Utils.showAlert(2, data?.message?.join(",") || data?.error);
        // setSubmitting(false);
      }
    );
  };
};
