import Utils from "../../../utility";

const initialState = {
  count: 0,
  loading: true,
  order: "ASC",
  sortField: "name",
  offset: 1,
  take: 10,
  creditData: [],
  customerCreditData: [],
  storeId:null,
};
export const creditsReducer = (state = initialState, action) => {
  switch (action.type) {
    case `${Utils.ActionName.CREDIT}`:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return { ...state };
  }
};
