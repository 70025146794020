import Utils from "../../../utility";

const initialState = {
  rtvStoreStock: [],
  type: "",
  order: "ASC",
  sortField: "userName",
  offset: 1,
  take: 10,
  search: "",
  count: 0,
  oprationManagerList: [],
  billerList: [],
  warehouseId: "",
};
export const rtvStoreStockReducer = (state = initialState, action) => {
  switch (action.type) {
    case `${Utils.ActionName.RTV_STORE_STOCK}`:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return { ...state };
  }
};
