import * as React from "react";
import { useState } from "react";
import Utils from "../../../utility";
import { CSVLink } from "react-csv";
import PropTypes from "prop-types";
import { alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
// import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
// import useDebounce from "../../../hooks/debounce";

import Button from "@mui/material/Button";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import { visuallyHidden } from "@mui/utils";
import { useDispatch, useSelector } from "react-redux";
import Stack from "@mui/material/Stack";
const headers = [
  { label: "Product ID", key: "ProductID" },
  { label: "Product Name", key: "ProductName" },
  { label: "Batch", key: "Batch" },
  { label: "Invoice Quantity", key: "InvoiceQuantity" },
  { label: "Recd. Quantity", key: "RecdQuantity" },
  // { label: "Short Quantity", key: "ShortQuantity" },
  { label: "MRP", key: "MRP" },
  { label: "Total Amount", key: "TotalAmount" },
  { label: "Vendor Name", key: "venderName" },
  { label: "Po", key: "po" },
  { label: "Invoice", key: "Invoice" },
  { label: "GRN by", key: "GRNBy" },
  { label: "Vehicle", key: "vehicle" },
];

const headCells = [
  {
    id: "id",
    numeric: true,
    disablePadding: true,
    label: "Product ID",
    sorting: false,
  },
  {
    id: "storename",
    numeric: false,
    disablePadding: true,
    label: "Product Name",
    sorting: false,
  },
  {
    id: "address",
    numeric: false,
    disablePadding: true,
    label: "Batch ",
    sorting: false,
  },
  {
    id: "manager",
    numeric: false,
    disablePadding: true,
    label: "Invoice Quantity",
  },
  {
    id: "oprator",
    numeric: false,
    disablePadding: true,
    label: "Recd. Quantity",
    sorting: false,
  },

  {
    id: "storeType",
    numeric: false,
    disablePadding: true,
    label: "MRP",
    sorting: false,
  },
  {
    id: "Total Amount",
    numeric: true,
    disablePadding: false,
    label: "Total Amount",
    sorting: false,
  },
];

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead sx={{ background: "black" }}>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            sortDirection={
              orderBy === headCell?.id ? order.toLowerCase() : false
            }
          >
            {headCell?.sorting ? (
              <TableSortLabel
                active={orderBy === headCell?.id}
                direction={
                  orderBy === headCell?.id ? order.toLowerCase() : "asc"
                }
                onClick={createSortHandler(headCell?.id)}
              >
                {headCell.label}
                {orderBy === headCell?.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </Box>
                ) : null}
              </TableSortLabel>
            ) : (
              headCell.label
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["ASC", "DESC"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = (props) => {
  const { numSelected } = props;
  const { GRNDetail } = useSelector((state) => state.GRNReducer);

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
      }}
    >
      <Typography
        sx={{ flex: "1 1 100%" }}
        // variant="h6"
        id="tableTitle"
        component="div"
      >
        <b>Vendor Name:</b> {GRNDetail?.vendor?.user?.name}
      </Typography>
      <Typography
        sx={{ flex: "1 1 100%" }}
        // variant="h6"
        id="tableTitle"
        component="div"
      >
        <b>PO#</b> {GRNDetail?.PO ? GRNDetail?.PO : "-"}
      </Typography>
      <Typography
        sx={{ flex: "1 1 100%" }}
        // variant="h6"
        id="tableTitle"
        component="div"
      >
        <b> Invoice#</b> {GRNDetail?.invoiceNumber}
      </Typography>
      <Typography
        sx={{ flex: "1 1 100%" }}
        // variant="h6"
        id="tableTitle"
        component="div"
      >
        GRN by
      </Typography>
      <Typography
        sx={{ flex: "1 1 100%" }}
        variant="h6"
        id="tableTitle"
        component="div"
      >
        <b>Vehicle# </b> {GRNDetail?.vehicleNo}
      </Typography>
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

export default function EnhancedTable() {
  const dispatch = useDispatch();
  const [cvcData, setCvcData] = useState([]);

  const { GRNDetail } = useSelector((state) => state.GRNReducer);

  React.useEffect(() => {
    if (GRNDetail?.grnProducts?.length) {
      const GRNCVC = GRNDetail?.grnProducts.map((item) => {
        return {
          ProductID: item?.product?.id,
          ProductName: item?.product?.name,
          Batch: item.batch,
          InvoiceQuantity: item.invoiceQuantity,
          RecdQuantity: item.recievedQuantity,
          MRP: item.printedMrp,

          TotalAmount: item.recievedQuantity * item.printedMrp,
          venderName: GRNDetail?.vendor?.user?.name
            ? GRNDetail?.vendor?.user?.name
            : "",
          Po: GRNDetail?.PO ? GRNDetail?.PO : "",
          Invoice: item?.invoiceQuantity ? item?.invoiceQuantity : "",
          GRN: "",
          Vehicle: "",
        };
      });
      setCvcData(GRNCVC);
    }
  }, [GRNDetail?.grnProducts]);

  const handleRequestSort = (event, property) => {
    const isAsc = sortField === property && order === "ASC";
    dispatch({
      type: Utils.ActionName.STORE,
      payload: { sortField: property, order: isAsc ? "DESC" : "ASC" },
    });
  };

  const { storeList, order, sortField } = useSelector(
    (state) => state.storeReducer
  );

  return (
    <>
      <Box sx={{ width: "100%" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: 10,
          }}
        >
          <Stack direction="row" spacing={2}>
            <Button
              variant="outlined"
              startIcon={<AddCircleOutlineOutlinedIcon />}
            >
              <CSVLink data={cvcData} headers={headers} filename={"warehouse-grn"}>
                Export
              </CSVLink>
            </Button>
          </Stack>
        </div>
        <Paper sx={{ width: "100%", mb: 2 }}>
          <EnhancedTableToolbar />
          <TableContainer>
            <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
              <EnhancedTableHead
                order={order}
                orderBy={sortField}
                onRequestSort={handleRequestSort}
                rowCount={storeList.length}
              />
              <TableBody>
                {GRNDetail?.grnProducts?.map((row, index) => {
                  return (
                    <TableRow hover key={row?.product?.id}>
                      <TableCell component="th" id={index} scope="row">
                        {row?.product?.id}
                      </TableCell>
                      <TableCell align="left">{row?.product?.name}</TableCell>
                      <TableCell align="left">{row?.batch}</TableCell>
                      <TableCell align="left">
                        {row?.invoiceQuantity ? row?.invoiceQuantity : "-"}
                      </TableCell>
                      <TableCell align="left">
                        {row?.printedMrp * row?.recievedQuantity}
                      </TableCell>
                      <TableCell align="left">{row?.printedMrp}</TableCell>
                      <TableCell align="left">
                        {row?.printedMrp * row?.recievedQuantity}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Box>
    </>
  );
}
