import * as React from "react";
import { useState } from "react";
import { makeStyles } from "@mui/styles";
import { CSVLink } from "react-csv";

import useDebounce from "../../../hooks/debounce";
import Utils from "../../../utility";
import MenuItem from "@mui/material/MenuItem";
import { getWarehouseList } from "../../warehouseRelated/warehouses/action";
import DeleteConfirmationPopup from "../../../component/modal/deleteModel";
import VisibilityIcon from "@mui/icons-material/Visibility";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import {
  getw2wList,
  updateStateW2w,
  getPdfw2w,
  getw2wListForExport,
} from "./action";
import EditIcon from "@mui/icons-material/Edit";
import PropTypes from "prop-types";
import { alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import CancelIcon from "@mui/icons-material/Cancel";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import { visuallyHidden } from "@mui/utils";
import { useDispatch, useSelector } from "react-redux";
import Stack from "@mui/material/Stack";
import DatePicker from "../../../component/date";
import Modal from "../../../component/modal";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import AddUser from "./addstoreStocks";
import Select from "../../../component/select";
import MakeTransfer from "./makeTransfer";
import { getProducts } from "../../productRelated/products/action";
import { getStoreList } from "../../storeRelated/store/action";
import moment from "moment";
const useStyles = makeStyles({
  viewStocks: {
    background: "#138496",
    color: "white",
    width: "55px !important",
    height: 35,
    borderRadius: 5,
    padding: 2,
    margin: 1,
  },
  doneStock: {
    background: "#218838",
    color: "white",
    width: "55px !important",
    height: 35,
    borderRadius: 5,
    padding: 2,
    margin: 1,
  },
  rejectStock: {
    background: "red",
    color: "white",
    width: "55px !important",
    // height: 35,
    borderRadius: 5,
    padding: 2,
    margin: 1,
  },
});
const headCells = [
  {
    id: "id",
    numeric: true,
    disablePadding: true,
    label: "Transfer",
  },
  {
    id: "warehouseNickname",
    numeric: false,
    disablePadding: true,
    label: "Group Transfer",
  },
  {
    id: "barcode",
    numeric: false,
    disablePadding: true,
    label: "Product Name",
  },
  {
    id: "quantity",
    numeric: false,
    disablePadding: true,
    label: "From",
  },
  {
    id: "bin",
    numeric: false,
    disablePadding: true,
    label: "To",
  },
  {
    id: "brand",
    numeric: false,
    disablePadding: true,
    label: "Quantity",
  },
  {
    id: "Category",
    numeric: false,
    disablePadding: true,
    label: "Status",
  },
  {
    id: "totalAmount",
    numeric: false,
    disablePadding: true,
    label: "Date Transfer",
  },
  {
    id: "expiry",
    numeric: false,
    disablePadding: true,
    label: "Received Transfer",
  },
  {
    id: "expiryInDay",
    numeric: false,
    disablePadding: true,
    label: "ID",
  },

  {
    id: "action",
    numeric: true,
    disablePadding: false,
    label: "Action",
  },
];

const headers = [
  { label: "Transfer Id", key: "transferId" },
  { label: "Group Transfer", key: "groupTransfer" },
  { label: "Product Name", key: "productName" },
  { label: "Barcode", key: "barcode" },
  { label: "From ", key: "from" },
  { label: "To", key: "to" },
  { label: "Quantity", key: "quantity" },
  { label: "Status", key: "status" },
  { label: "Date Transfer", key: "dateTransfer" },
  { label: "ID", key: "id" },
];
function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead
    // sx={{ background: "black" }}
    >
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            sortDirection={
              orderBy === headCell?.id ? order.toLowerCase() : false
            }
            // sx ={{ color:"white"}}
          >
            <TableSortLabel
              active={orderBy === headCell?.id}
              direction={orderBy === headCell?.id ? order.toLowerCase() : "asc"}
              onClick={createSortHandler(headCell?.id)}
            >
              {headCell.label}
              {orderBy === headCell?.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  //onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["ASC", "DESC"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = (props) => {
  const dispatch = useDispatch();
  const {
    numSelected,
    handleWarehouseSelect,
    storeId,
    warehouseList,
    handleStoreSelect,
    warehouseId,
  } = props;
  const { fromDate, toDate } = useSelector(
    (state) => state.warehouseToWarehouseReducer
  );
  const { permissions } = useSelector((state) => state.commonReducer);

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
      }}
    >
      <Typography
        sx={{ flex: "1 3 100%" }}
        variant="h6"
        id="tableTitle"
        component="div"
      >
        Warehouse to Warehouse
      </Typography>
      {!permissions ? (
        <>
          <Stack style={{ width: 200 }}>
            <DatePicker
              value={fromDate}
              name="fromDate"
              handleChange={(newVal) =>
                dispatch({
                  type: Utils.ActionName.WAREHOUSE_TO_WAREHOUSE,
                  payload: {
                    fromDate: newVal,
                  },
                })
              }
              label="From Date"
              // error={fromDate}
            />
          </Stack>
          <Stack direction="row" spacing={2} style={{ width: 200 }}>
            <DatePicker
              value={toDate}
              name="toDate"
              handleChange={(newVal) =>
                dispatch({
                  type: Utils.ActionName.WAREHOUSE_TO_WAREHOUSE,
                  payload: {
                    toDate: newVal,
                  },
                })
              }
              label="To Date"
              // error={errors.toDate}
            />
          </Stack>
        </>
      ) : (
        <></>
      )}
      <Stack sx={{ width: "300px", marginLeft: 1 }}>
        <Select
          handleChange={handleWarehouseSelect}
          value={warehouseId}
          label={"Select from Warehouse"}
          option={warehouseList?.map((item) => (
            <MenuItem key={item.id} value={item?.id}>
              {item?.warehouseNickname}
            </MenuItem>
          ))}
          name="manager"
        />
      </Stack>
      <Stack sx={{ width: "250px", marginLeft: 1 }}>
        <Select
          handleChange={handleStoreSelect}
          value={storeId}
          label={"Select Warehouse"}
          option={warehouseList?.map((item) => (
            <MenuItem key={item.id} value={item?.id}>
              {item?.warehouseNickname}
            </MenuItem>
          ))}
          name="manager"
        />
      </Stack>
    </Toolbar>
  );
};

export default function EnhancedTable() {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [cvcData, setCvcData] = useState([]);
  const [storeId, setStoreId] = React.useState(null);
  const [warehouseId, setWarehouseId] = React.useState(null);

  const [open, setOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(null);
  const [deleteConfirmation, setDeleteConfirmation] = useState(null);
  const [openMakeTransfer, setOpenMakeTransfer] = useState(null);
  const {
    warehouseToWarehouse,
    order,
    sortField,
    offset,
    take,
    search,
    count,
    warehouseToWarehouseForExport,
  } = useSelector((state) => state.warehouseToWarehouseReducer);
  const debouncedSearch = useDebounce(search, 500);

  React.useEffect(() => {
    dispatch(getw2wList(warehouseId, storeId));
  }, [debouncedSearch]);
  React.useEffect(() => {
    // dispatch(getWarehouseList());
    // if (!permissions || permissions["stockTransferW2W"]?.add)
    //   dispatch(getProducts());
    // dispatch(getStoreList());
    // dispatch(getw2wList());
  }, [dispatch]);

  const handleChangePage = (event, newPage) => {
    dispatch({
      type: Utils.ActionName.WAREHOUSE_TO_WAREHOUSE,
      payload: { offset: newPage + 1 },
    });
    dispatch(getw2wList(warehouseId, storeId));
  };

  const handleChangeRowsPerPage = (event) => {
    dispatch({
      type: Utils.ActionName.WAREHOUSE_TO_WAREHOUSE,
      payload: { take: event.target.value, offset: 1 },
    });
    dispatch(getw2wList(warehouseId, storeId));
  };

  // Avoid a layout jump when reaching the last page with empty rows.

  const handleSearch = (e) => {
    const searchedVal = e.target.value.trim();
    dispatch({
      type: Utils.ActionName.WAREHOUSE_TO_WAREHOUSE,
      payload: { search: searchedVal, take: take, offset: 1 },
    });
  };
  const handleWarehouseSelect = (e) => {
    setWarehouseId(e.target.value);
    dispatch(getw2wList(e.target.value, storeId));
    // dispatch(getStoreStockList());
  };
  const handleStoreSelect = (e) => {
    setStoreId(e.target.value);
    dispatch(getw2wList(warehouseId, e.target.value));
    // dispatch(getStoreStockList());
  };
  const { storeStockList } = useSelector((state) => state.storeStockReducer);
  const { warehouseStockList } = useSelector(
    (state) => state.warehouseStockReducer
  );
  // const { storeList } = useSelector((state) => state.storeReducer);
  // const { warehouseList } = useSelector((state) => state.warehouseReducer);
  const {
    storeListForDropdown,
    // productsDropDownList,
    warehouseListForDropdown,
  } = useSelector((state) => state.commonReducer);

  const { permissions } = useSelector((state) => state.commonReducer);
  const csvDownload = React.useRef(null);
  React.useEffect(() => {
    if (warehouseToWarehouseForExport?.length) {
      const GRNCVC = warehouseToWarehouseForExport.map((item) => {
        return {
          transferId: item?.w2wStocks?.transferId,
          groupTransfer: item?.groupTransferId,
          productName: item?.product?.name,
          barcode: item?.product?.barcode,
          from: item?.w2wStocks?.fromWarehouse?.warehouseNickname,
          to: item?.w2wStocks?.toWarehouse?.warehouseNickname,
          quantity: item?.quantity,
          status: item?.transferStatus,
          dateTransfer: item?.w2wStocks?.transferSendDate
            ? moment(item?.w2wStocks?.transferSendDate).format("DD/MM/YYYY")
            : "",
          id: item?.id,
        };
      });
      setCvcData(GRNCVC);
    }
  }, [warehouseToWarehouseForExport]);

  return (
    <>
      <Box sx={{ width: "100%" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: 10,
            alignItems: "center",
          }}
        >
          <Stack direction="row" space={2} sx={{ alignItems: "center" }}>
            <Stack direction="row" space={2}>
              {/* <Tooltip title="Please select store First"> */}
              {!permissions || permissions["stockTransferW2W"]?.add ? (
                <Button
                  variant="outlined"
                  startIcon={<AddCircleOutlineOutlinedIcon />}
                  onClick={() => setOpenMakeTransfer(true)}
                  // disabled={!storeId}
                  sx={{ py: 0.7 }}
                >
                  Make A Transfer
                </Button>
              ) : (
                <></>
              )}
              <Stack direction="row" space={2}>
                {/* <Tooltip title="Please select store First"> */}
                {!permissions ||permissions["stockTransferW2W"].report? (
                  <Button
                    variant="outlined"
                    startIcon={<AddCircleOutlineOutlinedIcon />}
                    // disabled
                    onClick={() =>
                      dispatch(
                        getw2wListForExport(warehouseId, storeId, csvDownload)
                      )
                    } // disabled={!storeId}
                    sx={{ py: 0.7, mx: 1 }}
                  >
                    STN report
                  </Button>
                ) : (
                  <></>
                )}
              </Stack>
            </Stack>
          </Stack>
          <TextField
            id="standard-search"
            label="Search field"
            searrch={search}
            type="search"
            variant="standard"
            onChange={handleSearch}
            InputProps={{
              endAdornment: (
                <InputAdornment>
                  <IconButton>
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </div>
        <Paper sx={{ mb: 2 }}>
          <EnhancedTableToolbar
            storeList={storeListForDropdown}
            storeId={storeId}
            warehouseList={warehouseListForDropdown}
            warehouseId={warehouseId}
            handleWarehouseSelect={handleWarehouseSelect}
            handleStoreSelect={handleStoreSelect}
          />
          <TableContainer>
            <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
              <EnhancedTableHead order={order} orderBy={sortField} />
              <TableBody>
                {[{ name: "Coffee" }].length &&
                  warehouseToWarehouse.length &&
                  warehouseToWarehouse.map((row, index) => {
                    return (
                      <TableRow hover key={row?.id}>
                        <TableCell component="th" id={index} scope="row">
                          {row?.w2wStocks?.transferId}
                        </TableCell>
                        <TableCell align="left">
                          {row?.groupTransferId}
                        </TableCell>
                        <TableCell align="left">{row?.product?.name}</TableCell>
                        <TableCell align="left">
                          {row?.w2wStocks?.fromWarehouse?.warehouseNickname}
                        </TableCell>
                        <TableCell align="left">
                          {row?.w2wStocks?.toWarehouse?.warehouseNickname}
                        </TableCell>{" "}
                        <TableCell align="left">{row?.quantity}</TableCell>
                        <TableCell align="left">
                          {row?.transferStatus}
                        </TableCell>
                        {/* <TableCell align="left">
                          {row?.barcode}
                        </TableCell> */}
                        <TableCell align="left">
                          {row?.w2wStocks?.transferSendDate}
                        </TableCell>
                        <TableCell align="left">
                          {row?.w2wStocks?.transferReceivedDate}
                        </TableCell>
                        <TableCell align="left">{row?.id}</TableCell>
                        <TableCell align="left">
                          <>
                            {row?.transferStatus == "pending" && (
                              <>
                                {!permissions ||
                                permissions["stockTransferW2W"]?.edit ? (
                                  <Tooltip title="Edit">
                                    <EditIcon
                                      style={{
                                        background: "#dc3545",
                                        color: "white",
                                        width: 55,
                                        // height: 35,
                                        borderRadius: 5,
                                      }}
                                      onClick={() => {
                                        setIsEdit(
                                          warehouseToWarehouse.filter(
                                            (item) => item.id === row.id
                                          )
                                        );
                                        setOpen(true);
                                      }}
                                    />
                                  </Tooltip>
                                ) : (
                                  <></>
                                )}
                                {!permissions ||
                                permissions["stockTransferW2W"]?.approve ? (
                                  <Tooltip
                                    title="Done"
                                    arrow
                                    placement="top"
                                    onClick={() =>
                                      dispatch(
                                        updateStateW2w(
                                          row?.id,
                                          "approved",
                                          () => {
                                            dispatch(
                                              getw2wList(warehouseId, storeId)
                                            );
                                          }
                                        )
                                      )
                                    }
                                  >
                                    <CheckCircleOutlineIcon
                                      className={classes.doneStock}
                                    />
                                  </Tooltip>
                                ) : (
                                  <></>
                                )}
                                {!permissions ||
                                permissions["stockTransferW2W"]?.reject ? (
                                  <Tooltip
                                    title="Done"
                                    arrow
                                    placement="top"
                                    onClick={() =>
                                      dispatch(
                                        updateStateW2w(
                                          row?.id,
                                          "rejected",
                                          () => {
                                            dispatch(
                                              getw2wList(warehouseId, storeId)
                                            );
                                          }
                                        )
                                      )
                                    }
                                  >
                                    <CancelIcon
                                      className={classes.rejectStock}
                                    />
                                  </Tooltip>
                                ) : (
                                  <></>
                                )}
                              </>
                            )}

                            <Tooltip
                              title="View"
                              arrow
                              onClick={() =>
                                dispatch(getPdfw2w(row?.w2wStocks?.id))
                              }
                            >
                              <VisibilityIcon className={classes.viewStocks} />
                            </Tooltip>
                          </>
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25]}
            component="div"
            count={count}
            rowsPerPage={take}
            page={offset - 1}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </Box>
      <Modal
        header="Make A Transfer"
        children={
          <AddUser
            setOpen={setOpen}
            isEdit={isEdit}
            setIsEdit={setIsEdit}
            storeId={storeId}
          />
        }
        handleClose={() => {
          setOpen(false);
          setIsEdit(null);
        }}
        open={open}
      />
      <Modal
        header="Stock Transfer"
        children={
          <MakeTransfer
            setOpen={setOpenMakeTransfer}
            // isEdit={OpenMakeTransfer}
            // setIsEdit={setIsEdit}
            // storeId={storeId}
          />
        }
        handleClose={() => {
          setOpenMakeTransfer(null);
          // setIsEdit(null);
        }}
        open={openMakeTransfer}
      />
      <DeleteConfirmationPopup
        title="Store Stock"
        handleClose={() => setDeleteConfirmation(null)}
        okButtonClick={() => {
          // dispatch(deleteStoreStock(deleteConfirmation));
          setDeleteConfirmation(null);
        }}
        open={deleteConfirmation}
        minWidth={500}
      />
      <CSVLink
        data={cvcData}
        headers={headers}
        filename={"order"}
        ref={csvDownload}
        target="_blank"
      />
    </>
  );
}
