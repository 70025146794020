import Utils from "../../../utility";
export const getBrandOwnerList = (takes) => {
  return (dispatch, getState) => {
    dispatch({
      type: Utils.ActionName.LOADING,
      payload: { loading: true },
    });
    const { order, sortField, offset, take, search } =
      getState().brandOwnerReducer;

    Utils.api.getApiCall(
      Utils.endPoints.brandOwner,
      `?order=${order}&sortField=${sortField}&offset=${offset}&take=${
        takes ? takes : take
      }&search=${search}`,
      (respData) => {
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });
        dispatch({
          type: Utils.ActionName.BRAND_OWNER,
          payload: {
            brandOwnerList: respData.data?.brandManager,
            count: respData.data.count,
          },
        });
        // navigate("/dashboard");
      },
      (error) => {
        let { data } = error;
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });
        Utils.showAlert(
          2,
          `${Utils.endPoints.brandOwner} GET Method error ${
            JSON.stringify(data?.message) || data?.error
          }`
        );
        // Utils.showAlert(2, data?.message?.join(",") || data?.error);
        // setSubmitting(true);
      }
    );
  };
};

export const getBrandOwnerListForDropdown = (takes) => {
  return (dispatch, getState) => {
    dispatch({
      type: Utils.ActionName.LOADING,
      payload: { loading: true },
    });
    const { order, sortField, offset, take, search } =
      getState().brandOwnerReducer;

    Utils.api.getApiCall(
      Utils.endPoints.brandOwner,
      `?order=${order}${
        sortField ? "&sortField=" + sortField : ""
      }&offset=${offset}&search=${search}`,
      (respData) => {
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });
        dispatch({
          type: Utils.ActionName.COMMON,
          payload: {
            brandOwnerListForDropdown: respData.data?.brandManager,
          },
        });
        // navigate("/dashboard");
      },
      (error) => {
        let { data } = error;
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });
        Utils.showAlert(
          2,
          `${Utils.endPoints.brandOwner} GET Method error ${
            JSON.stringify(data?.message) || data?.error
          }`
        );
        // Utils.showAlert(2, data?.message?.join(",") || data?.error);
        // setSubmitting(true);
      }
    );
  };
};
export const addBrandOwner = (values, setOpen) => {
  return (dispatch) => {
    dispatch({
      type: Utils.ActionName.LOADING,
      payload: { loading: true },
    });
    const dataToSend = values;
    // dataToSend.isDeleted = false;

    Utils.api.postApiCall(
      Utils.endPoints.createBrandManager,
      dataToSend,
      (respData) => {
        setOpen(false);
        Utils.showAlert(1, "Brand Owner add successfully");
        dispatch(getBrandOwnerList());
        dispatch(getBrandOwnerListForDropdown());
      },
      (error) => {
        let { data } = error;
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });
        Utils.showAlert(
          2,
          `${Utils.endPoints.createBrandManager} POST Method error ${
            JSON.stringify(data?.message) || data?.error
          }`
        );
        // Utils.showAlert(2, data?.message?.join(",") || data?.error);
      }
    );
  };
};

export const updateBrandOwner = (values, setOpen, id, resetForm, setIsEdit) => {
  return (dispatch) => {
    dispatch({
      type: Utils.ActionName.LOADING,
      payload: { loading: true },
    });
    const dataToSend = {
      // allowedBrands: values.allowedBrands,
      // bannedBrands: values.bannedBrands,
      // bankName: values.bankName,
      // bankBranch: values.bankBranch,
      // bankIFSC: values.bankIFSC,
      // bankAccountNumber: values.bankAccountNumber,
      // licenseType: values.licenseType,
      // licenseNumber: values.licenseNumber,
      GST: values.GST,
      address: values?.address,
      alias: values?.alias,
      // isManufacturer: values.isManufacturer,
    };

    Utils.api.putApiCall(
      Utils.endPoints.brandOwner + "/" + id,
      dataToSend,
      (respData) => {
        setOpen(false);
        Utils.showAlert(1, "Brand Owner updated successfully");
        dispatch(getBrandOwnerList());
        dispatch(getBrandOwnerListForDropdown());
        resetForm({});
        setIsEdit(null);
      },
      (error) => {
        let { data } = error;
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });
        // Utils.showAlert(2, data?.message?.join(",") || data?.error);
        Utils.showAlert(
          2,
          `update ${Utils.endPoints.brandOwner} PUT Method error ${
            JSON.stringify(data?.message) || data?.error
          }`
        );
        // setSubmitting(true);
      }
    );
  };
};

export const deleteBrandOwner = (id) => {
  return (dispatch) => {
    dispatch({
      type: Utils.ActionName.LOADING,
      payload: { loading: true },
    });
    Utils.api.deleteApiCall(
      Utils.endPoints.brandOwner + `/${id}`,
      "",
      (respData) => {
        Utils.showAlert(1, "Brand Owner delete sucesfuly");

        dispatch(getBrandOwnerList());
        dispatch(getBrandOwnerListForDropdown());
      },
      (error) => {
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });
        let { data } = error;
        Utils.showAlert(
          2,
          `delete ${Utils.endPoints.brandOwner} DELETE Method error ${
            JSON.stringify(data?.message) || data?.error
          }`
        );
        // Utils.showAlert(2, data?.message?.join(",") || data?.error);
        // setSubmitting(true);
      }
    );
  };
};
