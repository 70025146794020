import moment from "moment";
import Utils from "../../../utility";

const initialState = {
  storeToStore: [],
  order: "DESC",
  // sortField: "warehouseNickname",
  offset: 1,
  take: 10,
  search: "",
  count: 0,
  fromStore: null,
  toStore: null,
  storeToStoreForExport: [],
  fromDate: moment(),
  toDate: moment(),
};
export const storeToStoreReducer = (state = initialState, action) => {
  switch (action.type) {
    case `${Utils.ActionName.STORE_TO_STORE}`:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return { ...state };
  }
};
