import * as React from "react";
import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";
import Typography from "@mui/material/Typography";

export default function TableSkeleton() {
  return (
    <Box sx={{ width: "100%" }}>
      {/* <Skeleton /> */}

      <Box>
        <Typography component="div" variant="h1">
          <Skeleton />
        </Typography>
      </Box>
      <Box sx={{ display: "flex" }}>
        <Box sx={{ flex: 1, mr: 2 }}>
          <Typography component="div" variant="h3">
            <Skeleton />
          </Typography>
          <Typography component="div" variant="h3">
            <Skeleton />
          </Typography>
          <Typography component="div" variant="h3">
            <Skeleton />
          </Typography>
          <Typography component="div" variant="h3">
            <Skeleton />
          </Typography>
          <Typography component="div" variant="h3">
            <Skeleton />
          </Typography>
          <Typography component="div" variant="h3">
            <Skeleton />
          </Typography>
          <Typography component="div" variant="h3">
            <Skeleton />
          </Typography>
          <Typography component="div" variant="h3">
            <Skeleton />
          </Typography>
          <Typography component="div" variant="h3">
            <Skeleton />
          </Typography>
        </Box>
        <Box sx={{ width: "100%", flex: 3 }}>
          {/* <Skeleton /> */}
          {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10,1,12,13,14].map((item) => (
            <Skeleton key={item} height={40} animation="wave" />
          ))}
        </Box>
      </Box>
    </Box>
  );
}
