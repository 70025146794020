import Utils from "../../../utility";
export const getRtvStoreStockList = (id, discard = false) => {
  return (dispatch, getState) => {
    const { order, sortField, offset, take, search } =
      getState().rtvStoreStockReducer;

    dispatch({
      type: Utils.ActionName.LOADING,
      payload: { loading: true },
    });
    Utils.api.getApiCall(
      Utils.endPoints.rtvStoreStock,
      `?order=${order}&offset=${offset}&take=${take}&search=${search}&discard=${Boolean(
        discard
      )}${`&store=${id ? id : ""}`}`,
      (respData) => {
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });
        dispatch({
          type: Utils.ActionName.RTV_STORE_STOCK,
          payload: {
            rtvStoreStock: respData.data?.rtvProducts,
            count: respData.data.count,
          },
        });
      },
      (error) => {
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });
        let { data } = error;
        Utils.showAlert(
          2,
          `${Utils.endPoints.rtvStoreStock} GET Method error ${
            JSON.stringify(data?.message) || data?.error
          }`
        );
        // Utils.showAlert(2, data?.message?.join(",") || data?.error);
        // setSubmitting(true);
      }
    );
  };
};

export const makeRtv = (values, setOpen) => {
  return (dispatch) => {
    dispatch({
      type: Utils.ActionName.LOADING,
      payload: { loading: true },
    });

    let dataToSend = {};
    if (values.store)
      dataToSend = {
        product: values.stocks.map((item) => {
          return {
            product: item.product,
            quantity: item.quantity,
          };
        }),
        store: values.store,
      };
    else
      dataToSend = {
        product: values.stocks.map((item) => {
          return {
            product: item.product,
            quantity: item.quantity,
          };
        }),
        warehouse: values.warehouse,
      };

    Utils.api.postApiCall(
      Utils.endPoints.createRtv,
      dataToSend,
      (respData) => {
        setOpen(false);
        getRtvStoreStockList();
        Utils.showAlert(1, "successfully");
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });
        // dispatch(gets2sList(storeId));
        // resetForm({});
        // setIsEdit(null)
        // dispatch({type:Utils.ActionName.USER_LIST
        //   ,payload:{userlist:respData.data}})
        // navigate("/dashboard");
      },
      (error) => {
        let { data } = error;
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });
        // Utils.showAlert(2, data?.message?.join(",") || data?.error);

        Utils.showAlert(
          2,
          `${Utils.endPoints.createRtv} POST Method error ${
            JSON.stringify(data?.message) || data?.error
          }`
        );
        // setSubmitting(true);
      }
    );
  };
};
export const verifyRtv = (id, storeId) => {
  return (dispatch, getState) => {
    dispatch({
      type: Utils.ActionName.LOADING,
      payload: { loading: true },
    });
    Utils.api.putApiCall(
      Utils.endPoints.verifyRtv + id,
      {
        status: "approved",
      },
      (respData) => {
        dispatch(getRtvStoreStockList(storeId));
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });
      },
      (error) => {
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });
        let { data } = error;
        Utils.showAlert(
          2,
          `${Utils.endPoints.verifyRtv} PUT Method error ${
            JSON.stringify(data?.message) || data?.error
          }`
        );
        // Utils.showAlert(2, data?.message?.join(",") || data?.error);
        // setSubmitting(true);
      }
    );
  };
};

export const discardInvoice = (id) => {
  return (dispatch) => {
    dispatch({
      type: Utils.ActionName.LOADING,
      payload: { loading: true },
    });
    Utils.api.getApiCall(
      Utils.endPoints.rtvStoreStockPdf + id,
      "",
      (respData) => {
        var winparams =
          "dependent=yes,locationbar=no,scrollbars=yes,menubar=yes," +
          "resizable,screenX=50,screenY=50,width=850,height=1050";
        var htmlPop =
          "<embed width=100% height=100%" +
          ' type="application/pdf"' +
          ' src="data:application/pdf;base64,' +
          escape(respData?.data) +
          '"></embed>';
        var printWindow = window.open("", "PDF", winparams);
        //@ts-ignore
        printWindow.document.write(htmlPop);
        //@ts-ignore
        // printWindow.print()
        // const linkSource = `data:application/pdf;base64,${respData.data}`;
        // const downloadLink = document.createElement("a");
        // const fileName = "abc.pdf";
        // downloadLink.href = linkSource;
        // downloadLink.download = fileName;
        // downloadLink.click();
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });
      },
      (error) => {
        let { data } = error;
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });
        Utils.showAlert(
          2,
          `${Utils.endPoints.rtvStoreStockPdf} GET Method error ${
            JSON.stringify(data?.message) || data?.error
          }`
        );
        // Utils.showAlert(2, data?.error || data?.message);
      }
    );
  };
};
// export const getGRNById = (id) => {
//   return (dispatch, getState) => {
//     // const { order, sortField, offset, take, search } = getState().storeReducer;
//     dispatch({
//       type: Utils.ActionName.LOADING,
//       payload: { loading: true },
//     });
//     Utils.api.getApiCall(
//       Utils.endPoints.GRN + id,
//       "",
//       // `?order=${order}&sortField=${sortField}&offset=${offset}&take=${take}&search=${search}`,
//       (respData) => {
//         dispatch({
//           type: Utils.ActionName.LOADING,
//           payload: { loading: false },
//         });
//         dispatch({
//           type: Utils.ActionName.GRN,
//           payload: {
//             GRNDetail: respData.data,
//           },
//         });
//       },
//       (error) => {
//         dispatch({
//           type: Utils.ActionName.LOADING,
//           payload: { loading: false },
//         });
//         let { data } = error;
//         Utils.showAlert(2, data?.message?.join(",") || data?.error);
//         // setSubmitting(true);
//       }
//     );
//   };
// };
// export const updateStore = (values, setOpen, id, resetForm, setIsEdit) => {
//   return (dispatch) => {
//     dispatch({
//       type: Utils.ActionName.LOADING,
//       payload: { loading: true },
//     });
//     dispatch({
//       type: Utils.ActionName.LOADING,
//       payload: { loading: true },
//     });
//     const dataToSend = values;
//     dataToSend.isDeleted = false;
//     dataToSend.runner = +values.runner;
//     dataToSend.propertyOwner = +values.propertyOwner;
//     dataToSend.pincode = +values.pincode;

//     // dataToSend.FSSAI=values.FSSAI

//     Utils.api.putApiCall(
//       Utils.endPoints.store + id,
//       dataToSend,
//       (respData) => {
//         setOpen(false);
//         Utils.showAlert(1, "Store updated successfully");
//         // dispatch(getAllUser());
//         resetForm({});
//         setIsEdit(null);
//         // dispatch(getStoreList());
//         // dispatch({type:Utils.ActionName.USER_LIST
//         //   ,payload:{userlist:respData.data}})
//         // navigate("/dashboard");
//       },
//       (error) => {
//         let { data } = error;
//         dispatch({
//           type: Utils.ActionName.LOADING,
//           payload: { loading: false },
//         });
//         Utils.showAlert(2, data?.message?.join(",") || data?.error);

//         // setSubmitting(true);
//       }
//     );
//   };
// };
// export const createStores = (values, setOpen) => {
//   return (dispatch) => {
//     dispatch({
//       type: Utils.ActionName.LOADING,
//       payload: { loading: true },
//     });
//     const dataToSend = values;
//     dataToSend.isDeleted = false;
//     dataToSend.runner = +values.runner;
//     dataToSend.propertyOwner = +values.propertyOwner;
//     dataToSend.FSSAI = +values.FSSAI;
//     Utils.api.postApiCall(
//       Utils.endPoints.createStore,
//       dataToSend,
//       (respData) => {
//         setOpen(false);
//         Utils.showAlert(1, "Store updated successfully");
//         // dispatch(getStoreList());
//         // resetForm({});
//         // setIsEdit(null)
//         // dispatch({type:Utils.ActionName.USER_LIST
//         //   ,payload:{userlist:respData.data}})
//         // navigate("/dashboard");
//       },
//       (error) => {
//         let { data } = error;
//         dispatch({
//           type: Utils.ActionName.LOADING,
//           payload: { loading: false },
//         });
//         Utils.showAlert(2, data?.message?.join(",") || data?.error);

//         // setSubmitting(true);
//       }
//     );
//   };
// };

// export const deleteStore = (id) => {
//   return (dispatch) => {
//     dispatch({
//       type: Utils.ActionName.LOADING,
//       payload: { loading: true },
//     });
//     Utils.api.deleteApiCall(
//       Utils.endPoints.store + `${id}`,
//       "",
//       (respData) => {
//         Utils.showAlert(1, "Store delete sucesfuly");
//         // dispatch(getStoreList());
//         // dispatch({type:Utils.ActionName.USER_LIST
//         //   ,payload:{userlist:respData.data}})
//         // navigate("/dashboard");
//       },
//       (error) => {
//         let { data } = error;
//         dispatch({
//           type: Utils.ActionName.LOADING,
//           payload: { loading: false },
//         });
//         Utils.showAlert(2, data?.message?.join(",") || data?.error);
//         // setSubmitting(true);
//       }
//     );
//   };
// };

// export const getBrandList = () => {
//   return (dispatch) => {
//     // const { email, password } = values;
//     // const dataToSend = {
//     //   username:email,
//     //   password,
//     // };
//     Utils.api.getApiCall(
//       Utils.endPoints.brands,
//       "",
//       (respData) => {
//         dispatch({
//           type: Utils.ActionName.USER_LIST,
//           payload: { brands: respData.data },
//         });
//         // navigate("/dashboard");
//       },
//       (error) => {
//         let { data } = error;
//         Utils.showAlert(2, data?.message?.join(",") || data?.error);
//         // setSubmitting(true);
//       }
//     );
//   };
// };

// export const getPropertyOwner = () => {
//   return (dispatch) => {
//     // const { email, password } = values;
//     // const dataToSend = {
//     //   username:email,
//     //   password,
//     // };
//     Utils.api.getApiCall(
//       Utils.endPoints.propertyOwner,
//       "",
//       (respData) => {
//         dispatch({
//           type: Utils.ActionName.PROPERTY_OWNER,
//           payload: { propertyOwnerList: respData.data },
//         });
//         // navigate("/dashboard");
//       },
//       (error) => {
//         let { data } = error;
//         Utils.showAlert(2, data?.message?.join(",") || data?.error);
//         // setSubmitting(true);
//       }
//     );
//   };
// };

// export const getBiller = (takes) => {
//   return (dispatch, getState) => {
//     const { order, sortField, offset, take, search } = getState().storeReducer;

//     dispatch({
//       type: Utils.ActionName.LOADING,
//       payload: { loading: true },
//     });
//     Utils.api.getApiCall(
//       Utils.endPoints.biller,
//       "",
//       // `?order=${order}&sortField=${sortField}&offset=${offset}&take=${takes?takes:take}&search=${search}`,
//       (respData) => {
//         dispatch({
//           type: Utils.ActionName.LOADING,
//           payload: { loading: false },
//         });
//         dispatch({
//           type: Utils.ActionName.STORE,
//           payload: {
//             billerList: respData.data,
//           },
//         });
//       },
//       (error) => {
//         dispatch({
//           type: Utils.ActionName.LOADING,
//           payload: { loading: false },
//         });
//         let { data } = error;
//         Utils.showAlert(2, data?.message?.join(",") || data?.error);
//         // setSubmitting(true);
//       }
//     );
//   };
// };

// export const getOprationManagerList = (takes) => {
//   return (dispatch, getState) => {
//     const { order, sortField, offset, take, search } = getState().storeReducer;

//     dispatch({
//       type: Utils.ActionName.LOADING,
//       payload: { loading: true },
//     });
//     Utils.api.getApiCall(
//       Utils.endPoints.oprationManager,
//       "",
//       // `?order=${order}&sortField=${sortField}&offset=${offset}&take=${takes?takes:take}&search=${search}`,
//       (respData) => {
//         dispatch({
//           type: Utils.ActionName.LOADING,
//           payload: { loading: false },
//         });
//         dispatch({
//           type: Utils.ActionName.STORE,
//           payload: {
//             oprationManagerList: respData.data,
//           },
//         });
//       },
//       (error) => {
//         dispatch({
//           type: Utils.ActionName.LOADING,
//           payload: { loading: false },
//         });
//         let { data } = error;
//         Utils.showAlert(2, data?.message?.join(",") || data?.error);
//         // setSubmitting(true);
//       }
//     );
//   };
// };
