import Utils from "../../../utility";
import moment from "moment";

export const updateCostAndSellingPrice = (values) => {
  return (dispatch) => {
    const { costPrice, mrp, sellingPrice, productId } = values;
    dispatch({
      type: Utils.ActionName.LOADING,
      payload: { loading: true },
    });

    Utils.api.putApiCall(
      Utils.endPoints.updateSellingAndCostPrice +
        `?productId=${productId}&sellingPrice=${sellingPrice}&costPrice=${costPrice}&mrp=${mrp}`,
      {},
      (respData) => {
        Utils.showAlert(1, "Successfully updated");
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });

        // navigate("/dashboard");
      },
      (error) => {
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });
        let { data } = error;
        Utils.showAlert(
          2,
          `${Utils.endPoints.updateSellingAndCostPrice} PUT Method error ${
            JSON.stringify(data?.message) || data?.error
          }`
        );
        // Utils.showAlert(2, data?.message?.join(",") || data?.error);
        // setSubmitting(true);
      }
    );
  };
};

// export const deleteStoreStock = (id, storeId) => {
//   return (dispatch) => {
//     dispatch({
//       type: Utils.ActionName.LOADING,
//       payload: { loading: true },
//     });
//     Utils.api.deleteApiCall(
//       Utils.endPoints.storeStock + `/${id}`,
//       "",
//       (respData) => {
//         Utils.showAlert(1, "Warehouse stock delete sucesfuly");
//         dispatch(getPOkList(storeId));
//         // dispatch({type:Utils.ActionName.USER_LIST
//         //   ,payload:{userlist:respData.data}})
//         // navigate("/dashboard");
//       },
//       (error) => {
//         let { data } = error;
//         dispatch({
//           type: Utils.ActionName.LOADING,
//           payload: { loading: false },
//         });
//         Utils.showAlert(2, data?.message?.join(",") || data?.error);
//         // setSubmitting(true);
//       }
//     );
//   };
// };

// export const getBrandList = () => {
//   return (dispatch) => {
//     // const { email, password } = values;
//     // const dataToSend = {
//     //   username:email,
//     //   password,
//     // };
//     Utils.api.getApiCall(
//       Utils.endPoints.brands,
//       "",
//       (respData) => {
//         dispatch({
//           type: Utils.ActionName.USER_LIST,
//           payload: { brands: respData.data },
//         });
//         // navigate("/dashboard");
//       },
//       (error) => {
//         let { data } = error;
//         Utils.showAlert(2, data?.message?.join(",") || data?.error);
//         // setSubmitting(true);
//       }
//     );
//   };
// };

// export const getPropertyOwner = () => {
//   return (dispatch) => {
//     // const { email, password } = values;
//     // const dataToSend = {
//     //   username:email,
//     //   password,
//     // };
//     Utils.api.getApiCall(
//       Utils.endPoints.propertyOwner,
//       "",
//       (respData) => {
//         dispatch({
//           type: Utils.ActionName.PROPERTY_OWNER,
//           payload: { propertyOwnerList: respData.data },
//         });
//         // navigate("/dashboard");
//       },
//       (error) => {
//         let { data } = error;
//         Utils.showAlert(2, data?.message?.join(",") || data?.error);
//         // setSubmitting(true);
//       }
//     );
//   };
// };
