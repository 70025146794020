import React from "react";
import Button from "@mui/material/Button";
import Schema from "../../../schema";
import AutocompleteMultiple from "../../../component/input/multipleAutocomplete";
import { useDispatch, useSelector } from "react-redux";
import { Formik, Form } from "formik";
import { updateWarehouse, createWarehouse } from "./action";
import Input from "../../../component/input";
import { Box } from "@mui/material";
const AddUser = ({ setOpen, isEdit, setIsEdit }) => {
  const dispatch = useDispatch();

  const { WM, OM } = useSelector((state) => state.commonReducer);

  const initialValues = {
    manager: isEdit?.length
      ? isEdit[0]?.manager?.map((item) => `${item?.manager?.id}`)
      : [],
    operator: isEdit?.length
      ? isEdit[0]?.operator?.map((item) => `${item?.operator?.id}`)
      : [],
    warehouseAddress: isEdit?.length ? isEdit[0]?.warehouseAddress : "",
    warehouseState: isEdit?.length ? isEdit[0]?.warehouseState : "",
    warehousePincode: isEdit?.length ? isEdit[0]?.warehousePincode : "",
    warehouseGSTIN: isEdit?.length ? isEdit[0]?.warehouseGSTIN : "",
    warehouseNickname: isEdit?.length ? isEdit[0]?.warehouseNickname : "",
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={Schema.warehouseSchema}
      onSubmit={(values, { setSubmitting, resetForm }) => {
        if (isEdit?.length) {
          dispatch(
            updateWarehouse(values, setOpen, isEdit[0].id, resetForm, setIsEdit)
          );
        } else {
          dispatch(createWarehouse(values, setOpen));
        }
      }}
    >
      {({
        values,
        errors,
        touched,
        setFieldValue,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        /* and other goodies */
      }) => (
        <Form onSubmit={handleSubmit}>
          <Box sx={{ display: "flex", mb: 2 }}>
            <AutocompleteMultiple
              values={values.manager}
              onInputChange={(event) => {
                if (event !== null) {
                }
              }}
              onChange={(event, value) => {
                if (value !== null) {
                  const index = WM.findIndex((x) => {
                    return value.map((item) => item.id === x.id);
                  });
                  // handleChange(event);
                  if (index >= 0) {
                    setFieldValue(
                      "manager",
                      value.map((item) => `${item.id}`)
                    );
                  }
                }
              }}
              helperText={touched.manager && errors.manager}
              error={Boolean(touched.manager && errors.manager)}
              name={"manager"}
              label={"Warehouse manager"}
              touched={touched.manager}
              options={WM}
              getOptionLabel={(option) => option.userName}
              getOptionSelected={(option, value) => {
                return `${option.id}` === `${value.id}`;
              }}
              defaultValue={
                values?.manager?.length > 0
                  ? () => {
                      const items = [];
                      WM.forEach((x) => {
                        if (
                          values.manager &&
                          values.manager.includes(`${x.id}`)
                        ) {
                          return items.push(x);
                        }
                      });
                      return items;
                    }
                  : undefined
              }
            />
            <AutocompleteMultiple
              values={values.operator}
              onInputChange={(event) => {
                if (event !== null) {
                }
              }}
              onChange={(event, value) => {
                if (value !== null) {
                  const index = OM.findIndex((x) =>
                    // x.id === value[0].id
                    {
                      return value.map((item) => item.id === x.id);
                    }
                  );
                  // handleChange(event);
                  if (index >= 0) {
                    setFieldValue(
                      "operator",
                      value.map((item) => `${item.id}`)
                    );
                  }
                }
              }}
              helperText={touched.operator && errors.operator}
              error={Boolean(touched.operator && errors.operator)}
              name={"operator"}
              label={"Opration Manager"}
              touched={touched.operator}
              options={OM}
              getOptionLabel={(option) => option.userName}
              getOptionSelected={(option, value) => {
                return `${option.id}` === `${value.id}`;
              }}
              defaultValue={
                values?.operator?.length > 0
                  ? () => {
                      const items = [];
                      OM.forEach((x) => {
                        if (
                          values.operator &&
                          values.operator.includes(`${x.id}`)
                        ) {
                          return items.push(x);
                        }
                      });
                      return items;
                    }
                  : undefined
              }
            />
          </Box>
          <Box sx={{ display: "flex", mb: 2 }}>
            <Input
              value={values.warehouseAddress}
              name="warehouseAddress"
              type="text"
              error={errors.warehouseAddress}
              label="warehouse Address"
              onChange={handleChange}
              handleBlur={handleBlur}
            />

            <Input
              value={values.warehouseState}
              name="warehouseState"
              type="text"
              error={errors.warehouseState}
              label="warehouse State"
              onChange={handleChange}
              handleBlur={handleBlur}
            />
          </Box>

          <Box sx={{ display: "flex", mb: 2 }}>
            <Input
              value={values.warehousePincode}
              name="warehousePincode"
              type="number"
              error={errors.warehousePincode}
              label="Warehouse Pincode"
              onChange={handleChange}
              handleBlur={handleBlur}
            />
            <Input
              value={values.warehouseGSTIN}
              name="warehouseGSTIN"
              type="text"
              error={errors.warehouseGSTIN}
              label="GSTIN"
              onChange={handleChange}
              handleBlur={handleBlur}
            />
          </Box>
          <Box sx={{ display: "flex", mb: 2 }}>
            <Input
              value={values.warehouseNickname}
              name="warehouseNickname"
              type="text"
              error={errors.warehouseNickname}
              label="Warehouse Nickname"
              onChange={handleChange}
              handleBlur={handleBlur}
            />
          </Box>

          <Button variant="outlined" type="submit">
            {isEdit?.length ? "update Warehouse" : "Add Warehouse"}
          </Button>
        </Form>
      )}
    </Formik>
    //  </Authlayout>
  );
};

export default AddUser;
