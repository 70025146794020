import moment from "moment";
import Utils from "../../../utility";
import axios from "axios";

export const getStoreLedger = (value, setConfirmation) => {
  return (dispatch, getState) => {
    const { storeId, toDate, fromDate, email } = value;
    dispatch({
      type: Utils.ActionName.LOADING,
      payload: { loading: true },
    });
    // axios
    //   .post("http://3.110.158.0/ledger/", {
    //     storeId,
    //     fromDate: moment(fromDate).format("YYYY-MM-DD"),
    //     toDate: moment(toDate).format("YYYY-MM-DD"),
    //     email,
    //   })
    //   .then((res) => {
    //     console.log(res);
    //   })
    //   .catch((e) => {
    //     console.log(e);
    //   });
    Utils.api.postApiCall(
      "https://analytics.thenewshop.in/ledger",
      {
        storeId,
        fromDate: moment(fromDate).format("YYYY-MM-DD") + " 00:00:00",
        toDate: moment(toDate).format("YYYY-MM-DD") + " 23:59:59",
        email,
      },
      // `?order=${order}&sortField=${sortField}&offset=${offset}&take=${takes?takes:take}&search=${search}`,
      (respData) => {
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });
        setConfirmation(true);
        dispatch({
          type: Utils.ActionName.STORE,
          payload: {
            runnerList: respData.data?.users,
          },
        });
      },
      (error) => {
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });
        let { data } = error;
        
        Utils.showAlert(
          2,
          `ledger POST Method error ${
            JSON.stringify(data?.message) || data?.error
          }`
        );
        // Utils.showAlert(2, data?.message?.join(",") || data?.error);
        // setSubmitting(true);
      }
    );
  };
};
