import  React from "react";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

export default function MediaCard({
  position,
  bannerType,
  photoURL,
  handleUpdate,
  handleDelete,
  brand,
  category,
}) {
  return (
    <Card sx={{ minWidth: "28%", maxWidth: 345, m: 2 }}>
      <CardMedia
        component="img"
        height="140"
        image={photoURL}
        alt="Bnner Image"
      />
      <CardContent>
        <Typography gutterBottom variant="h6" component="div">
          Position:{position}
        </Typography>
        <Typography variant="h6">
          Banner Type:{brand?.id ? "Brand" : "category"}
        </Typography>
      </CardContent>
      <CardActions>
        <Button size="small" onClick={handleDelete}>
          Delete
        </Button>
        <Button size="small" onClick={handleUpdate}>
          Update{" "}
        </Button>
      </CardActions>
    </Card>
  );
}
