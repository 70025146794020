import * as React from "react";
import { useState } from "react";
import Utils from "../../../utility";
import { getWarehouseList } from "../../warehouseRelated/warehouses/action";
import moment from "moment";
import { Formik, Form } from "formik";
import DatePicker from "../../../component/date";
import AutoComplete from "../../../component/input/autocomplete";

import { getPOById, getPOkList } from "./action";
import PropTypes from "prop-types";
import { alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import FilterListIcon from "@mui/icons-material/FilterList";
import { visuallyHidden } from "@mui/utils";
import { useDispatch, useSelector } from "react-redux";
import Stack from "@mui/material/Stack";
import Modal from "../../../component/modal";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
// import Modal from "../../component/modal"
import AddUser from "./addPo";
import { getProducts } from "../../productRelated/products/action";
import useDebounce from "../../../hooks/debounce";

import { getBrandOwnerList } from "../../userAccount/brandOwner.js/action";
import VisibilityIcon from "@mui/icons-material/Visibility";
import PODetail from "./detailPO";

const headCells = [
  {
    id: "id",
    numeric: true,
    disablePadding: true,
    label: "PO Id",
  },
  {
    id: "warehouseNickname",
    numeric: false,
    disablePadding: true,
    label: "GRN Id",
  },

  {
    id: "quantity",
    numeric: false,
    disablePadding: true,
    label: "Date",
  },
  {
    id: "brand",
    numeric: false,
    disablePadding: true,
    label: "Vender Id",
  },
  {
    id: "name",
    numeric: false,
    disablePadding: true,
    label: "Vendor Name",
    sorting: true,
  },
  {
    id: "totalAmount",
    numeric: false,
    disablePadding: true,
    label: "Made By",
  },
  {
    id: "storename",
    numeric: false,
    disablePadding: true,
    label: "Warehouse ID",
  },
  {
    id: "storename",
    numeric: false,
    disablePadding: true,
    label: "Warehouse Name",
    sorting: true,
  },
  {
    id: "type",
    numeric: false,
    disablePadding: true,
    label: "Type",
    sorting: true,
  },
  {
    id: "quantity",
    numeric: false,
    disablePadding: true,
    label: "Status",
  },
  {
    id: "quantity",
    numeric: false,
    disablePadding: true,
    label: "Total Value",
  },
  {
    id: "action",
    numeric: true,
    disablePadding: false,
    label: "Action",
  },
];

function EnhancedTableHead(props) {
  const {
    order,
    orderBy,

    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            sortDirection={
              orderBy === headCell?.id ? order.toLowerCase() : false
            }
          >
            {headCell?.sorting ? (
              <TableSortLabel
                active={orderBy === headCell?.id}
                direction={
                  orderBy === headCell?.id ? order.toLowerCase() : "asc"
                }
                onClick={createSortHandler(headCell?.id)}
              >
                {headCell.label}
                {orderBy === headCell?.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </Box>
                ) : null}
              </TableSortLabel>
            ) : (
              headCell.label
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["ASC", "DESC"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = (props) => {
  const { numSelected } = props;

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
      }}
    >
      <Typography
        sx={{ flex: "1 1 100%" }}
        variant="h6"
        id="tableTitle"
        component="div"
      >
        PO Warehouse
      </Typography>

      <Tooltip title="Filter list">
        <IconButton>
          <FilterListIcon />
        </IconButton>
      </Tooltip>
    </Toolbar>
  );
};

export default function EnhancedTable() {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [openPO, setOpenPO] = useState(false);

  const [isEdit, setIsEdit] = useState(null);
  // const { warehouseListForDropdown } = useSelector((state) => state.warehouseReducer);

  const { POList, count, order, sortField, offset, take, search } = useSelector(
    (state) => state.warehousePOReducer
  );
  const debouncedSearch = useDebounce(search, 500);

  React.useEffect(() => {
    dispatch(getPOkList());
  }, [debouncedSearch]);

  React.useEffect(() => {
    dispatch(getWarehouseList());
    dispatch(getProducts());
    dispatch(getBrandOwnerList());
  }, [dispatch]);

  const handleChangePage = (event, newPage) => {
    dispatch({
      type: Utils.ActionName.WAREHOUSE_PO,
      payload: { offset: newPage + 1 },
    });
    dispatch(getPOkList());
  };

  const handleChangeRowsPerPage = (event) => {
    dispatch({
      type: Utils.ActionName.WAREHOUSE_PO,
      payload: { take: event.target.value, offset: 1 },
    });
    dispatch(getPOkList());
  };

  const handleRequestSort = (event, property) => {
    const isAsc = sortField === property && order === "ASC";
    // setOrder(isAsc ? "desc" : "asc");
    dispatch({
      type: Utils.ActionName.WAREHOUSE_PO,
      payload: { sortField: property, order: isAsc ? "DESC" : "ASC" },
    });
    dispatch(getPOkList());
  };

  const handleSearch = (e) => {
    const searchedVal = e.target.value.trim();
    dispatch({
      type: Utils.ActionName.WAREHOUSE_PO,
      payload: { search: searchedVal, take: take, offset: 1 },
    });
    // dispatch(getPOkList());
  };

  // const { brandOwnerListForDropdown } = useSelector((state) => state.brandOwnerReducer);

  const initialValues = {
    warehouseId: null,
    storeName: "",

    productName: "",
    endDate: moment(),
    startDate: moment(),
    productId: null,
  };
  // const { products } = useSelector((state) => state.productsReducer);
  const {
    warehouseListForDropdown,
    brandOwnerListForDropdown,
    productsDropDownList,
    // brandOwnerListForDropdown,
  } = useSelector((state) => state.commonReducer);

  return (
    <>
      <Box sx={{ width: "100%" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: 10,
          }}
        >
          <Stack direction="row" space={2}>
            <Stack direction="row" space={2}>
              {/* <Tooltip title="Please select store First"> */}
              <Button
                variant="outlined"
                startIcon={<AddCircleOutlineOutlinedIcon />}
                onClick={() => setOpen(true)}
                // disabled={!warehouseId}
              >
                Create PO Warehouse
              </Button>
              {/* </Tooltip> */}
            </Stack>
            <Stack sx={{ width: "200px", marginLeft: 1 }}>
              <Box direction="row" spacing={2} sx={{ display: "flex", mb: 2 }}>
                <Formik
                  // enableReinitialize = {}
                  initialValues={initialValues}
                  // validationSchema={Schema.SKUTimelineSchema}
                  onSubmit={(values, { setSubmitting }) => {
                    dispatch({
                      type: Utils.ActionName.WAREHOUSE_PO,
                      payload: values,
                    });
                    dispatch(getPOkList(values));
                  }}
                >
                  {({
                    values,
                    errors,
                    touched,
                    setFieldValue,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                    /* and other goodies */
                  }) => (
                    <Form onSubmit={handleSubmit}>
                      <Box sx={{ display: "flex" }}>
                        <Stack
                          direction="row"
                          spacing={2}
                          style={{ width: 200 }}
                        >
                          <AutoComplete
                            freeSolo={false}
                            // onBlur={handleBlur}
                            values={values.warehouseId}
                            onChange={(event, value) => {
                              if (value !== null) {
                                setFieldValue("warehouseId", value?.id);
                                setFieldValue(
                                  "storeName",
                                  value?.warehouseNickname
                                );
                                dispatch({
                                  type: Utils.ActionName.SKU_TIMELINE,
                                  payload: { skuData: [] },
                                });
                              }
                            }}
                            helperText={
                              touched.warehouseId && errors.warehouseId
                            }
                            error={Boolean(
                              touched.warehouseId && errors.warehouseId
                            )}
                            name={"warehouseId"}
                            label={"warehouseId"}
                            touched={touched.warehouseId}
                            options={warehouseListForDropdown}
                            getOptionLabel={(option) =>
                              option?.warehouseNickname
                            }
                            // isRequired={true}
                            getOptionSelected={(option, value) => {
                              return `${option.id}` === `${value.id}`;
                            }}
                            defaultValue={() => {
                              const index =
                                warehouseListForDropdown?.length &&
                                warehouseListForDropdown?.findIndex(
                                  (x) => x?.id === values.warehouseId
                                );
                              return warehouseListForDropdown[index];
                            }}
                          />
                        </Stack>
                        <Stack
                          direction="row"
                          spacing={2}
                          style={{ width: 200 }}
                        >
                          <AutoComplete
                            freeSolo={false}
                            // onBlur={handleBlur}
                            values={values.vendor}
                            onChange={(event, value) => {
                              if (value !== null) {
                                setFieldValue("vender", value?.id);
                              }
                            }}
                            helperText={touched.vendor && errors.vendor}
                            error={Boolean(touched.vendor && errors.vendor)}
                            name={"vendor"}
                            label={"Vendor"}
                            touched={touched.vendor}
                            options={brandOwnerListForDropdown}
                            getOptionLabel={(option) => option?.alias}
                            // isRequired={true}
                            getOptionSelected={(option, value) => {
                              return `${option.id}` === `${value.id}`;
                            }}
                            defaultValue={() => {
                              const index = brandOwnerListForDropdown?.findIndex(
                                (x) => x?.id === values?.productId
                              );
                              return brandOwnerListForDropdown[index];
                            }}
                          />
                        </Stack>

                        <Stack style={{ width: 200 }}>
                          <DatePicker
                            value={values.startDate}
                            name="startDate"
                            handleChange={(newVal) =>
                              setFieldValue("startDate", newVal)
                            }
                            label="From Date"
                            error={errors.startDate}
                          />
                        </Stack>
                        <Stack
                          direction="row"
                          spacing={2}
                          style={{ width: 200 }}
                        >
                          <DatePicker
                            value={values.endDate}
                            name="endDate"
                            handleChange={(newVal) =>
                              setFieldValue("endDate", newVal)
                            }
                            label="To Date"
                            error={errors.endDate}
                          />
                        </Stack>

                        <Button
                          variant="outlined"
                          spacing={2}
                          sx={{ mr: 2 }}
                          type="submit"
                        >
                          Submit
                        </Button>
                      </Box>
                    </Form>
                  )}
                </Formik>
              </Box>
            </Stack>
          </Stack>
        </div>
        <Stack sx={{ alignItems: "flex-end", mb: 1 }}>
          <TextField
            sx={{ width: 300 }}
            id="standard-search"
            label="Search field"
            searrch={search}
            type="search"
            variant="standard"
            onChange={handleSearch}
            InputProps={{
              endAdornment: (
                <InputAdornment>
                  <IconButton>
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Stack>
        <Paper sx={{ width: "100%", mb: 2 }}>
          <EnhancedTableToolbar />
          <TableContainer>
            <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
              <EnhancedTableHead
                order={order}
                orderBy={sortField}
                onRequestSort={handleRequestSort}
              />
              <TableBody>
                {POList &&
                  POList?.length &&
                  POList?.map((row, index) => {
                    return (
                      <TableRow hover key={row?.id}>
                        <TableCell component="th" id={index} scope="row">
                          {row?.poNumber || "N/A"}
                        </TableCell>
                        <TableCell align="left">
                          {row?.grnId || "N/A"}
                        </TableCell>
                        <TableCell align="left">{row?.date || "N/A"}</TableCell>
                        <TableCell align="left">
                          {row?.vendorId || "N/A"}
                        </TableCell>
                        <TableCell align="left">
                          {row?.vendorName || "N/A"}
                        </TableCell>
                        <TableCell align="left">
                          {row?.madeBy || "N/A"}
                        </TableCell>
                        <TableCell align="left">{row?.warehouse}</TableCell>
                        <TableCell align="left">{row?.warehouseName}</TableCell>
                        <TableCell align="left">{row?.type}</TableCell>
                        <TableCell align="left">{row?.status}</TableCell>
                        <TableCell align="left">{row?.totalValue}</TableCell>
                        <TableCell align="left">
                          <VisibilityIcon
                            onClick={() => {
                              dispatch(getPOById(row.poNumber, row?.warehouse));
                              setOpenPO(true);
                            }}
                          />
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25]}
            component="div"
            count={count}
            rowsPerPage={take}
            page={offset - 1}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </Box>
      <Modal
        header={`${isEdit ? "Edit" : "Add"} PO Warehouse`}
        children={
          <AddUser setOpen={setOpen} isEdit={isEdit} setIsEdit={setIsEdit} />
        }
        handleClose={() => {
          setOpen(false);
          setIsEdit(null);
        }}
        open={open}
        minWidth={"80%"}
      />
      <Modal
        header={"PO Warehouse Detail"}
        children={
          <PODetail
            setOpenPO={setOpenPO}
            isEdit={isEdit}
            setIsEdit={setIsEdit}
          />
        }
        handleClose={() => {
          setOpenPO(false);
          // setIsEdit(null);
        }}
        open={openPO}
        minWidth={1000}
      />
    </>
  );
}
