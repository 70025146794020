import Utils from "../../../utility";

const initialState = {
  warehouseStockList: [],
  order: "DESC",
  sortField: "name",
  offset: 1,
  take: 10,
  search: "",
  exportData: [],
  count: 0,
  printGrnData: {},
};
export const warehouseStockReducer = (state = initialState, action) => {
  switch (action.type) {
    case `${Utils.ActionName.WAREHOUSE_STOCK}`:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return { ...state };
  }
};
