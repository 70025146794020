import * as React from "react";
import { useState, useRef } from "react";
import Utils from "../../../utility";
import MenuItem from "@mui/material/MenuItem";
import EditIcon from "@mui/icons-material/Edit";
import DeleteConfirmationPopup from "../../../component/modal/deleteModel";
import moment from "moment";
import useDebounce from "../../../hooks/debounce";
import EditWarehouseStock from "./editWarehouseStock";
import {
  getWarehouseStockListById,
  deleteWarehouseStock,
  getAllWarehouseStockDataForExport,
} from "./action";
import { CSVLink } from "react-csv";
import PropTypes from "prop-types";
import { alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import FilterListIcon from "@mui/icons-material/FilterList";
import { visuallyHidden } from "@mui/utils";
import { useDispatch, useSelector } from "react-redux";
import Stack from "@mui/material/Stack";
import Modal from "../../../component/modal";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import AddUser from "./addWarehouseStocks";
import Select from "../../../component/select";
// import { getProducts } from "../../productRelated/products/action";
import { useReactToPrint } from "react-to-print";

// import { getBrandOwnerList } from "../../userAccount/brandOwner.js/action";
const headers = [
  { label: "Product ID", key: "ProductID" },
  { label: "Product Name", key: "ProductName" },
  { label: "barcode", key: "barcode" },

  { label: "Selling price ", key: "sellingPrice" },
  { label: "Total Amount", key: "totalAmount" },
  // { label: "Short Quantity", key: "ShortQuantity" },
  { label: "expiry", key: "expiry" },
  { label: "quantity", key: "quantity" },
  { label: "Warehouse name", key: "storeName" },

  // { label: "daysInStock", key: "daysInStock" },
  { label: "printedMrp", key: "printedMrp" },
  { label: "productCostPrice", key: "productCostPrice" },
  { label: "purchaseId", key: "purchaseId" },
  { label: "vendor Name", key: "vendorName" },
  // { label: "DiscountPercent", key: "DiscountPercent" },
  { label: "id", key: "id" },
  // { label: "Selling price ", key: "sellingPrice" },
  // { label: "Total Amount", key: "totalAmount" },
  // // { label: "Short Quantity", key: "ShortQuantity" },
  // // { label: "expiry", key: "expiry" },
  // { label: "quantity", key: "quantity" },
];
const headCells = [
  {
    id: "id",
    numeric: true,
    disablePadding: true,
    label: "Product Id",
    sorting: false,
  },
  {
    id: "warehouseNickname",
    numeric: false,
    disablePadding: true,
    label: "Name",
  },
  {
    id: "barcode",
    numeric: false,
    disablePadding: true,
    label: "Barcode",
  },
  {
    id: "quantity",
    numeric: false,
    disablePadding: true,
    label: "Selling Price",
  },
  {
    id: "brand",
    numeric: false,
    disablePadding: true,
    label: "Brand",
  },
  {
    id: "Category",
    numeric: false,
    disablePadding: true,
    label: "Category",
  },
  {
    id: "totalAmount",
    numeric: false,
    disablePadding: true,
    label: "Total Amount",
  },
  // {
  //   id: "expiry",
  //   numeric: false,
  //   disablePadding: true,
  //   label: "Expiry",
  // },
  // {
  //   id: "expiryInDay",
  //   numeric: false,
  //   disablePadding: true,
  //   label: "Expiry In Day",
  // },
  {
    id: "quantity",
    numeric: false,
    disablePadding: true,
    label: "quantity",
  },
  {
    id: "Action",
    numeric: false,
    disablePadding: true,
    label: "Action",
  },
];

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            sortDirection={
              orderBy === headCell?.id ? order.toLowerCase() : false
            }
          >
            {headCell?.sorting ? (
              <TableSortLabel
                active={orderBy === headCell?.id}
                direction={
                  orderBy === headCell?.id ? order.toLowerCase() : "asc"
                }
                onClick={createSortHandler(headCell?.id)}
              >
                {headCell.label}
                {orderBy === headCell?.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </Box>
                ) : null}
              </TableSortLabel>
            ) : (
              headCell.label
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["ASC", "DESC"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = (props) => {
  const { numSelected } = props;

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
      }}
    >
      <Typography
        sx={{ flex: "1 1 100%" }}
        variant="h6"
        id="tableTitle"
        component="div"
      >
        Warehouse Stock
      </Typography>

      <Tooltip title="Filter list">
        <IconButton>
          <FilterListIcon />
        </IconButton>
      </Tooltip>
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

export default function EnhancedTable() {
  const dispatch = useDispatch();
  const [warehouseId, setWarehouseId] = React.useState(null);
  const [open, setOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(null);
  const [deleteConfirmation, setDeleteConfirmation] = useState(null);
  const [cvcData, gets2wList] = useState([]);
  const [grandTotal, setGrandTotal] = useState(0);
  const [openEditSS, setOpenEditSS] = useState(null);

  const {
    warehouseStockList,
    exportData,
    order,
    sortField,
    offset,
    take,
    search,
    printGrnData,
    count,
  } = useSelector((state) => state.warehouseStockReducer);
  const debouncedSearch = useDebounce(search, 500);

  React.useEffect(() => {
    // dispatch(getWarehouseList());
    // dispatch(getProducts());
    // dispatch(getBrandOwnerList(50));
  }, [dispatch]);
  React.useEffect(() => {
    // dispatch(getWarehouseStockListById(warehouseId));
  }, [debouncedSearch]);
  const handleChangePage = (event, newPage) => {
    dispatch({
      type: Utils.ActionName.WAREHOUSE_STOCK,
      payload: { offset: newPage + 1 },
    });
    dispatch(getWarehouseStockListById(warehouseId));
  };

  const handleChangeRowsPerPage = (event) => {
    dispatch({
      type: Utils.ActionName.WAREHOUSE_STOCK,
      payload: { take: event.target.value, offset: 1 },
    });
    dispatch(getWarehouseStockListById(warehouseId));
  };

  const handleSearch = (e) => {
    const searchedVal = e.target.value.trim();
    dispatch({
      type: Utils.ActionName.WAREHOUSE_STOCK,
      payload: { search: searchedVal, take: take, offset: 1 },
    });
    dispatch(getWarehouseStockListById(warehouseId));
  };
  const handleWarehouseSelect = (e) => {
    setWarehouseId(e.target.value);
    dispatch(getWarehouseStockListById(e.target.value));
    dispatch(getAllWarehouseStockDataForExport(e.target.value));
  };
  const { permissions } = useSelector((state) => state.commonReducer);

  // const { warehouseList } = useSelector((state) => state.warehouseReducer);
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  React.useEffect(() => {
    if (exportData?.length) {
      const GRNCVC = exportData.map((item) => {
        return {
          ProductID: item?.product?.id,
          ProductName: item?.product?.name,
          barcode: item?.product?.barcode,

          sellingPrice: item?.sellingPrice,
          expiry: item?.expiryDate,
          quantity: item?.quantity,
          totalAmount: +item?.quantity * +item?.sellingPrice,
          storeName: item.warehouse.warehouseNickname,
          // daysInStock: item.daysInStock,
          printedMrp: item.printedMrp,
          productCostPrice: item.productCostPrice,
          purchaseId: item.purchaseId,
          vendorName: item?.product?.brandsManager?.alias,
          // DiscountPercent: item.DiscountPercent,
          id: item.id,
          // sellingPrice: item.product?.productMrp,
          // // expiry: item.expiryDate,
          // quantity: item.quantity,
          // totalAmount: +item?.quantity * +item?.productCostPrice,
        };
      });
      gets2wList(GRNCVC);
    }
  }, [exportData]);
  const {
    warehouseListForDropdown,
    // brandOwnerListForDropdown,
    // productsDropDownList,
    // brandOwnerListForDropdown,
  } = useSelector((state) => state.commonReducer);

  return (
    <>
      <Box sx={{ width: "100%" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: 10,
          }}
        >
          <Stack direction="row" space={2}>
            <Stack direction="row" space={2}>
              {!permissions || permissions["warehouseStock"]?.add ? (
                <Button
                  variant="outlined"
                  startIcon={<AddCircleOutlineOutlinedIcon />}
                  onClick={() => setOpen(true)}
                  disabled={!warehouseId}
                >
                  Add Warehouse GRN
                </Button>
              ) : (
                <></>
              )}
            </Stack>
            <Stack sx={{ width: "200px", marginLeft: 1 }}>
              <Select
                handleChange={handleWarehouseSelect}
                value={warehouseId}
                label={"Select Warehouse"}
                option={warehouseListForDropdown.map((item) => (
                  <MenuItem key={item?.id} value={`${item?.id}`}>
                    {item?.warehouseNickname}
                  </MenuItem>
                ))}
                name="manager"
                // error={errors.manager}
              />
            </Stack>
            {warehouseId && (
              <Stack direction="row" spacing={2}>
                <Button
                  variant="outlined"
                  disabled={!cvcData.length}
                  sx={{ color: "black" }}
                >
                  <CSVLink
                    data={cvcData}
                    headers={headers}
                    filename={"warehouse-stock"}
                  >
                    Export
                  </CSVLink>
                </Button>
              </Stack>
            )}
          </Stack>
          <TextField
            id="standard-search"
            label="Search field"
            searrch={search}
            type="search"
            variant="standard"
            onChange={handleSearch}
            InputProps={{
              endAdornment: (
                <InputAdornment>
                  <IconButton>
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </div>
        <Paper sx={{ width: "100%", mb: 2 }}>
          <EnhancedTableToolbar />
          <TableContainer>
            <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
              <EnhancedTableHead order={order} orderBy={sortField} />
              <TableBody>
                {warehouseStockList?.length ? (
                  warehouseStockList?.map((row, index) => {
                    return (
                      <TableRow hover key={row?.id}>
                        <TableCell component="th" id={index} scope="row">
                          {row?.product?.id}
                        </TableCell>
                        <TableCell align="left">{row?.product?.name}</TableCell>
                        <TableCell align="left">
                          {row?.product?.barcode}
                        </TableCell>
                        <TableCell align="left">
                          {row?.sellingPrice}
                        </TableCell>{" "}
                        <TableCell align="left">
                          {row?.product?.brands?.brandName}
                        </TableCell>
                        <TableCell align="left">
                          {row?.product?.category?.categoryName}
                        </TableCell>
                        <TableCell align="left">
                          {+row?.quantity * +row?.productCostPrice}
                        </TableCell>
                        {/* <TableCell align="left">
                          {moment(row?.expiryDate).format("DD-MM-YYYY")}
                        </TableCell>
                        <TableCell align="left">
                          {moment
                            .duration(moment().diff(moment(row?.expiryDate)))
                            .asDays()
                            .toFixed(2)}
                        </TableCell> */}
                        <TableCell align="left">{row?.quantity}</TableCell>
                        <TableCell>
                          {!permissions && (
                            <EditIcon
                              onClick={() => {
                                setOpenEditSS(row);
                              }}
                            />
                          )}
                        </TableCell>
                      </TableRow>
                    );
                  })
                ) : (
                  <TableCell align="left">{"No data Found"}</TableCell>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25]}
            component="div"
            count={count}
            rowsPerPage={take}
            page={offset - 1}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </Box>
      <Modal
        minWidth={"98%"}
        header={`${isEdit ? "Edit" : "Add"} Warehouse GRN`}
        children={
          <AddUser
            setOpen={setOpen}
            isEdit={isEdit}
            setIsEdit={setIsEdit}
            handlePrint={handlePrint}
            grandTotal={grandTotal}
            setGrandTotal={setGrandTotal}
            warehouseId={warehouseId}
          />
        }
        handleClose={() => {
          setOpen(false);
          setIsEdit(null);
        }}
        open={open}
      />
      <Modal
        header={"Update Store Stock"}
        children={
          <EditWarehouseStock
            setOpen={setOpenEditSS}
            openEditSS={openEditSS}
            // setIsEdit={setIsEdit}
            // stockId={stockId}
            // handlePrint={handlePrint}
            // grandTotal={grandTotal}
            // setGrandTotal={setGrandTotal}
          />
        }
        handleClose={() => {
          setOpenEditSS(false);
          // setIsEdit(null);
        }}
        open={openEditSS}
        minWidth={"95%"}
      />
      {open && (
        <div
          ref={componentRef}
          style={{
            margin: "10px auto",
            width: "207.874016px",
            // border: "2px solid black",
          }}
        >
          <img
            src="https://thenewshop.in/dash/images/logob.png"
            alt="The new shop"
            style={{ width: "200px" }}
          />
          <div style={{ margin: "0px 15px" }}>
            <div style={{ fontSize: 14, fontWeight: 500 }}>
              <b>GRN Id:</b> {printGrnData?.grnId}
            </div>
            <div style={{ fontSize: 14 }}>
              <b>Total Amount:</b>
              {grandTotal}
            </div>

            <div style={{ fontSize: 14, fontWeight: 500 }}>
              <b>Warehouse :</b>
              {printGrnData?.grnProducts &&
                printGrnData?.grnProducts[0]?.grn?.warehouse?.warehouseNickname}
            </div>
            <div style={{ fontSize: 14, fontWeight: 500 }}>
              <b> GRN By :</b>{" "}
              {printGrnData?.grnProducts &&
                printGrnData?.grnProducts[0]?.grn?.createdBy?.name}
            </div>
            <div style={{ fontSize: 14, fontWeight: 500 }}>
              <b> Date :</b>
              {printGrnData?.grnProducts &&
                moment(printGrnData?.grnProducts[0]?.grn?.createdAt).format(
                  "DD-MM-YYYY  HH:mm:ss"
                )}
            </div>
          </div>
        </div>
      )}
      <DeleteConfirmationPopup
        title="Warehouse Stock"
        handleClose={() => setDeleteConfirmation(null)}
        okButtonClick={() => {
          dispatch(deleteWarehouseStock(deleteConfirmation, warehouseId));
          setDeleteConfirmation(null);
        }}
        open={deleteConfirmation}
        minWidth={500}
      />
    </>
  );
}
