import * as React from "react";
import { useState } from "react";
import Select from "../../../component/select";
import MenuItem from "@mui/material/MenuItem";
import moment from "moment";
import Utils from "../../../utility";
import { getStoreList } from "../store/action";
import { getAllGRNList, getGRNById } from "./action";
import PropTypes from "prop-types";
import { alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import { visuallyHidden } from "@mui/utils";
import { useDispatch, useSelector } from "react-redux";
import Stack from "@mui/material/Stack";
import Modal from "../../../component/modal";
import DetailGRN from "./detailGRN";

const headCells = [
  {
    id: "id",
    numeric: true,
    disablePadding: true,
    label: "GRN ID",
    sorting: false,
  },
  {
    id: "storename",
    numeric: false,
    disablePadding: true,
    label: "Date",
    sorting: false,
  },
  {
    id: "address",
    numeric: false,
    disablePadding: true,
    label: "Time",
    sorting: false,
  },
  {
    id: "Vendor ID",
    numeric: false,
    disablePadding: true,
    label: "Vendor ID",
  },
  {
    id: "Vendor",
    numeric: false,
    disablePadding: true,
    label: "Vendor",
    sorting: false,
  },
  {
    id: "Done By",
    numeric: false,
    disablePadding: true,
    label: "Done By",
    sorting: false,
  },
  {
    id: "Excess/Short",
    numeric: false,
    disablePadding: true,
    label: "Excess/Short",
    sorting: false,
  },
  {
    id: "Invoice Number",
    numeric: false,
    disablePadding: true,
    label: "Invoice Number",
    sorting: false,
  },
  {
    id: "storename",
    numeric: true,
    disablePadding: false,
    label: "Store",
    sorting: true,
  },
];

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            sortDirection={
              orderBy === headCell?.id ? order.toLowerCase() : false
            }
          >
            {headCell?.sorting ? (
              <TableSortLabel
                active={orderBy === headCell?.id}
                direction={
                  orderBy === headCell?.id ? order.toLowerCase() : "asc"
                }
                onClick={createSortHandler(headCell?.id)}
              >
                {headCell.label}
                {orderBy === headCell?.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </Box>
                ) : null}
              </TableSortLabel>
            ) : (
              headCell.label
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["ASC", "DESC"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = (props) => {
  const { numSelected } = props;

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
      }}
    >
      <Typography
        sx={{ flex: "1 1 100%" }}
        variant="h6"
        id="tableTitle"
        component="div"
      >
        Store GRN
      </Typography>
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

export default function EnhancedTable() {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(null);
  const [storeId, setStoreId] = useState(null);
  React.useEffect(() => {
    dispatch(getStoreList());
  }, [dispatch]);

  const handleRequestSort = (event, property) => {
    const isAsc = sortField === property && order === "ASC";
    dispatch({
      type: Utils.ActionName.GRN,
      payload: { sortField: property, order: isAsc ? "DESC" : "ASC" },
    });
    dispatch(getAllGRNList("store", storeId));
  };

  const handleChangePage = (event, newPage) => {
    dispatch({
      type: Utils.ActionName.GRN,
      payload: { offset: newPage + 1 },
    });
    dispatch(getAllGRNList("store", storeId));
  };

  const handleChangeRowsPerPage = (event) => {
    dispatch({
      type: Utils.ActionName.GRN,
      payload: { take: event.target.value, offset: 1 },
    });
    dispatch(getAllGRNList("store", storeId));
  };

  const { GRNList, order, sortField, offset, take, count } = useSelector(
    (state) => state.GRNReducer
  );
  const { storeList } = useSelector((state) => state.storeReducer);
  return (
    <>
      <Box sx={{ width: "100%" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: 10,
          }}
        >
          <Stack direction="row" spacing={2} style={{ width: 200 }}>
            <Select
              handleChange={(e) => {
                setStoreId(e.target.value);
                dispatch(getAllGRNList("store", e.target.value));
              }}
              value={storeId}
              label={"Select Stores"}
              option={storeList.map((item) => (
                <MenuItem key={item.id} value={item?.id}>
                  {item?.storename}
                </MenuItem>
              ))}
              name="manager"
            />
          </Stack>
        </div>
        <Paper sx={{ width: "100%", mb: 2 }}>
          <EnhancedTableToolbar />
          <TableContainer>
            <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
              <EnhancedTableHead
                order={order}
                orderBy={sortField}
                onRequestSort={handleRequestSort}
              />
              <TableBody>
                {GRNList[0]?.map((row, index) => {
                  return (
                    <TableRow hover key={row?.id}>
                      <TableCell
                        component="th"
                        id={index}
                        scope="row"
                        onClick={() => {
                          dispatch(getGRNById(row?.id));
                          setOpen(true);
                        }}
                      >
                        {row?.id}
                      </TableCell>
                      <TableCell align="left">
                        {moment(row?.createdAt).format("DD-MM-YYYY")}
                      </TableCell>
                      <TableCell align="left">
                        {moment(row?.createdAt).format("LT")}
                      </TableCell>
                      <TableCell align="left">
                        {row?.vendor?.id ? row?.vendor?.id : "-"}
                      </TableCell>
                      <TableCell align="left">
                        {row?.vendor?.alias}
                      </TableCell>
                      <TableCell align="left">{row?.locationType}</TableCell>
                      <TableCell align="left">{row?.fillRate}</TableCell>
                      <TableCell align="left">{row?.invoiceNumber}</TableCell>
                      <TableCell align="left">
                        {row?.store?.storename}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25]}
            component="div"
            count={count}
            rowsPerPage={take}
            page={offset - 1}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </Box>
      <Modal
        header={"GRN Detail"}
        children={
          <DetailGRN setOpen={setOpen} isEdit={isEdit} setIsEdit={setIsEdit} />
        }
        handleClose={() => {
          setOpen(false);
          setIsEdit(null);
        }}
        open={open}
        minWidth={1000}
      />
    </>
  );
}
