import moment from "moment";
import Utils from "../../../utility";
import { removeEmptyValues } from "../../../utility/commonFunction";

export const getStoreDiscardRtvReport = (values, setOpen) => {
  return (dispatch, getState) => {
    const { fromDate, toDate, storeId } = values;
    dispatch({
      type: Utils.ActionName.LOADING,
      payload: { loading: true },
    });
    Utils.api.postApiCall(
      Utils.endPoints.discardReports,
      {
        store: storeId,
        fromDate: `${moment(fromDate).format("YYYY-MM-DD")} 00:00:00`,
        toDate: `${moment(toDate).format("YYYY-MM-DD")} 23:59:59`,
      },
      (respData) => {
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });

        if (!respData?.data?.length) {
          Utils.showAlert(2, "No data found");
          return;
        }
        // setOpen(false);
        const linkSource = `data:application/csv;base64,${respData.data}`;
        const downloadLink = document.createElement("a");
        const fileName = `store_discard_rtv_report.csv`;
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();

        // setTimeout(() => {
        //   csvDownload.current.link.click();
        // }, 500);
      },
      (error) => {
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });
        let { data } = error;
        Utils.showAlert(
          2,
          `${Utils.endPoints.discardReports} POST Method error ${
            JSON.stringify(data?.message) || data?.error
          }`
        );
        // Utils.showAlert(2, data?.message?.join(",") || data?.error);
        // setSubmitting(true);
      }
    );
  };
};
