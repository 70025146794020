import Utils from "../../../utility";

export const getAppVersion = (callback) => {
  return (dispatch, getState) => {
    dispatch({
      type: Utils.ActionName.LOADING,
      payload: { loading: true },
    });
    Utils.api.getApiCall(
      Utils.endPoints.appVersionForDashboard,
      ``,
      (respData) => {
        callback(respData);
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });
      },
      (error) => {
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });
        let { data } = error;
        Utils.showAlert(
          2,
          `${Utils.endPoints.appVersionForDashboard} GET Method error ${
            JSON.stringify(data?.message) || data?.error
          }`
        );
        // Utils.showAlert(2, data?.message?.join(",") || data?.error);
      }
    );
  };
};

export const updateVersion = (version) => {
  return (dispatch, getState) => {
    dispatch({
      type: Utils.ActionName.LOADING,
      payload: { loading: true },
    });
    // const { images } = getState().productsReducer;
    // const { brandName } = values;
    var dataToSend = {
      version,
    };

    Utils.api.putApiCall(
      Utils.endPoints.appVersion + "?version=" + version,
      {},
      (respData) => {
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });
        // dispatch({
        //   type: Utils.ActionName.PRODUCTS,
        //   payload: { images: [...images, respData.data.Location] },
        // });
        // setOpen(false);
        Utils.showAlert(1, "App version updated successfully");
        // dispatch(getAppVersion());
      },
      (error) => {
        let { data } = error;
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });
        // Utils.showAlert(2, data?.message?.join(",") || data?.error);
        Utils.showAlert(
          2,
          `update ${Utils.endPoints.appVersion} PUT Method error ${
            JSON.stringify(data?.message) || data?.error
          }`
        );
        // setSubmitting(true);
      }
    );
  };
};
