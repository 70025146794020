import moment from "moment";
import Utils from "../../../utility";

const initialState = {
  warehouseToStore: [],
  order: "DESC",
  // sortField: "createAt",
  warehouseId: "",
  storeId: "",
  offset: 1,
  take: 10,
  search: "",
  count: 0,
  warehouseToStoreForExport: [],
  fromDate: moment(),
  toDate: moment(),
};
export const warehouseToStoreReducer = (state = initialState, action) => {
  switch (action.type) {
    case `${Utils.ActionName.WAREHOUSE_TO_STORE}`:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return { ...state };
  }
};
