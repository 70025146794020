import Utils from "../../../utility";

export const addRecipes = (values, setOpen) => {
  return (dispatch, getState) => {
    dispatch({
      type: Utils.ActionName.LOADING,
      payload: { loading: true },
    });
    // const { brandName } = values;
    const dataToSend = {
      name: values?.recipeName,
      product: values?.product,
    };

    Utils.api.postApiCall(
      Utils.endPoints.createRecipes,
      dataToSend,
      (respData) => {
        setOpen(false);
        Utils.showAlert(1, "Ingredients added Successfully");
        dispatch(getRecipes());
      },
      (error) => {
        let { data } = error;
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });
        // Utils.showAlert(2, data?.message?.join(",") || data?.error);
        Utils.showAlert(
          2,
          `${Utils.endPoints.createBrand} POST Method error ${
            JSON.stringify(data?.message) || data?.error
          }`
        );
        // setSubmitting(true);
      }
    );
  };
};

export const addIngredient = (values, setOpen) => {
  return (dispatch, getState) => {
    dispatch({
      type: Utils.ActionName.LOADING,
      payload: { loading: true },
    });
    // const { brandName } = values;
    const { recipesDetail } = getState().recipesReducer;
    const dataToSend = {
      ingredients: values?.product,
      recipe: recipesDetail?.id,
    };

    Utils.api.postApiCall(
      Utils.endPoints.addIngredient,
      dataToSend,
      (respData) => {
        setOpen(false);
        Utils.showAlert(1, "Ingredients added Successfully");
        dispatch(getRecipes());
      },
      (error) => {
        let { data } = error;
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });
        // Utils.showAlert(2, data?.message?.join(",") || data?.error);
        Utils.showAlert(
          2,
          `${Utils.endPoints.createBrand} POST Method error ${
            JSON.stringify(data?.message) || data?.error
          }`
        );
        // setSubmitting(true);
      }
    );
  };
};

export const getRecipes = (allList = false) => {
  return (dispatch, getState) => {
    const { order, sortField, offset, take, search } =
      getState().recipesReducer;

    dispatch({
      type: Utils.ActionName.LOADING,
      payload: { loading: true },
    });
    Utils.api.getApiCall(
      Utils.endPoints.recipes,
      `?order=${order}&sortField=${sortField}&offset=${
        allList ? -1 : offset
      }&take=${take}&search=${search}`,
      (respData) => {
        dispatch({
          type: Utils.ActionName.RECIPES,
          payload: {
            recipes: respData.data.recipe,
            count: respData.data.count,
          },
        });
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });
        // navigate("/dashboard");
      },
      (error) => {
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });
        let { data } = error;
        Utils.showAlert(
          2,
          `${Utils.endPoints.recipes} GET Method error ${
            JSON.stringify(data?.message) || data?.error
          }`
        );
        // Utils.showAlert(2, data?.message?.join(",") || data?.error);
        // setSubmitting(true);
      }
    );
  };
};

/***
 * get product detail
 */

export const getProductDetail = (id, callBack) => {
  return (dispatch, getState) => {
    dispatch({
      type: Utils.ActionName.LOADING,
      payload: { loading: true },
    });
    Utils.api.getApiCall(
      Utils.endPoints.products,
      `${id}`,
      (respData) => {
        dispatch({
          type: Utils.ActionName.RECIPES,
          payload: {
            productDetail: respData.data,
          },
        });
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });
        callBack(respData.data);
      },
      (error) => {
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });
        let { data } = error;
        Utils.showAlert(
          2,
          `${Utils.endPoints.recipes} GET Method error ${
            JSON.stringify(data?.message) || data?.error
          }`
        );
      }
    );
  };
};

export const getRecipesDetail = (id, setOpen) => {
  return (dispatch, getState) => {
    const { recipesDetail } = getState().recipesReducer;
    dispatch({
      type: Utils.ActionName.LOADING,
      payload: { loading: true },
    });
    Utils.api.getApiCall(
      Utils.endPoints.recipes,
      `/${id}`,
      (respData) => {
        dispatch({
          type: Utils.ActionName.RECIPES,
          payload: { recipesDetail: { ...recipesDetail, ...respData.data } },
        });
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });
        setOpen(true);
        // navigate("/dashboard");
      },
      (error) => {
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });
        let { data } = error;
        Utils.showAlert(
          2,
          `${Utils.endPoints.recipes} detail GET Method error ${
            JSON.stringify(data?.message) || data?.error
          }`
        );
      }
    );
  };
};

export const getProductsRecipes = () => {
  return (dispatch, getState) => {
    dispatch({
      type: Utils.ActionName.LOADING,
      payload: { loading: true },
    });
    const { order, sortField, offset, take, search } =
      getState().productsReducer;
    Utils.api.getApiCall(
      Utils.endPoints.products,
      `?order=${order}${
        sortField ? "&sortField=" + sortField : ""
      }&offset=${offset}&search=${search}&isrecipe=true`,
      (respData) => {
        dispatch({
          type: Utils.ActionName.RECIPES,
          payload: {
            recipesProducts: respData.data?.product,
          },
        });
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });
      },
      (error) => {
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });
        let { data } = error;
        // Utils.showAlert(2, data?.message?.join(",") || data?.error);
        Utils.showAlert(
          2,
          `${Utils.endPoints.products} GET Method error ${
            JSON.stringify(data?.message) || data?.error
          }`
        );
        // setSubmitting(true);
      }
    );
  };
};
