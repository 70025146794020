import Utils from "../../../utility";

export const updateCategory = (values, setOpen, id, resetForm, setIsEdit) => {
  return (dispatch) => {
    dispatch({
      type: Utils.ActionName.LOADING,
      payload: { loading: true },
    });
    const dataToSend = values;

    Utils.api.putApiCall(
      Utils.endPoints.category + id,
      dataToSend,
      (respData) => {
        setOpen(false);
        Utils.showAlert(1, "Category updated successfully");
        // dispatch(getAllUser());
        resetForm({});
        setIsEdit(null);
        dispatch(getCategoryList());
        dispatch(getCategoryListForDropdown());
        // dispatch({type:Utils.ActionName.USER_LIST
        //   ,payload:{userlist:respData.data}})
        // navigate("/dashboard");
      },
      (error) => {
        let { data } = error;
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });
        Utils.showAlert(
          2,
          `Update ${Utils.endPoints.category} PUT Method error ${
            JSON.stringify(data?.message) || data?.error
          }`
        );
        // Utils.showAlert(2, data?.message?.join(",") || data?.error);
      }
    );
  };
};
export const addCategory = (values, setOpen) => {
  return (dispatch) => {
    dispatch({
      type: Utils.ActionName.LOADING,
      payload: { loading: true },
    });
    const { brandName } = values;
    const dataToSend = values;
    dataToSend.isDeleted = false;
    Utils.api.postApiCall(
      Utils.endPoints.createCategory,
      dataToSend,
      (respData) => {
        Utils.showAlert(1, "Category added successfully");
        setOpen(false);
        dispatch(getCategoryList());
      },
      (error) => {
        let { data } = error;
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });
        // Utils.showAlert(2, data?.message?.join(",") || data?.error);
        Utils.showAlert(
          2,
          `${Utils.endPoints.createCategory} POST Method error ${
            JSON.stringify(data?.message) || data?.error
          }`
        );
        // setSubmitting(true);
      }
    );
  };
};

export const deleteCategory = (id) => {
  return (dispatch) => {
    dispatch({
      type: Utils.ActionName.LOADING,
      payload: { loading: true },
    });
    Utils.api.deleteApiCall(
      Utils.endPoints.category + `${id}`,
      "",
      (respData) => {
        Utils.showAlert(1, "Category delete sucesfully");
        dispatch(getCategoryList());
      },
      (error) => {
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });
        let { data } = error;
        Utils.showAlert(
          2,
          `delete ${Utils.endPoints.category} DELETE Method error ${
            JSON.stringify(data?.message) || data?.error
          }`
        );
        // Utils.showAlert(2, data?.message?.join(",") || data?.error);
        // setSubmitting(true);
      }
    );
  };
};
// `?order=${order}&sortField=${sortField}&offset=${allList?-1:offset}&take=${take}&search=${search}`,
export const getCategoryList = (allList = false) => {
  return (dispatch, getState) => {
    const { order, sortField, offset, take, search } =
      getState().categoryReducer;

    dispatch({
      type: Utils.ActionName.LOADING,
      payload: { loading: true },
    });
    Utils.api.getApiCall(
      Utils.endPoints.category,
      `?order=${order}${
        sortField ? "&sortField=" + sortField : ""
      }&offset=${offset}&take=${take}&search=${search}`,
      (respData) => {
        dispatch({
          type: Utils.ActionName.CATEGORY,
          payload: {
            categoryList: respData.data?.categories,
            count: respData.data?.count,
          },
        });
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });
        // navigate("/dashboard");
      },
      (error) => {
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });
        let { data } = error;
        Utils.showAlert(
          2,
          `${Utils.endPoints.category} GET Method error ${
            JSON.stringify(data?.message) || data?.error
          }`
        );
        // Utils.showAlert(2, data?.message?.join(",") || data?.error);
        // setSubmitting(true);
      }
    );
  };
};

export const getCategoryListForDropdown = () => {
  return (dispatch, getState) => {
    const { order, sortField, offset, take, search } =
      getState().categoryReducer;

    dispatch({
      type: Utils.ActionName.LOADING,
      payload: { loading: true },
    });
    Utils.api.getApiCall(
      Utils.endPoints.category,
      `?order=${order}${
        sortField ? "&sortField=" + sortField : ""
      }&offset=${offset}&search=${search}`,
      (respData) => {
        dispatch({
          type: Utils.ActionName.COMMON,
          payload: {
            categoryListForDropDown: respData.data?.categories,
          },
        });
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });
        // navigate("/dashboard");
      },
      (error) => {
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });
        let { data } = error;
        Utils.showAlert(
          2,
          `${Utils.endPoints.category} GET Method error ${
            JSON.stringify(data?.message) || data?.error
          }`
        );
        // Utils.showAlert(2, data?.message?.join(",") || data?.error);
        // setSubmitting(true);
      }
    );
  };
};
