import moment from "moment";
import Utils from "../../../utility";
export const getw2sList = () => {
  return (dispatch, getState) => {
    const { order, sortField, storeId, warehouseId, offset, take, search } =
      getState().warehouseToStoreReducer;
    dispatch({
      type: Utils.ActionName.LOADING,
      payload: { loading: true },
    });
    Utils.api.getApiCall(
      Utils.endPoints.w2s,
      `?order=${order}&offset=${offset}&take=${take}&search=${search}&storeId=${
        storeId ? storeId : ""
      }&warehouseId=${warehouseId ? warehouseId : ""}`,
      // `?order=${order}&sortField=${sortField}&offset=${offset}&take=${take}&search=${search}`,
      (respData) => {
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });
        dispatch({
          type: Utils.ActionName.WAREHOUSE_TO_STORE,
          payload: {
            warehouseToStore: respData.data?.warehouseList,
            count: respData.data.count,
          },
        });
        // navigate("/dashboard");
      },
      (error) => {
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });
        let { data } = error;
        Utils.showAlert(
          2,
          `${Utils.endPoints.w2s} GET Method error ${
            JSON.stringify(data?.message) || data?.error
          }`
        );
        // Utils.showAlert(2, data?.message?.join(",") || data?.error);
        // setSubmitting(true);
      }
    );
  };
};

export const getw2sListForExport = (storeId, warehouseId, csvDownload) => {
  return (dispatch, getState) => {
    const { order, fromDate, toDate, offset, take, search } =
      getState().warehouseToStoreReducer;
    dispatch({
      type: Utils.ActionName.LOADING,
      payload: { loading: true },
    });
    Utils.api.getApiCall(
      Utils.endPoints.w2s,
      `?order=${order}&offset=${offset}&take=${50}&search=&storeId=${
        storeId ? storeId : ""
      }&warehouseId=${warehouseId ? warehouseId : ""}&fromDate=${moment(
        fromDate
      ).format("YYYY-MM-DD")}&toDate=${moment(toDate).format("YYYY-MM-DD")}`,
      // `?order=${order}&sortField=${sortField}&offset=${offset}&take=${take}&search=${search}`,
      (respData) => {
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });
        dispatch({
          type: Utils.ActionName.WAREHOUSE_TO_STORE,
          payload: {
            warehouseToStoreForExport: respData.data?.warehouseList,
          },
        });
        setTimeout(() => {
          csvDownload.current.link.click();
        }, 500);
        // navigate("/dashboard");
      },
      (error) => {
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });
        let { data } = error;
        Utils.showAlert(
          2,
          `${Utils.endPoints.w2s} GET Method error ${
            JSON.stringify(data?.message) || data?.error
          }`
        );
        // Utils.showAlert(2, data?.message?.join(",") || data?.error);
        // setSubmitting(true);
      }
    );
  };
};

export const makeATransferW2s = (values, setOpen, setSubmitting) => {
  return (dispatch) => {
    dispatch({
      type: Utils.ActionName.LOADING,
      payload: { loading: true },
    });
    const dataToSend = {
      product: values.stocks.map((item) => {
        return {
          product: item.product,
          quantity: item.quantity,
        };
      }),
      warehouse: +values.warehouseTo,
      store: values.store,
    };

    Utils.api.postApiCall(
      Utils.endPoints.createW2S,
      dataToSend,
      (respData) => {
        // setOpen(false);
        Utils.showAlert(1, "Successfull");
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });
        dispatch(getw2sList());
        // resetForm({});
        setOpen(null);
        // dispatch({type:Utils.ActionName.USER_LIST
        //   ,payload:{userlist:respData.data}})
        // navigate("/dashboard");
      },
      (error) => {
        let { data } = error;
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });
        // Utils.showAlert(2, data?.message?.join(",") || data?.error);
        Utils.showAlert(
          2,
          `${Utils.endPoints.createW2S} POST Method error ${
            JSON.stringify(data?.message) || data?.error
          }`
        );
        setSubmitting(false);
      }
    );
  };
};

export const updateStateW2s = (id, status) => {
  return (dispatch) => {
    dispatch({
      type: Utils.ActionName.LOADING,
      payload: { loading: true },
    });

    Utils.api.putApiCall(
      Utils.endPoints.updateStatusW2S + id + "?status=" + status,
      // dataToSend,{}
      {},
      (respData) => {
        // setOpen(false);
        Utils.showAlert(1, "Success");
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });
        dispatch(getw2sList());
        // resetForm({});
        // setOpen(null);
        // dispatch({type:Utils.ActionName.USER_LIST
        //   ,payload:{userlist:respData.data}})
        // navigate("/dashboard");
      },
      (error) => {
        let { data } = error;
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });
        // Utils.showAlert(2, data?.message?.join(",") || data?.error);
        Utils.showAlert(
          2,
          `${Utils.endPoints.updateStatusW2S} PUT Method error ${
            JSON.stringify(data?.message) || data?.error
          }`
        );
        // setSubmitting(true);
      }
    );
  };
};

export const getPdfw2s = (id) => {
  return (dispatch, getState) => {
    const { order, sortField, offset, take, search } =
      getState().warehouseToStoreReducer;
    dispatch({
      type: Utils.ActionName.LOADING,
      payload: { loading: true },
    });
    Utils.api.getApiCall(
      Utils.endPoints.w2sPdf + id,
      "",
      // `?order=${order}&sortField=${sortField}&offset=${offset}&take=${take}&search=${search}`,
      (respData) => {
        const linkSource = `data:application/pdf;base64,${respData.data}`;
        const downloadLink = document.createElement("a");
        const fileName = `tns${id}.pdf`;
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });
        // navigate("/dashboard");
      },
      (error) => {
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });
        let { data } = error;
        Utils.showAlert(
          2,
          `${Utils.endPoints.w2sPdf} GET Method error ${
            JSON.stringify(data?.message) || data?.error
          }`
        );
        // Utils.showAlert(2, data?.message?.join(",") || data?.error);
        // setSubmitting(true);
      }
    );
  };
};
export const updateQuntity = (values, setOpen, id) => {
  return (dispatch) => {
    dispatch({
      type: Utils.ActionName.LOADING,
      payload: { loading: true },
    });
    Utils.api.putApiCall(
      Utils.endPoints.updateQuantityW2s + id + `?quantity=${values?.quantity}`,
      {},
      (respData) => {
        setOpen(false);
        Utils.showAlert(1, "success");
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });
        dispatch(getw2sList());
        // resetForm({});
        // setIsEdit(null)
        // dispatch({type:Utils.ActionName.USER_LIST
        //   ,payload:{userlist:respData.data}})
        // navigate("/dashboard");
      },
      (error) => {
        let { data } = error;
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });
        // Utils.showAlert(2, data?.message?.join(",") || data?.error);
        Utils.showAlert(
          2,
          `${Utils.endPoints.updateQuantityW2s} PUT Method error ${
            JSON.stringify(data?.message) || data?.error
          }`
        );
        // setSubmitting(true);
      }
    );
  };
};
