import Utils from "../../../utility";
import moment from "moment";
export const getw2wList = (fromWarehouseId, toWarehouseId) => {
  return (dispatch, getState) => {
    const { order, sortField, offset, take, search } =
      getState().warehouseToWarehouseReducer;
    dispatch({
      type: Utils.ActionName.LOADING,
      payload: { loading: true },
    });
    // sortField=${sortField}
    Utils.api.getApiCall(
      Utils.endPoints.w2w,
      `?fromWarehouseId=${
        fromWarehouseId ? fromWarehouseId : ""
      }&toWarehouseId=${
        toWarehouseId ? toWarehouseId : ""
      }&order=${order}&offset=${offset}&take=${take}&search=${search}`,
      // `?`,
      (respData) => {
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });
        dispatch({
          type: Utils.ActionName.WAREHOUSE_TO_WAREHOUSE,
          payload: {
            warehouseToWarehouse: respData?.data?.warehouseList,
            count: respData?.data?.count,
          },
        });
        // navigate("/dashboard");
      },
      (error) => {
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });
        let { data } = error;
        Utils.showAlert(
          2,
          `${Utils.endPoints.w2w} GET Method error ${
            JSON.stringify(data?.message) || data?.error
          }`
        );
        // Utils.showAlert(2, data?.message?.join(",") || data?.error);
        // setSubmitting(true);
      }
    );
  };
};
export const getw2wListForExport = (
  fromWarehouseId,
  toWarehouseId,
  csvDownload
) => {
  return (dispatch, getState) => {
    const { order, fromDate, offset, take, toDate } =
      getState().warehouseToWarehouseReducer;
    dispatch({
      type: Utils.ActionName.LOADING,
      payload: { loading: true },
    });
    // sortField=${sortField}
    Utils.api.getApiCall(
      Utils.endPoints.w2w,
      `?fromWarehouseId=${
        fromWarehouseId ? fromWarehouseId : ""
      }&toWarehouseId=${
        toWarehouseId ? toWarehouseId : ""
      }&order=${order}&offset=${offset}&take=${50}&search=&fromDate=${moment(
        fromDate
      ).format("YYYY-MM-DD")}&toDate=${moment(toDate).format("YYYY-MM-DD")}`,
      // `?`,
      (respData) => {
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });
        dispatch({
          type: Utils.ActionName.WAREHOUSE_TO_WAREHOUSE,
          payload: {
            warehouseToWarehouseForExport: respData?.data?.warehouseList,
          },
        });
        setTimeout(() => {
          csvDownload.current.link.click();
        }, 500);
        // navigate("/dashboard");
      },
      (error) => {
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });
        let { data } = error;
        Utils.showAlert(
          2,
          `${Utils.endPoints.w2w} GET Method error ${
            JSON.stringify(data?.message) || data?.error
          }`
        );
        // Utils.showAlert(2, data?.message?.join(",") || data?.error);
        // setSubmitting(true);
      }
    );
  };
};
export const getWarehouseStockListById = (id) => {
  return (dispatch, getState) => {
    const { order, sortField, offset, take, search } =
      getState().warehouseReducer;
    dispatch({
      type: Utils.ActionName.LOADING,
      payload: { loading: true },
    });
    Utils.api.getApiCall(
      Utils.endPoints.warehouseById + id,
      "",
      // `?order=${order}&sortField=${sortField}&offset=${offset}&take=${take}&search=${search}`,
      (respData) => {
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });
        dispatch({
          type: Utils.ActionName.WAREHOUSE_STOCK,
          payload: {
            warehouseStockList: respData.data,
            count: respData.data.count,
          },
        });
        // navigate("/dashboard");
      },
      (error) => {
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });
        let { data } = error;
        Utils.showAlert(
          2,
          `${Utils.endPoints.warehouseById} GET Method error ${
            JSON.stringify(data?.message) || data?.error
          }`
        );
        // Utils.showAlert(2, data?.message?.join(",") || data?.error);
        // setSubmitting(true);
      }
    );
  };
};

export const updateStoreStock = (values, setOpen, id, resetForm, setIsEdit) => {
  return (dispatch) => {
    dispatch({
      type: Utils.ActionName.LOADING,
      payload: { loading: true },
    });

    const dataToSend = values;
    dataToSend.warehousePincode = +values.warehousePincode;
    dataToSend.warehouseGSTIN = +values.warehouseGSTIN;

    // dataToSend.FSSAI=values.FSSAI

    Utils.api.putApiCall(
      Utils.endPoints.storeStock + id,
      dataToSend,
      (respData) => {
        setOpen(false);
        Utils.showAlert(1, "Store stock updated successfully");
        // dispatch(getAllUser());
        resetForm({});
        setIsEdit(null);
        // dispatch(getStoreStockList());
        // dispatch({type:Utils.ActionName.USER_LIST
        //   ,payload:{userlist:respData.data}})
        // navigate("/dashboard");
      },
      (error) => {
        let { data } = error;
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });
        // Utils.showAlert(2, data?.message?.join(",") || data?.error);
        Utils.showAlert(
          2,
          `${Utils.endPoints.storeStock} PUT Method error ${
            JSON.stringify(data?.message) || data?.error
          }`
        );
        // setSubmitting(true);
      }
    );
  };
};

export const makeATransferW2W = (values, setOpen, setSubmitting) => {
  return (dispatch) => {
    dispatch({
      type: Utils.ActionName.LOADING,
      payload: { loading: true },
    });
    const dataToSend = {
      product: values.stocks.map((item) => {
        return {
          product: item.product,
          quantity: item.quantity,
        };
      }),

      fromWarehouse: values.warehouseTo,
      toWarehouse: values.warehouse,
    };

    // const dataToSend = {
    //   product: values.stocks.map((item) => {
    //     return {
    //       product: item.product,
    //       quantity: item.quantity,
    //       printedMrp: 0,
    //       sellingPrice: 0,
    //       productCostPrice: 0,
    //       expiryDate: "2022-08-11T05:30:36.236Z",
    //     };
    //   }),
    //   warehouse: values.warehouseTo,
    //   store: values.store,
    //   productEntryId: 0,
    //   quantity: 0,
    //   transferAmount: 0,
    //   transferSendDate: moment(),
    //   transferReceivedDate: moment(),
    //   transferNotes: "string",
    //   transferBill: "string",
    //   transferFile: "string",
    //   transferId: "string",
    //   // warehouse: "",
    // };
    // dataToSend.product = [
    //   {
    //     product: values.product,
    //     quantity: values.quantity,
    //   },
    // ];
    // delete dataToSend.quantity;
    // dataToSend.warehousePincode = +values.warehousePincode;
    // dataToSend.isDeleted = false;

    Utils.api.postApiCall(
      Utils.endPoints.createW2W,
      dataToSend,
      (respData) => {
        setOpen(false);
        Utils.showAlert(1, "success");
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });
        dispatch(getw2wList());
        // resetForm({});
        // setIsEdit(null)
        // dispatch({type:Utils.ActionName.USER_LIST
        //   ,payload:{userlist:respData.data}})
        // navigate("/dashboard");
      },
      (error) => {
        let { data } = error;
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });
        // Utils.showAlert(2, data?.message?.join(",") || data?.error);
        Utils.showAlert(
          2,
          `${Utils.endPoints.createW2W} POST Method error ${
            JSON.stringify(data?.message) || data?.error
          }`
        );
        setSubmitting(false);
      }
    );
  };
};

export const updateStateW2w = (id, status, callListingApi) => {
  return (dispatch) => {
    dispatch({
      type: Utils.ActionName.LOADING,
      payload: { loading: true },
    });

    Utils.api.putApiCall(
      Utils.endPoints.updateStatusW2W + id + "?status=" + status,
      // dataToSend,{}
      {},
      (respData) => {
        // setOpen(false);
        Utils.showAlert(1, "Success");
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });
        callListingApi();
        // resetForm({});
        // setOpen(null);
        // dispatch({type:Utils.ActionName.USER_LIST
        //   ,payload:{userlist:respData.data}})
        // navigate("/dashboard");
      },
      (error) => {
        let { data } = error;
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });
        // Utils.showAlert(2, data?.message?.join(",") || data?.error);
        Utils.showAlert(
          2,
          `${Utils.endPoints.updateStatusW2W} PUT Method error ${
            JSON.stringify(data?.message) || data?.error
          }`
        );
        // setSubmitting(true);
      }
    );
  };
};

export const getPdfw2w = (id) => {
  return (dispatch, getState) => {
    const { order, sortField, offset, take, search } =
      getState().warehouseToStoreReducer;
    dispatch({
      type: Utils.ActionName.LOADING,
      payload: { loading: true },
    });
    Utils.api.getApiCall(
      Utils.endPoints.w2wPdf + id,
      "",
      // `?order=${order}&sortField=${sortField}&offset=${offset}&take=${take}&search=${search}`,
      (respData) => {
        const linkSource = `data:application/pdf;base64,${respData.data}`;
        const downloadLink = document.createElement("a");
        const fileName = `tns${id}.pdf`;
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });
        // navigate("/dashboard");
      },
      (error) => {
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });
        let { data } = error;
        Utils.showAlert(
          2,
          `${Utils.endPoints.w2wPdf} GET Method error ${
            JSON.stringify(data?.message) || data?.error
          }`
        );
        // Utils.showAlert(2, data?.message?.join(",") || data?.error);
        // setSubmitting(true);
      }
    );
  };
};
export const updateQuntity = (values, setOpen, id) => {
  return (dispatch) => {
    dispatch({
      type: Utils.ActionName.LOADING,
      payload: { loading: true },
    });
    Utils.api.putApiCall(
      Utils.endPoints.updateQuantityW2w + id + `?quantity=${values?.quantity}`,
      {},
      (respData) => {
        setOpen(false);
        Utils.showAlert(1, "success");
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });
        dispatch(getw2wList());
        // resetForm({});
        // setIsEdit(null)
        // dispatch({type:Utils.ActionName.USER_LIST
        //   ,payload:{userlist:respData.data}})
        // navigate("/dashboard");
      },
      (error) => {
        let { data } = error;
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });
        // Utils.showAlert(2, data?.message?.join(",") || data?.error);
        Utils.showAlert(
          2,
          `${Utils.endPoints.updateQuantityW2w} PUT Method error ${
            JSON.stringify(data?.message) || data?.error
          }`
        );
        // setSubmitting(true);
      }
    );
  };
};
