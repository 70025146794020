import moment from "moment";
import Utils from "../../../utility";

const initialState = {
  POList: [],
  order: "DESC",
  sortField: "createdAt",
  offset: 1,
  take: 10,
  search: "",
  count: 0,
  PODetail: [],
  startDate: moment(),
  endDate: moment(),
  warehouseId: "",
  vender: "",
};
export const warehousePOReducer = (state = initialState, action) => {
  switch (action.type) {
    case `${Utils.ActionName.WAREHOUSE_PO}`:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return { ...state };
  }
};
