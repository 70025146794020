import * as React from "react";
import { useState, useRef } from "react";
import Utils from "../../../utility";
import MenuItem from "@mui/material/MenuItem";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import DeleteConfirmationPopup from "../../../component/modal/deleteModel";
import moment from "moment";
import DailyStockReport from "./dailyStockReport";
import BulkUploadGRN from "./bulkUploadGRN";
import { TextField } from "@mui/material";
import {
  getCurrentStock,
  getStoreStockDataForExport,
  deleteStoreStock,
  getStoreStockList,
  getAllStoreStockList,
} from "./action";
import EditStoreStock from "./editStoreStock";
import { useReactToPrint } from "react-to-print";
import EditIcon from "@mui/icons-material/Edit";
import ImportedButton from "../../../component/button/importedButton";
import PropTypes from "prop-types";
import { alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Button from "@mui/material/Button";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import FilterListIcon from "@mui/icons-material/FilterList";
import { visuallyHidden } from "@mui/utils";
import { useDispatch, useSelector } from "react-redux";
import Stack from "@mui/material/Stack";
import Modal from "../../../component/modal";
import AddUser from "./addstoreStocks";
import Select from "../../../component/select";
// import { getProducts } from "../../productRelated/products/action";
import { getStoreList } from "../store/action";
import { CSVLink } from "react-csv";
import AddButton from "../../../component/button/buttonWithIcon";
import Breadcrumbs from "../../../component/breadcrumb";
import SearchInput from "../../../component/searchInput";

import { getBrandOwnerList } from "../../userAccount/brandOwner.js/action";
import { getUserTypeFromLs } from "../../../utility/commonFunction";
import useDebounce from "../../../hooks/debounce";
import { getGRNById } from "../GRN/action";
const headers = [
  { label: "Product ID", key: "ProductID" },
  { label: "Product Name", key: "ProductName" },
  { label: "barcode", key: "barcode" },
  { label: "Selling price ", key: "sellingPrice" },
  { label: "Total Amount", key: "totalAmount" },
  // { label: "Short Quantity", key: "ShortQuantity" },
  { label: "expiry", key: "expiry" },
  { label: "quantity", key: "quantity" },
  { label: "Store name", key: "storeName" },

  { label: "daysInStock", key: "daysInStock" },
  { label: "printedMrp", key: "printedMrp" },
  // { label: "productCostPrice", key: "productCostPrice" },
  { label: "productEntryId", key: "productEntryId" },
  { label: "vendor Name", key: "vendorName" },
  { label: "DiscountPercent", key: "DiscountPercent" },
  { label: "id", key: "id" },
];
const headCells = [
  {
    id: "id",
    numeric: true,
    disablePadding: true,
    label: "Product Id",
  },

  {
    id: "barcode",
    numeric: false,
    disablePadding: true,
    label: "Barcode",
  },
  {
    id: "warehouseNickname",
    numeric: false,
    disablePadding: true,
    label: "Name",
  },
  // {
  //   id: "subcategory",
  //   numeric: false,
  //   disablePadding: true,
  //   label: "subcategory",
  // },
  {
    id: "quantity",
    numeric: false,
    disablePadding: true,
    label: "quantity",
  },
  {
    id: "Selling",
    numeric: false,
    disablePadding: true,
    label: "Selling Price",
  },
  // {
  //   id: "brand",
  //   numeric: false,
  //   disablePadding: true,
  //   label: "Brand",
  // },
  // {
  //   id: "Category",
  //   numeric: false,
  //   disablePadding: true,
  //   label: "Category",
  // },
  {
    id: "totalAmount",
    numeric: false,
    disablePadding: true,
    label: "Total Amount",
  },
  // {
  //   id: "expiry",
  //   numeric: false,
  //   disablePadding: true,
  //   label: "Expiry",
  // },
  // {
  //   id: "expiryInDay",
  //   numeric: false,
  //   disablePadding: true,
  //   label: "Expiry In Day",
  // },

  {
    id: "action",
    numeric: false,
    disablePadding: true,
    label: "Action",
  },
];

function EnhancedTableHead(props) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => {
          if (headCell.label === "Brand" && getUserTypeFromLs() == "PO")
            return false;
          else
            return (
              <TableCell
                key={headCell.id}
                sortDirection={
                  orderBy === headCell?.id ? order.toLowerCase() : false
                }
              >
                {headCell?.sorting ? (
                  <TableSortLabel
                    active={orderBy === headCell?.id}
                    direction={
                      orderBy === headCell?.id ? order.toLowerCase() : "asc"
                    }
                    onClick={createSortHandler(headCell?.id)}
                  >
                    {headCell.label}
                    {orderBy === headCell?.id ? (
                      <Box component="span" sx={visuallyHidden}>
                        {order === "desc"
                          ? "sorted descending"
                          : "sorted ascending"}
                      </Box>
                    ) : null}
                  </TableSortLabel>
                ) : (
                  headCell.label
                )}
              </TableCell>
            );
        })}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  //onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["ASC", "DESC"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = (props) => {
  const { numSelected } = props;

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
      }}
    >
      <Typography
        sx={{ flex: "1 1 100%" }}
        variant="h6"
        id="tableTitle"
        component="div"
      >
        Store Stock
      </Typography>

      <Tooltip title="Filter list">
        <IconButton>
          <FilterListIcon />
        </IconButton>
      </Tooltip>
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

export default function EnhancedTable() {
  const dispatch = useDispatch();
  const [storeId, setStoreId] = React.useState(null);
  const [open, setOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(null);
  const [deleteConfirmation, setDeleteConfirmation] = useState(null);
  const [cvcData, gets2wList] = useState([]);
  const [grandTotal, setGrandTotal] = useState(0);
  const [openEditSS, setOpenEditSS] = useState(null);
  const [openDailyStock, setOpenDailyStock] = useState(false);
  const [openBulkUpload, setOpenBulkUpload] = useState(false);
  const {
    storeStockList,
    exportData,
    count,
    order,
    sortField,
    offset,
    take,
    printGrnData,
    search,
    grandTotalAddedGRN,
  } = useSelector((state) => state.storeStockReducer);

  const debouncedSearch = useDebounce(search, 500);
  React.useEffect(() => {
    if (storeId) dispatch(getStoreStockList(storeId));
    // else if (
    //   Utils.constants.getUsertypeFromLS() === Utils.constants.UserTypeObj.SA
    // ) {
    //   dispatch(getAllStoreStockList());
    //   // dispatch(getAllStoreStockDataForExport());
    // }
  }, [debouncedSearch]);

  React.useEffect(() => {
    if (exportData?.length) {
      const GRNCVC = exportData.map((item) => {
        return {
          ProductID: item?.product?.id,
          ProductName: item?.product?.name,
          barcode: item?.product?.barcode,
          sellingPrice: item.sellingPrice,
          expiry: item.expiryDate,
          quantity: item.quantity,
          totalAmount: item.quantity * item.sellingPrice,
          storeName: item.store.storename,
          daysInStock: item.daysInStock,
          printedMrp: item.printedMrp,
          // productCostPrice: item.productCostPrice,
          productEntryId: item.productEntryId,
          vendorName: item?.vendor?.alias,
          DiscountPercent: item.DiscountPercent,
          id: item.id,
        };
      });
      gets2wList(GRNCVC);
    }
  }, [exportData]);

  const handleChangePage = (event, newPage) => {
    dispatch({
      type: Utils.ActionName.STORE_STOCK,
      payload: { offset: newPage + 1 },
    });
    if (storeId) dispatch(getStoreStockList(storeId));
    // else dispatch(getAllStoreStockList());
  };

  const handleChangeRowsPerPage = (event) => {
    dispatch({
      type: Utils.ActionName.STORE_STOCK,
      payload: { take: event.target.value, offset: 1 },
    });
    if (storeId) dispatch(getStoreStockList(storeId));
    // else dispatch(getAllStoreStockList());
    // dispatch(getStoreStockList());
  };

  // Avoid a layout jump when reaching the last page with empty rows.

  const handleSearch = (e) => {
    const searchedVal = e.target.value;
    dispatch({
      type: Utils.ActionName.STORE_STOCK,
      payload: { search: searchedVal, take: take, offset: 1 },
    });
  };
  const handleSearchClear = ()=>{
    dispatch({
      type: Utils.ActionName.STORE_STOCK,
      payload: { search: "", take: take, offset: 1 },
    });
  }
  const handleWarehouseSelect = (e) => {
    setStoreId(e.target.value);
    dispatch({
      type: Utils.ActionName.STORE_STOCK,
      payload: { take: take, offset: 1 },
    });
    dispatch(getStoreStockList(e.target.value));
  };
  const { permissions } = useSelector((state) => state.commonReducer);
  // const { warehouseStockList, order, sortField, offset, take, search } =
  //   useSelector((state) => state.warehouseStockReducer);
  // const { storeListForDropdown } = useSelector((state) => state.storeReducer);
  const {
    storeListForDropdown,
    // brandOwnerListForDropdown,

    // brandOwnerListForDropdown,
  } = useSelector((state) => state.commonReducer);
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  return (
    <>
      <Breadcrumbs />
      <Box className="tableContainer">
        <div className="Dflex table-filter-head ">
          <div className="Dflex">
            <Stack sx={{ width: "200px" }}>
              <Select
                handleChange={handleWarehouseSelect}
                value={storeId}
                key={storeId}
                label={"Select Stores"}
                option={
                  storeListForDropdown && storeListForDropdown?.length ? (
                    storeListForDropdown.map((item) => (
                      <MenuItem key={item?.id} value={item?.id}>
                        {item?.storename}
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem>No store</MenuItem>
                  )
                }
                name="store"
              />
            </Stack>

            <Stack direction="row" spacing={2} sx={{ mx: 1, mb: 0.5 }}>
              {/* {getUserTypeFromLs() === "B" ? (
                <></>
              ) : ( */}
              {!permissions || permissions["storeStock"]?.currentStockReport ? (
                <AddButton
                  disabled={!storeId}
                  text="Current Stock Report"
                  className="btn-purple m-l-10 h-100"
                  onPress={() => {
                    dispatch(getCurrentStock(storeId));
                  }}
                />
              ) : (
                <React.Fragment></React.Fragment>
              )}
              {/* )} */}
            </Stack>
          </div>
          <Box className="search_bar ">
            <SearchInput
              value={search}
              placeholder="Search here..."
              handleSearch={handleSearch}
              clearSearch={handleSearchClear}
            />
          </Box>
        </div>

        <TableContainer>
          <Table aria-labelledby="tableTitle">
            <EnhancedTableHead order={order} orderBy={sortField} />
            <TableBody>
              {storeStockList?.length &&
                storeStockList?.map((row, index) => {
                  return (
                    <TableRow hover key={row?.id}>
                      <TableCell component="th" id={index} scope="row">
                        {row?.product?.id}
                      </TableCell>
                      <TableCell align="left">
                        {row?.product?.barcode}
                      </TableCell>
                      <TableCell align="left">{row?.product?.name}</TableCell>
                      <TableCell align="left">{row?.quantity}</TableCell>
                      {/* <TableCell align="left">
                        {row?.product?.category?.subCategoryOf}
                      </TableCell> */}
                      <TableCell align="left"> {row?.sellingPrice}</TableCell>{" "}
                      {/* {getUserTypeFromLs() !== "PO" && (
                        <TableCell align="left">
                          {row?.product?.brands?.brandName || "N/A"}
                        </TableCell>
                      )} */}
                      {/* <TableCell align="left">
                        {row?.product?.category?.categoryName || "N/A"}
                      </TableCell> */}
                      <TableCell align="left">
                        {row?.sellingPrice * row.quantity}
                      </TableCell>
                      {/* <TableCell align="left">
                          {moment(row?.expiryDate)?.format("DD-MM-YYYY")}
                        </TableCell>
                        <TableCell align="left">
                          {moment
                            .duration(moment(row?.expiryDate).diff(moment()))
                            .asDays()
                            .toFixed(2)}{" "}
                          days
                        </TableCell> */}
                      <TableCell>
                        {(!permissions || permissions["storeStock"]?.edit) && (
                          <EditIcon
                            onClick={() => {
                              setOpenEditSS(row);
                            }}
                          />
                        )}
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25]}
          component="div"
          count={
            take === storeStockList?.length
              ? (offset + 1) * take
              : (offset - 1) * take + storeStockList?.length
          }
          rowsPerPage={take}
          page={offset - 1}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Box>
      <Modal
        header={`${isEdit ? "Edit" : "Add"} GRN`}
        children={
          <AddUser
            setOpen={setOpen}
            isEdit={isEdit}
            setIsEdit={setIsEdit}
            storeId={storeId}
            handlePrint={handlePrint}
            grandTotal={grandTotal}
            setGrandTotal={setGrandTotal}
          />
        }
        handleClose={() => {
          setOpen(false);
          setIsEdit(null);
        }}
        open={open}
        minWidth={"95%"}
      />
      {(open || openBulkUpload) && (
        <div
          ref={componentRef}
          style={{
            margin: "10px auto",
            width: "180.874016px",
            // border: "2px solid black",
          }}
        >
          <img
            src="https://thenewshop.in/header-logo.png"
            alt="The new shop"
            style={{ width: "170px" }}
          />
          <div style={{ margin: "0px 15px" }}>
            <div style={{ fontSize: 14, fontWeight: 500 }}>
              <b>GRN Id:</b> {printGrnData?.grnId || printGrnData?.id}
            </div>
            {grandTotalAddedGRN ? (
              <div style={{ fontSize: 14 }}>
                <b>Total Amount:</b>
                {grandTotalAddedGRN}
              </div>
            ) : (
              <React.Fragment></React.Fragment>
            )}

            <div style={{ fontSize: 14, fontWeight: 500 }}>
              <b>Store :</b>
              {(printGrnData?.grnProducts &&
                printGrnData?.grnProducts[0]?.grn?.store?.storename) ||
                printGrnData?.store?.storename}
            </div>
            <div style={{ fontSize: 14, fontWeight: 500 }}>
              <b> GRN By :</b>{" "}
              {(printGrnData?.grnProducts &&
                printGrnData?.grnProducts[0]?.grn?.createdBy?.name) ||
                printGrnData.createdBy?.name}
            </div>
            <div style={{ fontSize: 14, fontWeight: 500 }}>
              <b> Date :</b>
              {(printGrnData?.grnProducts &&
                moment(printGrnData?.grnProducts[0]?.grn?.createdAt).format(
                  "DD-MM-YYYY  HH:mm:ss"
                )) ||
                moment(printGrnData?.createdAt).format("DD-MM-YYYY  HH:mm:ss")}
            </div>
            <div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  fontSize: 14,
                  marginTop: 15,
                }}
              >
                <div>
                  <b>Product</b>
                </div>
                <div>
                  <b>Quantity</b>
                </div>
              </div>

              {printGrnData?.grnProducts?.length &&
                printGrnData?.grnProducts?.map((item, i) => (
                  <div
                    key={i}
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      fontSize: 12,
                      // textAlign:"center",
                    }}
                  >
                    <div style={{ maxWidth: "126px" }}>
                      {item?.product?.name}
                    </div>
                    <div>{item?.recievedQuantity}</div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      )}
      <DeleteConfirmationPopup
        title="Store Stock"
        handleClose={() => setDeleteConfirmation(null)}
        okButtonClick={() => {
          dispatch(deleteStoreStock(deleteConfirmation, storeId));
          setDeleteConfirmation(null);
        }}
        open={deleteConfirmation}
        minWidth={500}
      />
      <Modal
        header={`Edit Store Stock`}
        children={
          <EditStoreStock
            setOpen={setOpenEditSS}
            openEditSS={openEditSS}
            storeId={storeId}
          />
        }
        handleClose={() => {
          setOpenEditSS(false);
          // setIsEdit(null);
        }}
        open={openEditSS}
        minWidth={"85%"}
      />
      <Modal
        header={`Daily Stock Report`}
        children={
          <DailyStockReport
            setOpen={setOpenDailyStock}
            openEditSS={openDailyStock}
          />
        }
        handleClose={() => {
          setOpenDailyStock(false);
          // setIsEdit(null);
        }}
        open={openDailyStock}
        // minWidth={"85%"}
      />
      {/* {console.log(storeId, "storeId")} */}

      {/* <Modal
        header={`Grn bulk upload`}
        children={
          <BulkUploadGRN
            setOpen={setOpenBulkUpload}
            openEditSS={openBulkUpload}
            handlePrint={handlePrint}
          />
        }
        handleClose={() => {
          setOpenBulkUpload(false);
          // setIsEdit(null);
        }}
        open={openBulkUpload}
        // minWidth={"85%"}
      /> */}
    </>
  );
}
