import Utils from "../../../utility";

const initialState = {
  returnList: [],
  count: 0,
  order: "DESC",
  sortField: "name",
  offset: 1,
  take: 10,
  search: "",
  count: 0,
  storeId: "",
};
export const returnReducer = (state = initialState, action) => {
  switch (action.type) {
    case `${Utils.ActionName.RETURNS}`:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return { ...state };
  }
};
