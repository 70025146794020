import moment from "moment/moment";
import Utils from "../../../utility";

const initialState = {
  ordersList: [],
  images: [],
  runnerList: [],
  count: 0,
  order: "DESC",
  sortField: "createdAt",
  offset: 1,
  take: 10,
  search: "",
  filterdOrder: [],
  fromDate: moment().subtract(7, "d"),
  toDate: moment(),
  ordersListStatus: [],
  zomatoData: {},
};
export const onlineOrdersReducer = (state = initialState, action) => {
  switch (action.type) {
    case `${Utils.ActionName.ONLINE_ORDERS}`:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return { ...state };
  }
};
