import {
  getBiller,
  getOM,
  getRunner,
  getWM,
} from "../../../component/layout/action";
import Utils from "../../../utility";
export const getAllUser = (takes) => {
  return (dispatch, getState) => {
    dispatch({
      type: Utils.ActionName.LOADING,
      payload: { loading: true },
    });
    const { order, sortField, offset, take, search } =
      getState().userListReducer;

    Utils.api.getApiCall(
      Utils.endPoints.user,
      `?order=${order}${
        sortField ? `&sortField=${sortField}` : ""
      }&offset=${offset}&take=${takes ? takes : take}&search=${search}`,
      (respData) => {
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });
        dispatch({
          type: Utils.ActionName.USER_LIST,
          payload: {
            userlist: respData.data.users,
            count: respData.data.count,
          },
        });
        // navigate("/dashboard");
      },
      (error) => {
        let { data } = error;
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });
        Utils.showAlert(
          2,
          `${Utils.endPoints.user} GET Method error ${
            JSON.stringify(data?.message) || data?.error
          }`
        );
        // Utils.showAlert(2, data?.message?.join(",") || data?.error);
        // setSubmitting(true);
      }
    );
  };
};
export const getAllUserForDropdown = (takes) => {
  return (dispatch, getState) => {
    dispatch({
      type: Utils.ActionName.LOADING,
      payload: { loading: true },
    });
    const { order, sortField, offset, take, search } =
      getState().userListReducer;

    Utils.api.getApiCall(
      Utils.endPoints.user,
      `?order=${order}&sortField=${sortField}&offset=${offset}&search=${search}`,
      (respData) => {
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });
        dispatch({
          type: Utils.ActionName.COMMON,
          payload: {
            userlistForDropdown: respData.data.users,
          },
        });
        // navigate("/dashboard");
      },
      (error) => {
        let { data } = error;
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });
        Utils.showAlert(
          2,
          `${Utils.endPoints.user} GET Method error ${
            JSON.stringify(data?.message) || data?.error
          }`
        );
        // Utils.showAlert(2, data?.message?.join(",") || data?.error);
        // setSubmitting(true);
      }
    );
  };
};

export const addUser = (values, setOpen, setSubmitting) => {
  return (dispatch) => {
    dispatch({
      type: Utils.ActionName.LOADING,
      payload: { loading: true },
    });
    const {
      userType,
      fullName,
      username,
      password,
      phone,
      email,
      address,
      state,
      city,
      pincode,
      PAN,
      aadhar,
      officeLocation,
      post,
      brandOwner,
      isActive,
      propertyOwner,
      leaveDate,
    } = values;
    const dataToSend = {
      name: fullName,
      userName: username,
      password: password,
      contactEmail: email,
      contactNumber: phone,
      address: address,
      city: city,
      state: state,
      pincode: pincode,
      joinDate: leaveDate,
      isActive,
      userType: userType,
      PAN: PAN,
      aadhar: aadhar,
      officeLocation: officeLocation,
      post,
      isDeleted: false,
    };
    // if (userType === "PO") {
    //   dataToSend.propertyOwnerId = propertyOwner;
    // } else if (userType === "BO") {
    //   dataToSend.brandId = brandOwner;
    // }
    Utils.api.postApiCall(
      Utils.endPoints.createUser,
      dataToSend,
      (respData) => {
        setOpen(false);
        Utils.showAlert(1, "User add successfully");
        dispatch(getAllUser());
        dispatch(getAllUserForDropdown());
        if (userType === "OM") dispatch(getOM());
        if (userType === "R") dispatch(getRunner());
        if (userType === "B") dispatch(getBiller());
        if (userType === "WM") dispatch(getWM());
      },
      (error) => {
        let { data } = error;
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });
        // Utils.showAlert(2, data?.message?.join(",") || data?.error);
        Utils.showAlert(
          2,
          `${Utils.endPoints.createUser} POST Method error ${
            JSON.stringify(data?.message) || data?.error
          }`
        );
        setSubmitting(false);
      }
    );
  };
};

export const updateUser = (values, setOpen, id, setIsEdit, setSubmitting) => {
  return (dispatch) => {
    dispatch({
      type: Utils.ActionName.LOADING,
      payload: { loading: true },
    });
    const {
      userType,
      fullName,
      username,
      // password,
      phone,
      email,
      address,
      state,
      city,
      pincode,
      PAN,
      aadhar,
      officeLocation,
      post,
      brandOwner,
      isActive,
      propertyOwner,
      leaveDate,
    } = values;
    const dataToSend = {
      name: fullName,
      userName: username,
      // password: password,
      contactEmail: email,
      contactNumber: phone,
      address: address,
      city: city,
      state: state,
      pincode: pincode,
      joinDate: leaveDate,
      isActive,
      userType: userType,
      PAN: PAN,
      aadhar: aadhar,
      officeLocation: officeLocation,
      post,
      isDeleted: false,
    };
    if (userType === "PO") {
      dataToSend.propertyOwnerId = propertyOwner;
    } else if (userType === "BO") {
      dataToSend.brandId = brandOwner;
    }
    Utils.api.putApiCall(
      Utils.endPoints.user + "/" + id,
      dataToSend,
      (respData) => {
        setOpen(false);
        Utils.showAlert(1, "User updated successfully");
        dispatch(getAllUser());
        dispatch(getAllUserForDropdown());
        // resetForm({});
        setIsEdit(null);
        // dispatch({type:Utils.ActionName.USER_LIST
        //   ,payload:{userlist:respData.data}})
        // navigate("/dashboard");
      },
      (error) => {
        let { data } = error;
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });
        // Utils.showAlert(2, data?.message?.join(",") || data?.error);
        Utils.showAlert(
          2,
          `update ${Utils.endPoints.user} PUT Method error ${
            JSON.stringify(data?.message) || data?.error
          }`
        );
        setSubmitting(false);
      }
    );
  };
};

export const deleteUser = (id) => {
  return (dispatch) => {
    dispatch({
      type: Utils.ActionName.LOADING,
      payload: { loading: true },
    });
    Utils.api.deleteApiCall(
      Utils.endPoints.user + `/${id}`,
      "",
      (respData) => {
        Utils.showAlert(1, "User delete sucesfuly");

        dispatch(getAllUser());
        dispatch(getAllUserForDropdown());
      },
      (error) => {
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });
        let { data } = error;
        Utils.showAlert(
          2,
          `delete ${Utils.endPoints.user} DELETE Method error ${
            JSON.stringify(data?.message) || data?.error
          }`
        );
        // Utils.showAlert(2, data?.message?.join(",") || data?.error);
        // setSubmitting(true);
      }
    );
  };
};
export const updatePassword = (values, setOpen, id, setIsEdit) => {
  return (dispatch) => {
    dispatch({
      type: Utils.ActionName.LOADING,
      payload: { loading: true },
    });
    const { newPassword } = values;
    const dataToSend = {
      password: newPassword,
    };

    Utils.api.putApiCall(
      Utils.endPoints.user + "/" + localStorage.getItem("id"),
      dataToSend,
      (respData) => {
        setOpen(false);
        Utils.showAlert(1, "successfully");
        dispatch(getAllUser());
        dispatch(getAllUserForDropdown());
        // resetForm({});
        setIsEdit(null);
        // dispatch({type:Utils.ActionName.USER_LIST
        //   ,payload:{userlist:respData.data}})
        // navigate("/dashboard");
      },
      (error) => {
        let { data } = error;
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });
        // Utils.showAlert(2, data?.message?.join(",") || data?.error);
        Utils.showAlert(
          2,
          `update ${Utils.endPoints.user} PUT Method error ${
            JSON.stringify(data?.message) || data?.error
          }`
        );
        // setSubmitting(true);
      }
    );
  };
};
