import Utils from "../../../utility";

export const getBanner = (callback) => {
  return (dispatch, getState) => {
    dispatch({
      type: Utils.ActionName.LOADING,
      payload: { loading: true },
    });
    // const { order, sortField, offset, take, search } = getState().orderReducer;
    Utils.api.getApiCall(
      Utils.endPoints.banners,
      ``,
      (respData) => {
        dispatch({
          type: Utils.ActionName.BANNER,
          payload: {
            bannerList: respData.data,
            // count: respData?.data?.count,
          },
        });
        // callback(respData);
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });
      },
      (error) => {
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });
        let { data } = error;
        Utils.showAlert(
          2,
          `${Utils.endPoints.banners} GET Method error ${
            JSON.stringify(data?.message) || data?.error
          }`
        );
        // Utils.showAlert(2, data?.message?.join(",") || data?.error);
        // setSubmitting(true);
      }
    );
  };
};

export const AddBanner = (values, photoURL, setOpen) => {
  return (dispatch, getState) => {
    dispatch({
      type: Utils.ActionName.LOADING,
      payload: { loading: true },
    });
    // photoURL
    // const { images } = getState().productsReducer;
    const { position, brandId, categoryId } = values;
    var dataToSend = {
      photoURL: photoURL,
      position: position,
      brandId: brandId,
      categoryId: categoryId,
    };
    if (!dataToSend?.brandId) {
      delete dataToSend?.brandId;
    }
    if (!dataToSend?.categoryId) {
      delete dataToSend?.categoryId;
    }
    Utils.api.postApiCall(
      Utils.endPoints.banners,
      dataToSend,
      (respData) => {
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });
        setOpen(false);
        dispatch(getBanner());
        // dispatch({
        //   type: Utils.ActionName.PRODUCTS,
        //   payload: { images: [...images, respData.data.Location] },
        // });
        // setOpen(false);
        Utils.showAlert(1, "Successful");
        // dispatch(getAppVersion());
      },
      (error) => {
        let { data } = error;
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });
        // Utils.showAlert(2, data?.message?.join(",") || data?.error);
        Utils.showAlert(
          2,
          `${Utils.endPoints.banners} POST Method error ${
            JSON.stringify(data?.message) || data?.error
          }`
        );
        // setSubmitting(true);
      }
    );
  };
};

export const updateBanner = (values, photoURL, setOpen, id, setEdit) => {
  return (dispatch, getState) => {
    dispatch({
      type: Utils.ActionName.LOADING,
      payload: { loading: true },
    });
    // photoURL
    // const { images } = getState().productsReducer;
    const { position, brandId, categoryId, typeOfBanner } = values;
    var dataToSend = {
      photoURL: photoURL,
      position: position,
      brandId: typeOfBanner === "brand" ? brandId : null,
      categoryId: typeOfBanner === "category" ? categoryId : null,
    };
    // if (!dataToSend?.brandId) {
    //   delete dataToSend?.brandId;
    // }
    // if (!dataToSend?.categoryId) {
    //   delete dataToSend?.categoryId;
    // }
    Utils.api.putApiCall(
      Utils.endPoints.banners + "/" + id,
      dataToSend,
      (respData) => {
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });
        setOpen(false);
        setEdit(false);
        dispatch(getBanner());
        // dispatch({
        //   type: Utils.ActionName.PRODUCTS,
        //   payload: { images: [...images, respData.data.Location] },
        // });
        // setOpen(false);
        Utils.showAlert(1, "Successful");
        // dispatch(getAppVersion());
      },
      (error) => {
        let { data } = error;
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });
        // Utils.showAlert(2, data?.message?.join(",") || data?.error);

        Utils.showAlert(
          2,
          `update ${Utils.endPoints.banners} PUT Method error ${
            JSON.stringify(data?.message) || data?.error
          }`
        );
        // setSubmitting(true);
      }
    );
  };
};

export const deleteBanner = (id) => {
  return (dispatch, getState) => {
    dispatch({
      type: Utils.ActionName.LOADING,
      payload: { loading: true },
    });

    Utils.api.deleteApiCall(
      Utils.endPoints.banners + "/" + id,
      "",
      (respData) => {
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });
        dispatch(getBanner());

        Utils.showAlert(1, "Successful");
      },
      (error) => {
        let { data } = error;
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });
        // Utils.showAlert(2, data?.message?.join(",") || data?.error);
        Utils.showAlert(
          2,
          `delete ${Utils.endPoints.banners} DELETE Method error ${
            JSON.stringify(data?.message) || data?.error
          }`
        );
        // setSubmitting(true);
      }
    );
  };
};
