import Utils from "../../utility";

export const getOM = () => {
  return (dispatch) => {
    dispatch({
      type: Utils.ActionName.LOADING,
      payload: { loading: true },
    });
    Utils.api.getApiCall(
      Utils.endPoints.user,
      "?order=ASC&offset=1&take=50&sortField=userName&user=OM",
      (respData) => {
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });
        dispatch({
          type: Utils.ActionName.COMMON,
          payload: {
            OM: respData.data?.users,
          },
        });
      },
      (error) => {
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });
        let { data } = error;
        Utils.showAlert(
          2,
          `${Utils.endPoints.user} GET Method error ${
            JSON.stringify(data?.message) || data?.error
          }`
        );
        // Utils.showAlert(2, data?.message?.join(",") || data?.error);
        // setSubmitting(true);
      }
    );
  };
};

export const getRunner = (takes) => {
  return (dispatch, getState) => {
    //   const { order, sortField, offset, take, search } = getState().storeReducer;
    //   dispatch({
    //     type: Utils.ActionName.LOADING,
    //     payload: { loading: true },
    //   });
    Utils.api.getApiCall(
      Utils.endPoints.user,
      "?order=ASC&offset=1&sortField=userName&user=R",
      (respData) => {
        //   dispatch({
        //     type: Utils.ActionName.LOADING,
        //     payload: { loading: false },
        //   });
        dispatch({
          type: Utils.ActionName.COMMON,
          payload: {
            runner: respData.data?.users,
          },
        });
      },
      (error) => {
        //   dispatch({
        //     type: Utils.ActionName.LOADING,
        //     payload: { loading: false },
        //   });
        let { data } = error;
        Utils.showAlert(
          2,
          `${Utils.endPoints.user} GET Method error ${
            JSON.stringify(data?.message) || data?.error
          }`
        );
        // Utils.showAlert(2, data?.message?.join(",") || data?.error);
        // setSubmitting(true);
      }
    );
  };
};

export const getBiller = (takes) => {
  return (dispatch, getState) => {
    //   const { order, sortField, offset, take, search } = getState().storeReducer;
    //   dispatch({
    //     type: Utils.ActionName.LOADING,
    //     payload: { loading: true },
    //   });
    Utils.api.getApiCall(
      Utils.endPoints.user,
      "?order=ASC&offset=1&sortField=userName&user=B",
      (respData) => {
        //   dispatch({
        //     type: Utils.ActionName.LOADING,
        //     payload: { loading: false },
        //   });
        dispatch({
          type: Utils.ActionName.COMMON,
          payload: {
            biller: respData.data?.users,
          },
        });
      },
      (error) => {
        //   dispatch({
        //     type: Utils.ActionName.LOADING,
        //     payload: { loading: false },
        //   });
        let { data } = error;
        Utils.showAlert(
          2,
          `${Utils.endPoints.user} GET Method error ${
            JSON.stringify(data?.message) || data?.error
          }`
        );
        // Utils.showAlert(2, data?.message?.join(",") || data?.error);
        // setSubmitting(true);
      }
    );
  };
};

export const getWM = (takes) => {
  return (dispatch, getState) => {
    //   const { order, sortField, offset, take, search } = getState().storeReducer;
    //   dispatch({
    //     type: Utils.ActionName.LOADING,
    //     payload: { loading: true },
    //   });
    Utils.api.getApiCall(
      Utils.endPoints.user,
      "?order=ASC&offset=1&take=50&sortField=userName&user=WM",
      (respData) => {
        //   dispatch({
        //     type: Utils.ActionName.LOADING,
        //     payload: { loading: false },
        //   });
        dispatch({
          type: Utils.ActionName.COMMON,
          payload: {
            WM: respData.data?.users,
          },
        });
      },
      (error) => {
        //   dispatch({
        //     type: Utils.ActionName.LOADING,
        //     payload: { loading: false },
        //   });
        let { data } = error;
        Utils.showAlert(
          2,
          `${Utils.endPoints.user} GET Method error ${
            JSON.stringify(data?.message) || data?.error
          }`
        );
        // Utils.showAlert(2, data?.message?.join(",") || data?.error);
        // setSubmitting(true);
      }
    );
  };
};

export const changeUserPassword = (value, setOpen) => {
  return (dispatch, getState) => {
    //   const { order, sortField, offset, take, search } = getState().storeReducer;
    dispatch({
      type: Utils.ActionName.LOADING,
      payload: { loading: true },
    });
    Utils.api.putApiCall(
      Utils.endPoints.changeUserPassword + value?.userId,
      {
        password: value?.confirmPassword,
      },
      (respData) => {
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });
        setOpen(false);
        Utils.showAlert(1, "Password updated sucessfully");
      },
      (error) => {
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });
        let { data } = error;
        Utils.showAlert(
          2,
          `${Utils.endPoints.changeUserPassword} PUT Method error ${
            JSON.stringify(data?.message) || data?.error
          }`
        );
        // Utils.showAlert(2, data?.message?.join(",") || data?.error);
        // setSubmitting(true);
      }
    );
  };
};
