import React from "react";
import Button from "@mui/material/Button";
import { fileUpload } from "../../productRelated/products/action";
import UploadImage from "../../../component/uploadImage";

import MenuItem from "@mui/material/MenuItem";
// import DatePicker from "../../../component/date";
import DeleteIcon from "@mui/icons-material/Delete";
import AutoComplete from "../../../component/input/autocomplete";
import { useDispatch, useSelector } from "react-redux";
import { Formik, Form, FieldArray } from "formik";
import AddBoxTwoToneIcon from "@mui/icons-material/AddBoxTwoTone";
import IconButton from "@mui/material/IconButton";
import { getPOById } from "../warehousePo/action";
import { createWarehouseeGRN } from "./action";
import Input from "../../../component/input";
import { Box } from "@mui/material";

import Select from "../../../component/select";
const AddUser = ({
  setOpen,
  isEdit,
  grandTotal,
  setGrandTotal,
  warehouseId,
  handlePrint,
}) => {
  const dispatch = useDispatch();
  // const { productsDropDownList } = useSelector((state) => state.productsReducer);
  // const [grandTotal, setGrandTotal] = useState(0);

  const calculateGrandTotal = (values, index, currentValue, currentField) => {
    const grandTotal = values.reduce((total, item, i) => {
      if (i === index) {
        if (currentField === "costPrice")
          total = +currentValue * values[index].recievedQuantity + total;
        else total = +currentValue * values[index].costPrice + total;
      } else {
        total = item.total + total;
      }

      return total;
    }, 0);
    setGrandTotal(grandTotal);
  };
  const initialValues = {
    product: isEdit?.length ? isEdit[0]?.product : [],
    warehouse: +warehouseId,
    invoice: isEdit?.length ? isEdit[0]?.invoice : "",
    PONumber: null,
    vehicle: null,
    vendor: isEdit?.length ? isEdit[0]?.warehouseState : "",
  };
  const {
    // warehouseListForDropdown,
    brandOwnerListForDropdown,
    productsDropDownList,
    // brandOwnerListForDropdown,
  } = useSelector((state) => state.commonReducer);

  // const { brandOwnerListForDropdown } = useSelector((state) => state.brandOwnerReducer);
  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      // validationSchema={Schema.warehouseStockSchema}
      onSubmit={(values, { setSubmitting, resetForm }) => {
        dispatch(
          createWarehouseeGRN(
            values,
            setOpen,
            warehouseId,
            handlePrint,
            setSubmitting
          )
        );
      }}
    >
      {({
        values,
        errors,
        touched,
        setFieldValue,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        /* and other goodies */
      }) => (
        <Form onSubmit={handleSubmit}>
          <Box sx={{ display: "flex", mb: 2, alignItems: "center" }}>
            <Select
              handleChange={handleChange}
              value={values.vendor}
              label={"vendor Id"}
              option={brandOwnerListForDropdown.map((item) => (
                <MenuItem value={item?.id} key={item?.id}>
                  {item?.alias}
                </MenuItem>
              ))}
              name="vendor"
              error={errors.vendor}
            />
            <UploadImage
              handleChange={
                (e) =>
                  dispatch(
                    fileUpload(e.target.files[0], (res) => {
                      setFieldValue("invoice", res.Location);
                    })
                  )
                // handleChanegImage(e, setFieldValue, "invoice")
              }
              lable="Invoice"
              image={values?.invoice}
              id="invoice"
              onDelete={() => setFieldValue("invoice", "")}
              // onEdit = {}
            />
            <Input
              value={values.PONumber}
              name="PONumber"
              type="text"
              error={errors.PONumber}
              label="PO Number"
              onChange={(e) => {
                setFieldValue("PONumber", e.target.value);
              }}
              handleBlur={() => {
                dispatch(
                  getPOById(values.PONumber, warehouseId, (res) => {
                    setFieldValue(
                      "product",
                      res?.poProducts?.map((item) => {
                        return {
                          product: item.product.id,
                          invoiceQuantity: item.requiredQuantity,
                          recievedQuantity: null,
                          mrp: item?.product?.productMrp,
                          total: 0,
                          costPrice: item?.productCostPrice,
                          sellingPrice: item?.productSellingPrice,
                          batch: "",
                        };
                      })
                    );
                    setFieldValue("vendor", res?.vendor?.id);
                  })
                );
              }}
            />
            <Input
              value={values.vehicle}
              name="vehicle"
              type="text"
              error={errors.vehicle}
              label="Vehicle"
              onChange={handleChange}
              handleBlur={handleBlur}
            />
          </Box>
          <FieldArray
            name="product"
            render={(arrayHelpers) => {
              // console.log(errors[index])
              return (
                <div>
                  {values.product && values.product.length > 0
                    ? values.product.map((val, index) => (
                        <Box sx={{ display: "flex", mb: 2 }} key={index}>
                          <AutoComplete
                            freeSolo={false}
                            values={values.product[index].product}
                            onChange={(event, value) => {
                              if (value !== null) {
                                setFieldValue(
                                  `product.${index}.product`,
                                  value?.id
                                );
                              }
                            }}
                            helperText={
                              touched?.product &&
                              errors?.product &&
                              errors?.product[index]?.product
                            }
                            error={Boolean(
                              touched?.product &&
                                errors?.product &&
                                errors?.product[index]?.product
                            )}
                            name={`product.${index}.product`}
                            label={"Product Id"}
                            // touched={touched.product}
                            options={productsDropDownList}
                            getOptionLabel={(option) =>
                              `${option?.barcode} | ${option.name}`
                            }
                            // isRequired={true}
                            getOptionSelected={(option, value) => {
                              return `${option.id}` === `${value.id}`;
                            }}
                            defaultValue={() => {
                              const pIndex = productsDropDownList.findIndex(
                                (x) => x?.id === values.product[index].product
                              );
                              return productsDropDownList[pIndex];
                            }}
                          />
                          <Input
                            value={values.product[index].invoiceQuantity}
                            name={`product.${index}.invoiceQuantity`}
                            type="number"
                            error={
                              errors.product &&
                              errors.product[index]?.invoiceQuantity
                            }
                            // error={errors.product}
                            label="Inv. Quantity"
                            onChange={handleChange}
                            handleBlur={handleBlur}
                          />
                          <Input
                            value={values.product[index].recievedQuantity}
                            name={`product.${index}.recievedQuantity`}
                            type="number"
                            error={
                              errors.product &&
                              errors.product[index]?.recievedQuantity
                            }
                            label="Recd. Quantity"
                            onChange={(e) => {
                              if (
                                e.target.value >
                                values.product[index].invoiceQuantity
                              ) {
                                return;
                              }
                              handleChange(e);
                              setFieldValue(
                                `product.${index}.total`,
                                +e.target.value *
                                  values.product[index].costPrice
                              );
                              calculateGrandTotal(
                                values.product,
                                index,
                                e.target.value,
                                "recievedQuantity"
                              );
                            }}
                            handleBlur={handleBlur}
                          />
                          <Input
                            value={values.product[index].mrp}
                            name={`product.${index}.mrp`}
                            type="number"
                            // error={errors.product}
                            error={errors.product && errors.product[index]?.mrp}
                            label="mrp"
                            onChange={handleChange}
                            handleBlur={handleBlur}
                          />
                          <Input
                            value={values.product[index].sellingPrice}
                            name={`product.${index}.sellingPrice`}
                            type="number"
                            // disabled={true}
                            error={
                              errors.product &&
                              errors.product[index]?.sellingPrice
                            }
                            // error={errors.product}
                            label="sellingPrice"
                            onChange={handleChange}
                            handleBlur={handleBlur}
                          />
                          {/* <DatePicker
                            value={values.product[index].batch}
                            name={`product.${index}.batch`}
                            handleChange={(newVal) =>
                              setFieldValue(`product.${index}.batch`, newVal)
                            }
                            label="Batch"
                            error={
                              errors.product && errors.product[index]?.batch
                            }
                          /> */}
                          <Input
                            value={values.product[index].costPrice}
                            name={`product.${index}.costPrice`}
                            type="number"
                            error={
                              errors.product && errors.product[index]?.costPrice
                            }
                            // error={errors.product}
                            label="costPrice"
                            onChange={(e) => {
                              handleChange(e);
                              setFieldValue(
                                `product.${index}.total`,
                                +e.target.value *
                                  values.product[index].recievedQuantity
                              );

                              calculateGrandTotal(
                                values.product,
                                index,
                                e.target.value,
                                "costPrice"
                              );
                            }}
                            handleBlur={handleBlur}
                          />
                          <Input
                            value={values.product[index].total}
                            name={`product.${index}.total`}
                            type="number"
                            disabled={true}
                            error={
                              errors.product && errors.product[index]?.total
                            }
                            label="Total"
                            onChange={handleChange}
                            handleBlur={handleBlur}
                          />
                          <IconButton
                            style={{
                              background: "rgb(231 112 112)",
                              color: "white",
                              width: 35,
                              height: 35,
                              borderRadius: 5,
                            }}
                            aria-label="delete"
                            size="small"
                            onClick={() => arrayHelpers.remove(index)}
                          >
                            <DeleteIcon fontSize="inherit" />
                          </IconButton>
                        </Box>
                      ))
                    : null}
                  <Box sx={{ display: "flex", mb: 2 }}>
                    <Box
                      sx={{
                        width: "100%",
                        pr: 2,
                        maxWidth: "100%",
                        textAlign: "right",
                      }}
                    >
                      Grand Total
                    </Box>

                    <Input
                      value={grandTotal}
                      disabled
                      name="grandTotal"
                      type="number"
                      label="Grand Total "
                    />

                    <IconButton
                      style={{
                        background: "green",
                        color: "white",
                        width: 35,
                        height: 35,
                        borderRadius: 5,
                      }}
                      aria-label="delete"
                      size="small"
                      onClick={() =>
                        arrayHelpers.push({
                          product: null,
                          invoiceQuantity: null,
                          recievedQuantity: null,
                          mrp: null,
                          total: 0,
                          // landingPrice: null,
                          costPrice: 0,
                          sellingPrice: 0,
                          batch: "",
                        })
                      }
                    >
                      <AddBoxTwoToneIcon fontSize="inherit" />
                    </IconButton>
                  </Box>
                </div>
              );
            }}
          />

          <Box
            sx={{ display: "flex", mt: 2, mb: 2, justifyContent: "flex-end" }}
          >
            <Button variant="outlined" type="submit" disabled={isSubmitting}>
              {isEdit?.length ? "update Wrehouse GRN" : "Add Wrehouse GRN"}
            </Button>
            <Button
              variant="outlined"
              style={{
                border: "1px solid #e13030",
                color: "#e13030",
                marginLeft: 10,
              }}
              onClick={() => setOpen(false)}
            >
              Cancel
            </Button>
          </Box>
        </Form>
      )}
    </Formik>
  );
};

export default AddUser;
