import React from "react";
import Button from "@mui/material/Button";
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import AddBoxTwoToneIcon from "@mui/icons-material/AddBoxTwoTone";
import MenuItem from "@mui/material/MenuItem";
import Schema from "../../../schema";
import AutoComplete from "../../../component/input/autocomplete";
import { useDispatch, useSelector } from "react-redux";
import { Formik, Form, FieldArray } from "formik";
import { makeATransferW2W } from "./action";
import Input from "../../../component/input";
import { Box } from "@mui/material";

import Select from "../../../component/select";
const AddUser = ({ setOpen }) => {
  const dispatch = useDispatch();
  // const { productsDropDownList } = useSelector((state) => state.productsReducer);
  const { warehouseList } = useSelector((state) => state.warehouseReducer);
  const {
    // storeListForDropdown,
    productsDropDownList,
    warehouseListForDropdown,
  } = useSelector((state) => state.commonReducer);
  const initialValues = {
    stocks: [
      {
        product: null,
        quantity: null,
      },
    ],
    warehouseTo: null,
    warehouse: null,
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={Schema.makeStockTransferW2W}
      onSubmit={(values, { setSubmitting, resetForm }) => {
        dispatch(makeATransferW2W(values, setOpen, setSubmitting));
      }}
    >
      {({
        values,
        errors,
        touched,
        setFieldValue,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
      }) => (
        <Form onSubmit={handleSubmit}>
          <Box sx={{ display: "flex", mb: 2 }}>
            <Select
              handleChange={handleChange}
              value={values.warehouseTo}
              label={"Select Warehouse"}
              option={warehouseListForDropdown.map((item) => (
                <MenuItem key={item.id} value={item?.id}>
                  {item?.warehouseNickname}
                </MenuItem>
              ))}
              name="warehouseTo"
              error={errors.warehouseTo}
            />
            <Select
              handleChange={handleChange}
              value={values.warehouse}
              label={"Select Warehouse"}
              option={warehouseListForDropdown.map((item) => (
                <MenuItem key={item.id} value={item?.id}>
                  {item?.warehouseNickname}
                </MenuItem>
              ))}
              name="warehouse"
              error={errors.warehouse}
            />
          </Box>

          <FieldArray
            name="stocks"
            render={(arrayHelpers) => {
              return (
                <div>
                  {values.stocks && values.stocks.length > 0
                    ? values.stocks.map((val, index) => (
                        <Box sx={{ display: "flex", mb: 2 }} key={index}>
                          <React.Fragment key={values?.stocks[index].product}>
                            <AutoComplete
                              freeSolo={false}
                              values={values?.stocks[index].product}
                              onChange={(event, value) => {
                                if (value !== null) {
                                  const pIndex = productsDropDownList.findIndex(
                                    (x) => x.id === value.id
                                  );
                                  setFieldValue(
                                    `stocks.${index}.product`,
                                    value?.id
                                  );
                                }
                              }}
                              helperText={
                                touched.product &&
                                errors?.stocks[index]?.product
                              }
                              error={Boolean(
                                touched.product &&
                                  errors?.stocks[index]?.product
                              )}
                              name={`stocks.${index}.product`}
                              label={"Product Id"}
                              // touched={touched.product}
                              options={productsDropDownList}
                              getOptionLabel={(option) =>
                                `${option?.barcode} | ${option.name}`
                              }
                              getOptionSelected={(option, value) => {
                                return `${option.id}` === `${value.id}`;
                              }}
                              defaultValue={() => {
                                const pIndex = productsDropDownList.findIndex(
                                  (x) => x?.id === values.stocks[index].product
                                );
                                return productsDropDownList[pIndex];
                              }}
                            />
                          </React.Fragment>
                          <Input
                            value={values.stocks[index].quantity}
                            name={`stocks.${index}.quantity`}
                            type="number"
                            error={
                              errors.stocks && errors.stocks[index]?.quantity
                            }
                            // error={errors.product}
                            label="Inv. Quantity"
                            onChange={handleChange}
                            handleBlur={handleBlur}
                          />

                          <IconButton
                            style={{
                              background: "rgb(231 112 112)",
                              color: "white",
                              width: 35,
                              height: 35,
                              borderRadius: 5,
                            }}
                            aria-label="delete"
                            size="small"
                            // color= "primary"
                            onClick={() => arrayHelpers.remove(index)}
                          >
                            <DeleteIcon fontSize="inherit" />
                          </IconButton>

                          <IconButton
                            style={{
                              background: "green",
                              color: "white",
                              width: 35,
                              height: 35,
                              marginLeft: 5,
                              borderRadius: 5,
                            }}
                            aria-label="delete"
                            size="small"
                            // color= "primary"
                            onClick={() =>
                              arrayHelpers.push({
                                barcode: null,
                                qunatity: null,
                              })
                            }
                          >
                            <AddBoxTwoToneIcon fontSize="inherit" />
                          </IconButton>
                        </Box>
                      ))
                    : null}
                </div>
              );
            }}
          />

          <Box sx={{ display: "flex", mb: 2, justifyContent: "flex-end" }}>
            <Button variant="outlined" type="submit">
              Submit
            </Button>
            <Button
              variant="outlined"
              style={{
                border: "1px solid #e13030",
                color: "#e13030",
                marginLeft: 10,
              }}
              onClick={() => setOpen(false)}
            >
              Cancel
            </Button>
          </Box>
        </Form>
      )}
    </Formik>
  );
};

export default AddUser;
