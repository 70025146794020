import * as React from "react";
import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";

export default function TableSkeleton() {
  return (
    <Box sx={{ width: "100%" }}>
      {/* <Skeleton /> */}
      {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((item) => (
        <Skeleton key={item} height={40} animation="wave" />
      ))}
    </Box>
  );
}
