import Utils from "../../../utility";

export const updatePropertyOwner = (
  values,
  setOpen,
  id,
  resetForm,
  setIsEdit
) => {
  return (dispatch) => {
    const {
      store,
      propertyOwner,
      bankAccountNumber,
      bankIFSC,
      bankBranch,
      bankName,
      CIN,
      GST,
    } = values;
    const dataToSend = {
      stores: store,
      userId: propertyOwner,
      bankAccountNumber,
      bankIFSC,
      bankBranch,
      bankName,
      CIN,
      GST,
      isDeleted: false,
    };
    dispatch({
      type: Utils.ActionName.LOADING,
      payload: { loading: true },
    });
    Utils.api.putApiCall(
      Utils.endPoints.updatePropertyOwner + id,
      dataToSend,
      (respData) => {
        setOpen(false);
        Utils.showAlert(1, "Property Owner updated successfully");
        // dispatch(getAllUser());
        resetForm({});
        setIsEdit(null);
        dispatch(getPropertyOwner());
        dispatch(getPropertyOwnerForDropdown());
        // dispatch({type:Utils.ActionName.USER_LIST
        //   ,payload:{userlist:respData.data}})
        // navigate("/dashboard");
      },
      (error) => {
        let { data } = error;
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });
        // Utils.showAlert(2, data?.message?.join(",") || data?.error);
        Utils.showAlert(
          2,
          `update ${Utils.endPoints.updatePropertyOwner} PUT Method error ${
            JSON.stringify(data?.message) || data?.error
          }`
        );
        // setSubmitting(true);
      }
    );
  };
};
export const createPropertyOwner = (values, setOpen) => {
  return (dispatch) => {
    const {
      store,
      propertyOwner,
      bankAccountNumber,
      bankIFSC,
      bankBranch,
      bankName,
      CIN,
      GST,
    } = values;
    const dataToSend = {
      stores: store,
      userId: propertyOwner,
      bankAccountNumber: bankAccountNumber,
      bankIFSC,
      bankBranch,
      bankName,
      CIN,
      GST,
      isDeleted: false,
    };
    dispatch({
      type: Utils.ActionName.LOADING,
      payload: { loading: true },
    });
    Utils.api.postApiCall(
      Utils.endPoints.addPropertyOwner,
      dataToSend,
      (respData) => {
        setOpen(false);
        Utils.showAlert(1, "Property Owner added successfully");
        dispatch(getPropertyOwner());
        dispatch(getPropertyOwnerForDropdown());
        // resetForm({});
        // setIsEdit(null)
        // dispatch({type:Utils.ActionName.USER_LIST
        //   ,payload:{userlist:respData.data}})
        // navigate("/dashboard");
      },
      (error) => {
        let { data } = error;
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });
        // Utils.showAlert(2, data?.message?.join(",") || data?.error);
        Utils.showAlert(
          2,
          `${Utils.endPoints.addPropertyOwner} POST Method error ${
            JSON.stringify(data?.message) || data?.error
          }`
        );
        // setSubmitting(true);
      }
    );
  };
};

export const deletePropertyOwner = (id) => {
  return (dispatch) => {
    dispatch({
      type: Utils.ActionName.LOADING,
      payload: { loading: true },
    });
    Utils.api.deleteApiCall(
      Utils.endPoints.propertyOwner + `/${id}`,
      "",
      (respData) => {
        Utils.showAlert(1, "Property Owner delete sucesfuly");
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });
      },
      (error) => {
        let { data } = error;
        Utils.showAlert(
          2,
          `delete ${Utils.endPoints.propertyOwner} DELETE Method error ${
            JSON.stringify(data?.message) || data?.error
          }`
        );
        // Utils.showAlert(2, data?.message?.join(",") || data?.error);
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });
        // setSubmitting(true);
      }
    );
  };
};

export const getPropertyOwner = () => {
  return (dispatch, getState) => {
    const { order, sortField, offset, take, search } =
      getState().propertyOwnerReducer;

    dispatch({
      type: Utils.ActionName.LOADING,
      payload: { loading: true },
    });
    Utils.api.getApiCall(
      Utils.endPoints.propertyOwner,
      `/?order=${order}&sortField=${sortField}&offset=${offset}&take=${take}&search=${search}`,
      (respData) => {
        dispatch({
          type: Utils.ActionName.PROPERTY_OWNER,
          payload: {
            propertyOwnerList: respData.data?.propertyOwners,
            count: respData.data.count,
          },
        });
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });
        // navigate("/dashboard");
      },
      (error) => {
        let { data } = error;
        Utils.showAlert(
          2,
          `${Utils.endPoints.propertyOwner} GET Method error ${
            JSON.stringify(data?.message) || data?.error
          }`
        );
        // Utils.showAlert(2, data?.message?.join(",") || data?.error);
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });
        // setSubmitting(true);
      }
    );
  };
};
export const getPropertyOwnerDetail = (id, response) => {
  return (dispatch, getState) => {
    const { order, sortField, offset, take, search } =
      getState().propertyOwnerReducer;

    dispatch({
      type: Utils.ActionName.LOADING,
      payload: { loading: true },
    });
    Utils.api.getApiCall(
      Utils.endPoints.propertyOwner,
      `/${id}`,
      (respData) => {
        response(respData?.data);
        // navigate("/dashboard");
      },
      (error) => {
        let { data } = error;
        Utils.showAlert(
          2,
          `${Utils.endPoints.propertyOwner} GET Method error ${
            JSON.stringify(data?.message) || data?.error
          }`
        );
        // Utils.showAlert(2, data?.message?.join(",") || data?.error);
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });
        // setSubmitting(true);
      }
    );
  };
};

export const getPropertyOwnerForDropdown = () => {
  return (dispatch, getState) => {
    const { order, sortField, offset, take, search } =
      getState().propertyOwnerReducer;

    dispatch({
      type: Utils.ActionName.LOADING,
      payload: { loading: true },
    });
    Utils.api.getApiCall(
      Utils.endPoints.propertyOwner,
      `/?order=${order}&sortField=${sortField}&offset=${offset}&search=${search}`,
      (respData) => {
        dispatch({
          type: Utils.ActionName.COMMON,
          payload: {
            propertyOwnerListForDropdown: respData.data?.propertyOwners,
          },
        });
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });
        // navigate("/dashboard");
      },
      (error) => {
        let { data } = error;
        Utils.showAlert(
          2,
          `${Utils.endPoints.propertyOwner} GET Method error ${
            JSON.stringify(data?.message) || data?.error
          }`
        );
        // Utils.showAlert(2, data?.message?.join(",") || data?.error);
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });
        // setSubmitting(true);
      }
    );
  };
};
