import { Navigate } from "react-router-dom";
import Utils from "../utility";
import { getUserTypeFromLs } from "../utility/commonFunction";
// import PrivateContainer from "../templates/PrivateContainer";
// import Utils from "../utils";

const PublicAuth = ({ children }) => {
  const token = localStorage.getItem("accessToken");
  if (token?.length > 10) {
    if (["B", "PO"].includes(getUserTypeFromLs())) {
      // navigate("/store");
      return <Navigate to="/store" />;
    } else return <Navigate to="/dashboard" />;
  }
  return <>{children}</>;
};

export default PublicAuth;
