import Utils from "../../../utility";
import moment from "moment";
export const getPOkList = (value = {}) => {
  return (dispatch, getState) => {
    const {
      order,
      sortField,
      offset,
      take,
      search,
      startDate,
      endDate,
      warehouseId,
      vender,
    } = getState().warehousePOReducer;
    // const {} = value;
    dispatch({
      type: Utils.ActionName.LOADING,
      payload: { loading: true },
    });
    Utils.api.getApiCall(
      Utils.endPoints.poWarehouse,
      `?order=${order}&offset=${offset}&take=${take}&startDate=${
        startDate ? moment(startDate).format("YYYY-MM-DD HH:mm:ss") : ""
      }&endDate=${
        endDate ? moment(endDate).format("YYYY-MM-DD HH:mm:ss") : ""
      }&warehouse=${warehouseId ? warehouseId : ""}&vendor=${
        vender ? vender : ""
      }&sortField=${sortField ? sortField : ""}&search=${search}`,
      (respData) => {
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });
        dispatch({
          type: Utils.ActionName.WAREHOUSE_PO,
          payload: {
            POList: respData.data.purchaseOrders,
            count: respData.data.count,
          },
        });
        // navigate("/dashboard");
      },
      (error) => {
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });
        let { data } = error;
        Utils.showAlert(
          2,
          `${Utils.endPoints.poWarehouse} GET Method error ${
            JSON.stringify(data?.message) || data?.error
          }`
        );
        // Utils.showAlert(2, data?.message?.join(",") || data?.error);
      }
    );
  };
};

export const getPOById = (id, warehouseId, callback = () => {}) => {
  return (dispatch, getState) => {
    // const { order, sortField, offset, take, search } =
    //   getState().warehouseReducer;
    dispatch({
      type: Utils.ActionName.LOADING,
      payload: { loading: true },
    });
    Utils.api.postApiCall(
      Utils.endPoints.sigleWarehousePo +
        id +
        `?warehouseId=${warehouseId ? warehouseId : ""}`,
      {},
      (respData) => {
        callback(respData.data?.purchaseOrder);
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });
        dispatch({
          type: Utils.ActionName.WAREHOUSE_PO,
          payload: {
            PODetail: respData.data?.purchaseOrder,
            // count: respData.data.count,
          },
        });
        // navigate("/dashboard");
      },
      (error) => {
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });
        let { data } = error;
        Utils.showAlert(
          2,
          `${Utils.endPoints.sigleWarehousePo} POST Method error ${
            JSON.stringify(data?.message) || data?.error
          }`
        );
        // Utils.showAlert(2, data?.message?.join(",") || data?.error);
        // setSubmitting(true);
      }
    );
  };
};

// export const updateStoreStock = (values, setOpen, id, resetForm, setIsEdit) => {
//   return (dispatch) => {
//     dispatch({
//       type: Utils.ActionName.LOADING,
//       payload: { loading: true },
//     });

//     const dataToSend = values;
//     dataToSend.warehousePincode = +values.warehousePincode;
//     dataToSend.warehouseGSTIN = +values.warehouseGSTIN;

//     // dataToSend.FSSAI=values.FSSAI

//     Utils.api.putApiCall(
//       Utils.endPoints.storeStock + id,
//       dataToSend,
//       (respData) => {
//         setOpen(false);
//         Utils.showAlert(1, "Store stock updated successfully");
//         // dispatch(getAllUser());
//         resetForm({});
//         setIsEdit(null);
//         // dispatch(getStoreStockList());
//         // dispatch({type:Utils.ActionName.USER_LIST
//         //   ,payload:{userlist:respData.data}})
//         // navigate("/dashboard");
//       },
//       (error) => {
//         let { data } = error;
//         dispatch({
//           type: Utils.ActionName.LOADING,
//           payload: { loading: false },
//         });
//         Utils.showAlert(2, data?.message?.join(",") || data?.error);

//         // setSubmitting(true);
//       }
//     );
//   };
// };
export const createPO = (values, setOpen, storeId, setSubmitting) => {
  return (dispatch) => {
    dispatch({
      type: Utils.ActionName.LOADING,
      payload: { loading: true },
    });

    const dataToSend = {
      product: values.product.map((item) => {
        return {
          product: item.product,
          quantity: item.quantity,
          productSellingPrice: item.productSellingPrice,
          productCostPrice: item.productCostPrice,
        };
      }),
      warehouse: values.warehouseId,
      vendor: values.vendor,
      terms: values.terms,
      deliveryDate: values.deliveryDate,
      expiryDate: values.deliveryDate,
      type: values.type,
    };

    Utils.api.postApiCall(
      Utils.endPoints.createPo,
      dataToSend,
      (respData) => {
        setOpen(false);
        Utils.showAlert(1, "PO added successfully");
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });
        dispatch(getPOkList(storeId));
        // resetForm({});
        // setIsEdit(null)
        // dispatch({type:Utils.ActionName.USER_LIST
        //   ,payload:{userlist:respData.data}})
        // navigate("/dashboard");
      },
      (error) => {
        let { data } = error;
        dispatch({
          type: Utils.ActionName.LOADING,
          payload: { loading: false },
        });
        Utils.showAlert(
          2,
          `${Utils.endPoints.createPo} POST Method error ${
            JSON.stringify(data?.message) || data?.error
          }`
        );
        // Utils.showAlert(2, data?.message?.join(",") || data?.error);
        setSubmitting(false);
      }
    );
  };
};

// export const deleteStoreStock = (id, storeId) => {
//   return (dispatch) => {
//     dispatch({
//       type: Utils.ActionName.LOADING,
//       payload: { loading: true },
//     });
//     Utils.api.deleteApiCall(
//       Utils.endPoints.storeStock + `/${id}`,
//       "",
//       (respData) => {
//         Utils.showAlert(1, "Warehouse stock delete sucesfuly");
//         dispatch(getPOkList(storeId));
//         // dispatch({type:Utils.ActionName.USER_LIST
//         //   ,payload:{userlist:respData.data}})
//         // navigate("/dashboard");
//       },
//       (error) => {
//         let { data } = error;
//         dispatch({
//           type: Utils.ActionName.LOADING,
//           payload: { loading: false },
//         });
//         Utils.showAlert(2, data?.message?.join(",") || data?.error);
//         // setSubmitting(true);
//       }
//     );
//   };
// };

// export const getBrandList = () => {
//   return (dispatch) => {
//     // const { email, password } = values;
//     // const dataToSend = {
//     //   username:email,
//     //   password,
//     // };
//     Utils.api.getApiCall(
//       Utils.endPoints.brands,
//       "",
//       (respData) => {
//         dispatch({
//           type: Utils.ActionName.USER_LIST,
//           payload: { brands: respData.data },
//         });
//         // navigate("/dashboard");
//       },
//       (error) => {
//         let { data } = error;
//         Utils.showAlert(2, data?.message?.join(",") || data?.error);
//         // setSubmitting(true);
//       }
//     );
//   };
// };

// export const getPropertyOwner = () => {
//   return (dispatch) => {
//     // const { email, password } = values;
//     // const dataToSend = {
//     //   username:email,
//     //   password,
//     // };
//     Utils.api.getApiCall(
//       Utils.endPoints.propertyOwner,
//       "",
//       (respData) => {
//         dispatch({
//           type: Utils.ActionName.PROPERTY_OWNER,
//           payload: { propertyOwnerList: respData.data },
//         });
//         // navigate("/dashboard");
//       },
//       (error) => {
//         let { data } = error;
//         Utils.showAlert(2, data?.message?.join(",") || data?.error);
//         // setSubmitting(true);
//       }
//     );
//   };
// };
